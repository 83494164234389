<template-base [title]="('monitoring.title' | translate) + ' > ' + ('monitoring.epd.title' | translate)">
  <div all>
    <crf-form-fields #filtersForm [fields]="formFields" [noCard]="true" [collapsable]="true"
                     (crfSubmit)="search($event)"
                     (clear)="clearSearch()" [loading]="loading" (valueChange)="checkDates($event)"/>
    <crf-dt-table [title]="'monitoring.epd.search.items' | translate"
                  [items]="epd.items" [showRefresh]="epd.loaded" (refreshItem)="refresh()"
                  [showPagination]="epd.hasMore"
                  [loading]="loading" (loadMore)="loadMore()">
      <th crf-dt-header field="deliveryId" type="string" sortable="deliveryId" (sort)="onSort($event)">
        {{ 'monitoring.epd.tbl.deliveryId' | translate }}
      </th>
      <th crf-dt-header field="subscriberId" type="string" sortable="subscriberId" (sort)="onSort($event)">
        {{ 'monitoring.epd.tbl.subscriberId' | translate }}
      </th>
      <th crf-dt-header field="culture" type="string" sortable="culture" (sort)="onSort($event)">
        {{ 'monitoring.epd.tbl.culture' | translate }}
      </th>
      <th crf-dt-header field="status" type="string" sortable="status" (sort)="onSort($event)">
        {{ 'monitoring.epd.tbl.status' | translate }}
      </th>
      <th crf-dt-header field="createdAt" type="string" sortable="createdAt" (sort)="onSort($event)">
        {{ 'monitoring.epd.tbl.createdAt' | translate }}
      </th>
      <th crf-dt-header field="completedAt" type="string" sortable="completedAt" (sort)="onSort($event)">
        {{ 'monitoring.epd.tbl.completedAt' | translate }}
      </th>
      <th crf-dt-header field="elapsed" type="string" sortable="elapsed" (sort)="onSort($event)">
        {{ 'monitoring.epd.tbl.elapsed' | translate }}
      </th>
      <tr crf-dt-row class="align-middle" *ngFor="let item of epd.items; trackBy: trackByEPD">
        <td crf-dt-cell>{{ item.deliveryId }}</td>
        <td crf-dt-cell>{{ item.subscriberId }}</td>
        <td crf-dt-cell>{{ item.culture }}</td>
        <td crf-dt-cell>
          <div class="d-flex flex-nowrap align-items-center">
            <div class="badge cds-badge active {{ getStatusTextBackground(item) }}">{{ item.status | titlecase }}</div>
            <div>
            <span *ngIf="item.warnings && item.warnings.length > 0"
                  [ngbTooltip]="getWarningTooltip(item.warnings)"
                  style="color:#ffcc00; margin-left: 10px">
                <i class="fa fa-exclamation-triangle"></i>
            </span>
            </div>
          </div>
        </td>
        <td crf-dt-cell>{{ item.createdAt | date:"dd/MM/yyyy hh:mm a z" }}</td>
        <td crf-dt-cell>{{ item.completedAt | date:"dd/MM/yyyy hh:mm a z" }}</td>
        <td crf-dt-cell>{{ getElapsedTime(item) }}</td>
        <crf-dt-actions>
          <crf-dt-action (click)="showDetails(item)">
            {{ 'monitoring.actions.details' | translate }}
          </crf-dt-action>
          <crf-dt-action (click)="showInputParameters(item)">
            {{ 'monitoring.actions.inputParameters' | translate }}
          </crf-dt-action>
          <crf-dt-action (click)="showProcessSteps(item)">{{
              'monitoring.actions.processSteps' | translate
            }}
          </crf-dt-action>
        </crf-dt-actions>
      </tr>
      <span crf-dt-no-item>
        {{ 'monitoring.epd.noItems' | translate }}
      </span>
      <span crf-dt-loading>
        {{ 'common.loading' | translate }}
      </span>
    </crf-dt-table>

    <job-steps-table *ngIf="toShowStepForId" jobType="EPD"
                     [jobId]="toShowStepForId??''" (closeCard)="closeStepsCard()"
                     [warnings]="getDeliveryWarnings(toShowStepForId)">
    </job-steps-table>
  </div>
</template-base>
