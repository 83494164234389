import {
  AfterViewChecked,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import {FormField, FormFieldOption, FormFieldsDefinition, IForm} from './form-fields';
import {UniqueIdService} from '../utils/unique-id.service';
import {filter, map, pairwise, startWith, Subscription, tap} from 'rxjs'

@Component({
  selector: 'crf-form-fields',
  templateUrl: './crf-form-fields.component.html',
  styleUrls: ['./crf-form-fields.component.scss']
})
export class CrifFormFieldsComponent implements IForm, OnChanges, AfterViewChecked, DoCheck, OnDestroy {

  constructor(idService: UniqueIdService) {
    this._id = idService.id
  }

  private _id: string
  public get id() {
    return this._id
  }

  public filterForm!: FormGroup;
  private formChangeSub!: Subscription
  private valueChangeSub!: Subscription

  @ViewChild('ngForm') form!: NgForm;

  @Input() public title!: string
  @Input() public actionBtn!: string
  @Input() public clearBtn!: string
  @Input() public disabled: boolean = false
  @Input() public loading: boolean = false
  @Input() public rows: number = 2
  @Input() public noActions: boolean = false
  @Input() public noCard: boolean = false
  @Input() public collapsable: boolean = false
  @Input() public fields?: FormFieldsDefinition
  @Input() public datalist?: FormFieldOption[]

  @Output() public crfSubmit: EventEmitter<any> = new EventEmitter()
  @Output() public clear: EventEmitter<void> = new EventEmitter()
  @Output() public formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

  get _fields() {
    if (!this.fields) return [];
    return Object.keys(this.fields).map((o) => {
      return {property: o, ...this.fields?.[o]} as FormField;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['fields']?.previousValue !== changes['fields']?.currentValue ||
      changes['fields']?.isFirstChange()
    ) {
      this.buildForm();
    }
    if (changes['disabled']?.previousValue !== changes['disabled']?.currentValue) {
      if (this.disabled) {
        this.filterForm.disable()
      } else {
        this.filterForm.enable()
      }
    }
    if (changes['loading']?.previousValue !== changes['loading']?.currentValue) {
      if (this.loading) {
        this.filterForm.disable()
      } else {
        this.filterForm.enable()
      }
    }
  }

  ngDoCheck(): void {
    for (const f of this._fields) {
      if (f.disabled) {
        this.filterForm.get(f.property)?.disable()
      } else {
        this.filterForm.get(f.property)?.enable()
      }
    }
  }

  private buildForm() {
    if (!this.fields) return;
    const formGroup = {} as { [k: string]: FormControl };
    for (const field of this._fields) {
      const control = new FormControl('', field.validators);
      formGroup[field.property] = control
    }
    this.filterForm = new FormGroup(formGroup);
    if (this.disabled) {
      this.filterForm.disable();
    }
  }

  onFilterSubmit() {
    if (this.filterForm.valid) {
      this.crfSubmit.emit(this.filterForm.value)
    }
  }

  identify(index: number, el: FormField): string {
    return el.property;
  }

  isText(t: string) {
    return ['text', 'email', 'number', 'password', 'search', 'tel', 'url', 'string'].includes(t)
  }

  isTextarea(t: string) {
    return ['textarea'].includes(t)
  }

  isDatePicker(t: string) {
    return ['date'].includes(t)
  }

  isSwitch(t: string) {
    return ['switch'].includes(t)
  }

  isSelect(t: string) {
    return ['select'].includes(t)
  }

  isMultiSelect(t: string) {
    return ['multiselect'].includes(t)
  }

  isCron(t: string) {
    return ['cron'].includes(t)
  }

  isCodeBlock(t: string) {
    return ['codeblock'].includes(t);
  }

  submitForm() {
    this.form.onSubmit({} as Event)
  }

  ngAfterViewChecked(): void {
    this.formChangeSub?.unsubscribe();
    this.valueChangeSub?.unsubscribe();

    if (this.form && this.form.valueChanges) {
      this.valueChangeSub = this.form.valueChanges.pipe(
        startWith(this.form.value),
        pairwise(),
        tap(([oldState, newState]) => this.formChange.emit(newState)),
        map(([oldState, newState]) => {
          const changes: any = {};
          for (const key in newState) {
            if (oldState[key] !== newState[key] && oldState[key] !== undefined) {
              changes[key] = newState[key];
            }
          }
          return changes;
        }),
        filter(changes => Object.keys(changes).length > 0),
        tap(changes => this.valueChange.emit(changes))
      ).subscribe();
    }
  }

  clearForm() {
    this.filterForm.reset()
    this.clear.emit()
  }

  ngOnDestroy(): void {
    if (this.valueChangeSub) {
      this.valueChangeSub.unsubscribe();
    }

    if (this.formChangeSub) {
      this.formChangeSub.unsubscribe();
    }
  }
}
