import {DeliveryReadDTO, DeliverySearchDTO, WarningDTO} from '@/apis/monitoring';
import {DeliveriesHPDService} from '@/services/deliveries/deliveries-hpd.service';
import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {ServiceStatus} from '@/services/paginated.service';
import {Component, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {Subscription} from 'rxjs';
import {SubscribersService} from "@/services/subscribers/subscribers.service";
import {SubscriberReadDTO} from "@/apis/configuration";
import {SortableDirective, SortEvent} from '@shared/directives/sortable.directive';
import {AbstractMonitoringComponent} from '../abstract-monitoring.component';
import {CrifFormFieldsComponent} from '@shared/components/crf-form-fields/crf-form-fields.component';
import {cloneDeep} from 'lodash-es';

@Component({
  selector: 'app-delivery-hpd',
  templateUrl: './delivery-hpd.component.html',
  styleUrls: ['./delivery-hpd.component.scss']
})
export class DeliveryHPDComponent extends AbstractMonitoringComponent<DeliverySearchDTO> implements OnDestroy {

  hpd: ServiceStatus<DeliveryReadDTO>
  globalSettingsService: GlobalSettingsService
  subscribersService: SubscribersService
  subscribers: any[] = []
  toShowStepForId: string | undefined;

  private searchFilters: DeliverySearchDTO = {}
  private subscriptions: Subscription[] = []
  public loading: boolean = true
  private DB_PAGE_SIZE = 1000;
  private DISPLAY_PAGE_SIZE = 50;
  private actualPage = 0;
  private items: DeliveryReadDTO[] = [];

  @ViewChild('filtersForm') filtersForm!: CrifFormFieldsComponent;
  @ViewChildren(SortableDirective) headers!: QueryList<SortableDirective>;

  constructor(
    private deliveryService: DeliveriesHPDService,
    private modalService: ModalService,
    globalSettingsService: GlobalSettingsService,
    languagesService: LanguagesService,
    subscribersService: SubscribersService
  ) {
    super(languagesService);
    this.subscribersService = subscribersService
    this.globalSettingsService = globalSettingsService
    this.hpd = deliveryService.status
    this.formFields = {};
    this.subscriptions.push(this.deliveryService.loading$.subscribe(o => this.loading = o))
  }

  protected override getFiltersForm(): CrifFormFieldsComponent {
    return this.filtersForm;
  }

  async search(data: DeliverySearchDTO) {
    if (!this.areSearchFiltersCorrect(data)) return;
    this.searchFilters = data;
    this.searchFilters.type = 'HPD';
    this.deliveryService.reset(true);
    this.hpd = this.deliveryService.status;
    await this.deliveryService.search(this.DB_PAGE_SIZE, this.searchFilters);
    this.hpd = this.deliveryService.status;
    this.items = this.deliveryService.status.items;
    this.hpd.items = this.items.slice(0, this.DISPLAY_PAGE_SIZE);
    this.actualPage = 1;
    this.setHasMore();
  }

  areSearchFiltersCorrect(data: DeliverySearchDTO) {
    let errorLabel: string = "";
    if (data.createdAtFrom && data.createdAtTo) {
      // Check 1
      const fromDate: Date = new Date(data.createdAtFrom);
      const toDate: Date = new Date(data.createdAtTo);
      if (fromDate > toDate) errorLabel = "monitoring.hpd.search.errors.fromAfterTo";
      // Check 2
      const diffTime: number = Math.abs(toDate.getTime() - fromDate.getTime());
      const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 30) errorLabel = "monitoring.hpd.search.errors.moreThan30Days";
    } else if (data.createdAtFrom && data.createdAtTo == undefined) {
      // Setting to date 30 days after from date
      let toDate: Date = new Date(data.createdAtFrom);
      toDate.setDate(toDate.getDate() + 29);
      if (toDate > (new Date())) toDate = (new Date());
      data.createdAtTo = toDate.toISOString().substring(0, 10);
      this.filtersForm.form.form.setValue(data);
      this.searchFilters.createdAtTo = toDate.toISOString().substring(0, 10);
    } else if (data.createdAtFrom == undefined && data.createdAtTo) {
      // Setting from date 30 days before to date
      let fromDate: Date = new Date(data.createdAtTo);
      fromDate.setDate(fromDate.getDate() - 29);
      data.createdAtFrom = fromDate.toISOString().substring(0, 10);
      this.filtersForm.form.form.setValue(data);
      this.searchFilters.createdAtFrom = fromDate.toISOString().substring(0, 10);
    } else {
      // Setting a range of 30 days from today
      const toDate: Date = new Date();
      let fromDate: Date = new Date();
      fromDate.setDate(fromDate.getDate() - 29);
      this.searchFilters.createdAtFrom = fromDate.toISOString().substring(0, 10);
      this.searchFilters.createdAtTo = toDate.toISOString().substring(0, 10);
      data.createdAtFrom = fromDate.toISOString().substring(0, 10);
      data.createdAtTo = toDate.toISOString().substring(0, 10);
      this.filtersForm.form.form.setValue(data);
    }
    if (errorLabel && errorLabel.length > 0) {
      this.modalService.openDialog<string, void>('row-details-modal-component', {
        type: 'warning',
        title: this.languagesService.translate('dialogs.warning'),
        content: this.languagesService.translate(errorLabel),
        action: 'details',
        entity: 'text',
        ok: this.languagesService.translate('common.ok'),
      })
      return false;
    }

    return true;
  }

  async loadMore() {
    const startPosition = this.actualPage * this.DISPLAY_PAGE_SIZE;
    this.hpd.items = this.hpd.items.concat(this.items.slice(startPosition, startPosition + this.DISPLAY_PAGE_SIZE));
    this.actualPage++;
    this.setHasMore();
  }

  async clearSearch() {
    this.searchFilters = {}
  }

  setHasMore() {
    this.hpd.hasMore = this.hpd.items.length !== this.items.length;
  }

  async refresh(isFirstLoad: boolean = false) {
    if (isFirstLoad) {
      let defaultFrom = new Date();
      defaultFrom.setDate(defaultFrom.getDate() - 5);
      this.filtersForm.form.form.setValue({
        deliveryId: null,
        status: null,
        culture: null,
        subscriberId: null,
        createdAtFrom: defaultFrom.toISOString().substring(0, 10),
        createdAtTo: (new Date()).toISOString().substring(0, 10)
      })
      this.searchFilters.createdAtFrom = defaultFrom.toISOString().substring(0, 10);
      this.searchFilters.createdAtTo = (new Date()).toISOString().substring(0, 10);
    }
    await this.search(this.searchFilters);
  }

  showDetails(rowItem: DeliveryReadDTO) {
    this.modalService.openDialog<DeliveryReadDTO, void>('row-details-modal-component', {
      type: 'info',
      title: this.languagesService.translate('monitoring.hpd.detail'),
      content: rowItem,
      action: 'details',
      entity: 'hpd',
      ok: this.languagesService.translate('common.ok'),
    })
  }

  getStatusTextBackground(elaboration: DeliveryReadDTO): string {
    let color: string;
    switch (elaboration.status) {
      case "COMPLETE":
        color = "text-bg-success";
        break;
      case "FAILED":
        color = "text-bg-danger";
        break;
      case "RUNNING":
        color = "text-bg-info";
        break;
      case "INVALID":
        color = "text-bg-danger";
        break;
      default:
        color = "text-bg-light";
        break;
    }
    return color;
  }

  async ngAfterViewInit(): Promise<void> {
    this.loading = true;
    this.subscribersService.reset(true);
    this.subscribersService.load(999999).then((response: SubscriberReadDTO[]) => {
      this.subscribers = response
        .map((subscriber: SubscriberReadDTO) => ({
          value: subscriber.subscriberId,
          text: `${subscriber.subscriberId} - ${subscriber.subscriberName}`
        }))
        .sort((a, b) => a.text > b.text ? 1 : -1);

      this.formFields = {
        deliveryId: {labeli18n: "monitoring.hpd.search.deliveryId", type: 'text', cols: "col-6",},
        status: {labeli18n: "monitoring.hpd.search.status", type: 'select', cols: "col-3"},
        culture: {labeli18n: "monitoring.hpd.search.culture", type: 'select', cols: "col-3",},
        subscriberId: {
          labeli18n: "engine.form.subscriberId", type: 'select', cols: "col-6",
          options: this.subscribers
        },
        createdAtFrom: {
          labeli18n: "monitoring.hpd.search.createdAtFrom",
          type: 'date',
          format: 'yyyy-MM-dd',
          cols: "col-3",
          maxDate: this.maxDate
        },
        createdAtTo: {
          labeli18n: "monitoring.hpd.search.createdAtTo",
          type: 'date',
          format: 'yyyy-MM-dd',
          cols: "col-3",
          maxDate: this.maxDate
        },
      }
      this.globalSettingsService.initialized.subscribe(o => {
        if (o) {
          this.formFields['status'].options = this.globalSettingsService.deliveryStatus
        }
      })
      this.globalSettingsService.initialized.subscribe(o => {
        if (o) {
          this.formFields['culture'].options = this.globalSettingsService.cultures || []
        }
      })
    }).then(value => {
      setTimeout(() => {
        this.refresh(true).then();
      }, 1);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
  }

  onSort(sortEvent: SortEvent) {
    if (sortEvent.column === 'elapsed') {
      this.deliveryService.sortItems(sortEvent, x => {
        if (!x.createdAt || !x.completedAt) {
          return 0
        }
        const str = new Date(x.createdAt).getTime();
        const end = new Date(x.completedAt).getTime();
        return end - str
      })

      this.hpd = this.deliveryService.status;
      return;
    }

    for (const header of this.headers) {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    }
    this.deliveryService.sort(sortEvent);
    this.hpd = this.deliveryService.status;
  }

  getElapsedTime(item: DeliveryReadDTO): string {
    if (!item.createdAt || !item.completedAt) {
      return "-"
    }
    const diff = new Date(item.completedAt).getTime() - new Date(item.createdAt).getTime();
    const hours = Math.trunc(diff / (1000 * 3600));
    const minutes = Math.trunc((diff - (hours * 3600 * 1000)) / (1000 * 60));
    const seconds = Math.trunc((diff - (hours * 3600 * 1000) - (minutes * 60 * 1000)) / 1000);
    const millis = diff - (hours * 3600 * 1000) - (minutes * 60 * 1000) - (seconds * 1000);
    return hours.toString() + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2) + '.' + ('000' + millis).slice(-3);
  }

  showInputParameters(delivery: DeliveryReadDTO) {
    const clonedDelivery = cloneDeep(delivery);
    clonedDelivery.query = delivery.query ? this.getFormattedQuery(delivery.query) : '';
    this.modalService.openDialog<DeliveryReadDTO, void>('row-details-modal-component', {
      type: 'info',
      title: this.languagesService.translate('monitoring.hpd.detail'),
      content: clonedDelivery,
      action: 'details',
      entity: 'delivery-input-parameters',
      ok: this.languagesService.translate('common.ok'),
    })
  }

  showProcessSteps(rowItem: DeliveryReadDTO) {
    this.toShowStepForId = rowItem.deliveryId
  }

  closeStepsCard() {
    this.toShowStepForId = undefined
  }

  trackByHPD(index: number, item: DeliveryReadDTO) {
    return item.deliveryId;
  }

  getWarningTooltip(warnings: WarningDTO[]): string {
    const warningSum = this.getWarningSum(warnings);
    const translationKey = `monitoring.warnings.${warningSum > 1 ? 'multipleWarnings' : 'singleWarning'}`;
    return `${warningSum} ${this.languagesService.translate(translationKey)}`;
  }

  getWarningSum(warnings: WarningDTO[]): number {
    return warnings ? warnings.reduce((sum, warning) => sum + warning.warningCounter, 0) : 0;
  }

  getDeliveryWarnings(id: string): WarningDTO[] {
    return this.items?.find(x => x.deliveryId === id)?.warnings ?? []
  }
}
