import {StartHPDRequestDTO} from '@/apis/engine';
import {EngineService} from '@/services/engine/engine.service';
import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {SubscribersService} from '@/services/subscribers/subscribers.service';
import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {CrifFormFieldsComponent} from '@shared/components/crf-form-fields/crf-form-fields.component';
import {FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {lastValueFrom, Subscription} from 'rxjs';
import {SubscriberReadDTO} from "@/apis/configuration";
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-engine-hpd',
  templateUrl: './engine-hpd.component.html',
  styleUrls: ['./engine-hpd.component.scss']
})
export class EngineHpdComponent implements AfterViewInit, OnDestroy {
  formFields: FormFieldsDefinition
  disabled: boolean = false
  @ViewChild('iFormFields') iFormFields!: CrifFormFieldsComponent;
  subscribers: any[] = []
  subscribersService: SubscribersService
  globalSettings: GlobalSettingsService
  QUERY_HINT: string = "(AccountPurpose = 1 AND Amount >= 0 AND Category in ('BL_00','BL_01','HF_02')) OR (AccountPurpose = 1 AND Amount < 0 AND Category in ('BL_00','BL_01','BL_02'))"

  private subscription: Subscription = new Subscription();

  constructor(private modalService: ModalService, private language: LanguagesService, private engineService: EngineService, globalSettings: GlobalSettingsService, subscribersService: SubscribersService) {
    this.subscribersService = subscribersService
    this.globalSettings = globalSettings
    this.formFields = {}
  }

  async startEngine(value: StartHPDRequestDTO & { isAccountableBool: boolean, sendFileBool: boolean }) {
    if (!value.subscriberId || !value.culture || !value.startDate || !value.endDate) {
      this.modalService.openDialog<string, void>('error-modal-component', {
        type: 'error',
        title: this.language.translate('dialogs.error.title'),
        content: this.language.translate("engine.hpd.mandatoryError"),
        action: 'error',
        ok: this.language.translate('common.ok'),
      })

      return;
    }

    if (value.startDate && value.endDate) {
      let errorLabel = "";
      const fromDate = new Date(value.startDate);
      const toDate = new Date(value.endDate);
      if (fromDate > toDate) errorLabel = "engine.form.errors.fromAfterTo";
      const diffTime = Math.abs(toDate.getTime() - fromDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 1000) errorLabel = "engine.form.errors.moreThan1000Days";

      if (errorLabel && errorLabel.length > 0) {
        this.modalService.openDialog<string, void>('row-details-modal-component', {
          type: 'warning',
          title: this.language.translate('dialogs.warning'),
          content: this.language.translate(errorLabel),
          action: 'details',
          entity: 'text',
          ok: this.language.translate('common.ok'),
        })
        return;
      }
    }

    this.disabled = true
    value.isAccountable = ('' + value.isAccountableBool).length > 0 ? '' + value.isAccountableBool : 'false';
    value.sendFile = ('' + value.sendFileBool).length > 0 ? '' + value.sendFileBool : 'false';

    lastValueFrom(this.engineService.startHPD(value)).catch()
    this.disabled = false

    this.modalService.openDialog<string, void>('error-modal-component', {
      type: 'info',
      title: this.language.translate('dialogs.detail'),
      content: this.language.translate("engine.form.submitted"),
      action: 'details',
      ok: this.language.translate('common.ok'),
    })
  }

  async ngAfterViewInit(): Promise<void> {
    this.subscribersService.load(999999).then((response: SubscriberReadDTO[]) => {
      this.subscribers = response.map((subscriber: SubscriberReadDTO) => ({
        value: subscriber.subscriberId,
        text: `${subscriber.subscriberId} - ${subscriber.subscriberName}`
      }));

      this.formFields = {
        subscriberId: {
          labeli18n: "engine.form.subscriberId", type: 'select', cols: "col-6",
          options: this.subscribers
        },
        culture: {
          labeli18n: "engine.form.culture", type: 'select', cols: "col-6",
          options: this.globalSettings.cultures
        },
        startDate: {
          labeli18n: "engine.form.startDate",
          type: 'date',
          format: 'yyyy-MM-dd',
          cols: "col-6"
        },
        endDate: {labeli18n: "engine.form.endDate", type: 'date', format: 'yyyy-MM-dd', cols: "col-6"},
        isAccountableBool: {labeli18n: "engine.form.isAccountable", type: 'switch', cols: "col-6", defaultValue: false},
        sendFileBool: {labeli18n: "engine.form.sendFile", type: 'switch', cols: "col-6", defaultValue: false},
        filters: {
          labeli18n: "engine.form.filters",
          type: 'textarea',
          cols: "col-12",
          rows: 12,
          hintText: `${this.language.translate("engine.form.eg")} ${this.QUERY_HINT}`
        },
      }

      this.subscription = this.globalSettings.initialized.subscribe(o => {
        if (o) {
          let cultures = this.globalSettings.cultures ? [...this.globalSettings.cultures] : [];
          cultures.unshift({value: "", text: ""})
          this.formFields['culture'].options = cultures
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public checkDate(data: StartHPDRequestDTO) {
    let startFromHintText = '';
    let endToHintText = '';

    if (data.startDate) {
      const createdAtToControl = this.iFormFields.form.form.controls['endDate'];
      const fromDate = new Date(data.startDate);
      const toDate = createdAtToControl.value ? new Date(createdAtToControl.value) : null;
      const diffDays = toDate ? this.getDiffDays(fromDate, toDate) : 0;
      if (!toDate || diffDays < 0 || diffDays > 1000) {
        let value = new Date(data.startDate);
        value.setDate(fromDate.getDate() + 1000);
        if (value > new Date()) {
          value = new Date();
        }
        createdAtToControl.setValue(this.formatDate(value));
        endToHintText = this.language.translate('engine.form.hints.moreThan1000Days');
      }
    }

    if (data.endDate) {
      const createdAtFromControl = this.iFormFields.form.form.controls['startDate'];
      const fromDate = createdAtFromControl.value ? new Date(createdAtFromControl.value) : null;
      const toDate = new Date(data.endDate);
      const diffDays = fromDate ? this.getDiffDays(fromDate, toDate) : 0;
      if (!fromDate || diffDays < 0 || diffDays > 1000) {
        let value = new Date(data.endDate);
        value.setDate(toDate.getDate() - 1000);
        createdAtFromControl.setValue(this.formatDate(value));
        startFromHintText = this.language.translate('engine.form.hints.moreThan1000Days');
      }
    }

    this.formFields['startDate'].hintText = startFromHintText;
    this.formFields['endDate'].hintText = endToHintText;
  }

  private getDiffDays(fromDate: Date, toDate: Date): number {
    const diffTime = toDate.getTime() - fromDate.getTime();
    return Math.round(diffTime / (1000 * 60 * 60 * 24));
  }

  private formatDate(d: Date): string {
    return formatDate(d, 'yyyy-MM-dd', 'en-US');
  }
}
