import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {PagesModule} from './pages/pages.module';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MODALS} from './modals';
import {CrifModalRegistryOptions} from '@shared/components/crf-modals/crf-modal-registry.options';

@NgModule({
  declarations: [AppComponent,
    ...MODALS
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    PagesModule,
  ],
  providers: [
    {provide: CrifModalRegistryOptions, useValue: {modals: MODALS} as CrifModalRegistryOptions}],
  bootstrap: [AppComponent],
  exports: [
    ...MODALS
  ],
})
export class AppModule {
}
