import {Injectable} from '@angular/core';
import * as cronParser from "cron-parser";
import {isEmpty} from 'lodash-es';
import cron from "cron-validate";

@Injectable({
  providedIn: 'root'
})
export class CronValidatorService {

  public isValid(cronExpression: string): boolean {
    return !isEmpty(cronExpression) && cronExpression.trim().length > 0 && cron(cronExpression, {preset: 'aws-cloud-watch'}).isValid();
  }

  public hasNextExecution(cronExpression: string): boolean {
    const cronYear = cronExpression.split(' ')[5]
    if (cronYear === "*") {
      return true;
    }

    return cronYear
      .split(',')
      .some(group => {
        if (group.includes("*")) return true;
        const yrValues = group.split('-').map(val => parseInt(val))
        return this._hasNextExecution(cronExpression, yrValues[0], yrValues[1])
      })
  }

  private getEuropeRomeDate(dt: Date) {
    return new Date(dt.toLocaleString('en-US', {timeZone: 'Europe/Rome'}));
  }

  private _hasNextExecution(cronExpression: string, cronStartYear: number, cronEndYear?: number): boolean {
    cronEndYear ??= cronStartYear
    const parsableCronExp = cronExpression.split(' ').slice(0, 5).join(' ')
    const interval = cronParser.parseExpression(parsableCronExp, {
      currentDate: this.getEuropeRomeDate(new Date()),
      startDate: this.getEuropeRomeDate(new Date(cronStartYear, 0, 1, 0, 0, 0)),
      endDate: this.getEuropeRomeDate(new Date(cronEndYear, 11, 31, 23, 59, 59))
    });

    return interval.hasNext()
  }
}
