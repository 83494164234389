import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { OauthService } from './oauth.service';
import { environment } from '~/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private oauthService: OidcSecurityService) { }
  configId: string = environment.cognito.configId
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.baseUrl)) {
      return next.handle(request);
    }
    return this.oauthService.getIdToken(this.configId).pipe(
      switchMap(token => {
        if (token) {
          const newRequest = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
          });
          return next.handle(newRequest);
        }
        return next.handle(request);
      })
    );
  }
}
