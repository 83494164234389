/**
 * CRIF Data Flow configuration
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriberFilterItemDTO } from './subscriberFilterItemDTO';


export interface SubscriberEventItemDTO { 
    eventId: string;
    name: string;
    scheduler: string;
    filters: Array<SubscriberFilterItemDTO>;
    enabled?: boolean;
    culture?: string;
    emptyTaggedDescription?: boolean;
}

