import { Component, Input, ViewContainerRef, ViewChild, TemplateRef } from '@angular/core';
import { HostWrapper } from '../utils/host-wrapper';
import { WrappedColumn } from '../utils/wrapped.component';


@Component({
  selector: 'crf-option',
  template: `
    <ng-template  #template> 
      <option [value]="value" [disabled]="disabled"><ng-content></ng-content></option>
    </ng-template>`
})
export class OptionComponent {

  @Input() disabled = false;
  @Input() value!: string;

  @ViewChild('template', { static: true }) template!: TemplateRef<any>;

  constructor(private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
  }
}
