import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CookiesService } from '../cookies/cookies.service';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  constructor(
    private translateService: TranslateService,
    private cookiesService: CookiesService
  ) {
    translateService.addLangs(environment.i18n.availableLangs);
    const languageUI =
      this.cookiesService.getCookie('ui-locale') ||
      this.browserLanguage ||
      this.defaultLanguage;
    translateService.use(languageUI);
  }

  get language() {
    return (
      this.translateService.currentLang || environment.i18n.defaultLanguage
    );
  }

  set language(value: string) {
    this.cookiesService.setCookie('ui-locale', value);
    this.translateService.use(value);
  }

  public get languages(): string[] {
    return this.translateService.getLangs();
  }

  public get defaultLanguage(): string {
    return this.translateService.getDefaultLang();
  }

  public get browserLanguage() {
    return this.translateService.getBrowserLang();
  }

  public translate(key: string | Array<string>, interpolateParams?: Object) {
    return this.translateService.instant(key, interpolateParams);
  }

  public get languageChanged$() {
    return this.translateService.onLangChange;
  }

  public getLanguageName(l: string): string | undefined {
    switch (l) {
      case 'it':
        return 'Italiano';
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
      case 'fr':
        return 'Francés';
      default:
        return;
    }
  }
}
