import { Observable, map } from "rxjs";
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { LanguagesService } from "@shared/services/languages/languages.service";
import { ModalService } from "@shared/components/crf-modals/modal.service";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard {

  constructor(
    private modalService: ModalService,
    private languagesService: LanguagesService
  ) {
  }

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return component.canDeactivate() ?
      true :
      this.openDialog();
  }

  private openDialog(): Observable<boolean> {
    return this.modalService.openDialog<string, boolean>('confirm-modal-component', {
      type: 'warning',
      title: this.languagesService.translate('common.pendingChanges.title'),
      content: this.languagesService.translate('common.pendingChanges.description'),
      action: 'confirm',
      entity: 'text',
      ok: this.languagesService.translate('common.ok'),
      cancel: this.languagesService.translate('common.cancel'),
    }).pipe(
      map(o => o.reason === 'ok')
    );
  }
}