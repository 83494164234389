import { Component, Input, Optional, Self, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CrifAbstractComponent } from './crf-abstract-field.component';
import { UniqueIdService } from '../utils/unique-id.service';
import { FormFieldOption } from '../crf-form-fields/form-fields';

@Component({
  selector: 'crf-select',
  host: {
    class: 'd-flex flex-grow-1 flex-shrink-1 flex-auto'
  },
  template: `
  <div class="w-100">
    <label *ngIf="label" [for]="id" class="form-label">{{ label }}</label>
    <ng-select
        [attr.aria-label]="label"
        [id]="id"
        [attr.disabled]="disabled?true:undefined"
        [attr.placeholder]="placeholder"
        [attr.size]="display"
        [(ngModel)]="value"
        (blur)="touch();"
        [ngClass]="{'is-invalid': control?.touched && control?.invalid}"
        (change)="update($event);">
      <ng-option *ngFor="let option of options; trackBy: trackByOption" [value]="option.value">{{option.text}}</ng-option>
    </ng-select>
    <div class="invalid-feedback">
      {{ errors | json }}
    </div>
  </div>`
})
export class SelectComponent extends CrifAbstractComponent<string> implements ControlValueAccessor, OnInit {
  @Input() disabled = false;
  @Input() label: string = '';
  @Input() placeholder!: string
  @Input() size: "small" | "large" | undefined = undefined
  @Input() display?: number = undefined
  @Input() defaultValue?: string = undefined
  @Input() options?: FormFieldOption[] | undefined

  constructor(idService: UniqueIdService, @Self() @Optional() override control?: NgControl) {
    super(idService, control)
  }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.setValue(this.defaultValue);
    }
  }

  update(value: string) {
    if (!this.disabled) {
      this.setValue(value);
    }
  }

  trackByOption(index: number, item: FormFieldOption) {
    return `${index}-select-${this.id}`;
  }
}
