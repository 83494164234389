<crf-modal-simple-template [title]="data.title" [okLabel]="data.ok" [cancelLabel]="data.cancel"
  (close)="onClose($event)" [color]="data.type">
  <ng-container *ngIf="dataContributor">
    <span> {{ 'dialogs.contributors.deleteConfirm' | translate }}</span>
    <div class="mt-3">
      <h5 class="d-block"> {{ 'contributors.detail' | translate }} </h5>
      <span class="d-block">
        <strong>{{ 'contributors.form.id' | translate }}</strong>: {{ dataContributor.contributorId }}
      </span>
      <span class="d-block">
        <strong>{{ 'contributors.form.name' | translate }}</strong>: {{ dataContributor.contributorName }}
      </span>
      <span class="d-block" *ngIf="dataContributor.errorThreshold">
        <strong>{{ 'contributors.form.errorThreshold' | translate }}</strong>: {{ dataContributor.errorThreshold}}
      </span>
    </div>
    <h6 class="d-block"> {{ 'contributors.filters.title' | translate }} </h6>
    <crf-dt-table [items]="dataContributor.filters">
      <th crf-dt-header field="type" type="string">{{ 'contributors.filters.ent.type' | translate }}</th>
      <th crf-dt-header field="match" type="string">{{ 'contributors.filters.ent.match' | translate }}</th>
      <th crf-dt-header field="col" type="string">{{ 'contributors.filters.ent.col' | translate }}</th>
      <th crf-dt-header field="candidates" type="string">{{ 'contributors.filters.ent.candidates' | translate }}
      </th>
      <th crf-dt-header field="enabled" type="string">{{ 'contributors.filters.ent.enabled' | translate }}</th>
      <tr crf-dt-row *ngFor="let item of dataContributor.filters; trackBy: trackByFilter">
        <td crf-dt-cell>{{ item.type }} </td>
        <td crf-dt-cell>{{ item.match }}</td>
        <td crf-dt-cell>{{ item.col }}</td>
        <td crf-dt-cell>{{ item.candidates }}</td>
        <td crf-dt-cell>
          <crf-switch [(ngModel)]="item.enabled" [disabled]="true" />
        </td>
      </tr>
      <span crf-dt-no-item>
        {{ 'contributors.noItems' | translate }}
      </span>
    </crf-dt-table>
  </ng-container>
  <ng-container *ngIf="dataSubscriber">
    <span> {{ 'dialogs.subscribers.deleteConfirm' | translate }}</span>
    <div class="mt-3">
      <h5 class="d-block"> {{ 'subscribers.detail' | translate }} </h5>
      <span class="d-block">
        <strong>{{ 'subscribers.form.id' | translate }}</strong>: {{ dataSubscriber.subscriberId }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.form.name' | translate }}</strong>: {{ dataSubscriber.subscriberName }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.form.region' | translate }}</strong>: {{ dataSubscriber.region}}
      </span>
    </div>
    <h6 class="d-block"> {{ 'subscribers.events.title' | translate }} </h6>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <crf-tree-view [nodes]="dataSubscriberEventTree || []" (selectedItem)="treeItemClick($event)"
              [maxDepth]="3" [readonly]="true"></crf-tree-view>
          </div>
        </div>
      </div>
      <!--
      <div class="col-8">
        <h6>{{ 'subscribers.events.event.name' | translate }}</h6>
        <crf-form-fields class="d-block" #eventForm [fields]="eventFormFields" [noActions]="true" [noCard]="true"
          [disabled]="true" />
        <hr>
        <div class="ps-3">
          <h6>{{ 'subscribers.events.filter.name' | translate }}</h6>
          <crf-form-fields class="d-block" #filterForm [fields]="filterFormFields" [noActions]="true" [noCard]="true"
            [disabled]="true" />
          <hr>
          <div class="ps-3">
            <h6>{{ 'subscribers.events.condition.name' | translate }}</h6>
            <crf-form-fields class="d-block" #conditionForm [fields]="conditionFormFields" [noActions]="true"
              [noCard]="true" [disabled]="true" />
          </div>
        </div>
      </div>
      -->
    </div>
  </ng-container>
  <ng-container *ngIf="dataContributorFilter">
    <span> {{ 'dialogs.contributors.filters.deleteConfirm' | translate }}</span>
    <div class="mt-3">
      <h5 class="d-block"> {{ 'dialogs.detail' | translate }} </h5>
      <span class="d-block">
        <strong>{{ 'contributors.filters.ent.type' | translate }}</strong>: {{ dataContributorFilter.type }}
      </span>
      <span class="d-block">
        <strong>{{ 'contributors.filters.ent.match' | translate }}</strong>: {{ dataContributorFilter.match }}
      </span>
      <span class="d-block">
        <strong>{{ 'contributors.filters.ent.col' | translate }}</strong>: {{ dataContributorFilter.col }}
      </span>
      <span class="d-block">
        <strong>{{ 'contributors.filters.ent.candidates' | translate }}</strong>: {{ dataContributorFilter.candidates }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="dataSubscriberEvent">
    <span> {{ 'dialogs.subscribers.events.deleteEventConfirm' | translate }}</span>
    <div class="mt-3">
      <h5 class="d-block"> {{ 'dialogs.detail' | translate }} </h5>
      <span class="d-block">
        <strong>{{ 'subscribers.events.event.scheduler' | translate }}</strong>: {{ dataSubscriberEvent.scheduler }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.events.event.culture' | translate }}</strong>: {{ dataSubscriberEvent.culture }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.events.event.enabled' | translate }}</strong>: {{ dataSubscriberEvent.enabled }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="dataSubscriberFilter">
    <span> {{ 'dialogs.subscribers.events.deleteFilterConfirm' | translate }}</span>
    <div class="mt-3">
      <h5 class="d-block"> {{ 'dialogs.detail' | translate }} </h5>
      <span class="d-block">
        <strong>{{ 'subscribers.events.filter.type' | translate }}</strong>: {{ dataSubscriberFilter.type }}
      </span>
      <span class="d-block" *ngIf="dataSubscriberFilter.value">
        <strong>{{ 'subscribers.events.filter.value' | translate }}</strong>: {{ dataSubscriberFilter.value }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.events.filter.enabled' | translate }}</strong>: {{ dataSubscriberFilter.enabled }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="dataSubscriberGroup">
    <span> {{ 'dialogs.subscribers.events.deleteGroupConfirm' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="dataSubscriberCondition">
    <span> {{ 'dialogs.subscribers.events.deleteConditionConfirm' | translate }}</span>
    <div class="mt-3">
      <h5 class="d-block"> {{ 'dialogs.detail' | translate }} </h5>
      <span class="d-block">
        <strong>{{ 'subscribers.events.condition.column' | translate }}</strong>: {{ dataSubscriberCondition.column }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.events.condition.operator' | translate }}</strong>:
        {{ dataSubscriberCondition.condition }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.events.condition.value' | translate }}</strong>: {{ dataSubscriberCondition.value }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="text">
    <span> {{ text }}</span>
  </ng-container>
  <ng-container *ngIf="dataStartEpd">
    <span> {{ 'dialogs.engine.startEpdConfirm' | translate }}</span>
    <div class="mt-3">
      <h5 class="d-block"> {{ 'dialogs.detail' | translate }} </h5>
      <span class="d-block">
        <strong>{{ 'engine.form.subscriberId' | translate }}</strong>:
        {{ dataStartEpd.subscriberId }}
      </span>
      <span class="d-block">
        <strong>{{ 'engine.form.culture' | translate }}</strong>:
        {{ dataStartEpd.culture }}
      </span>
    </div>
  </ng-container>
</crf-modal-simple-template>
