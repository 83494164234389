import {DeliveryGeneratePresignedUrlDTO, DeliveryReadDTO, DeliveryService, PresignedUrlDTO} from '@/apis/monitoring';
import {Component, Input} from '@angular/core';
import {SortEvent} from '@shared/directives/sortable.directive';
import {ErrorUiService} from '@shared/services/error/error-ui.service';
import {lastValueFrom, map, tap} from 'rxjs';

interface HpdOutputFileRow {
  fileName: string,
  displayName: string
}

interface HpdOutputFilePagination {
  total: number,
  first: number,
  last: number,
  perPage: number
}


@Component({
  selector: 'hpd-output-files',
  templateUrl: './hpd-output-files.component.html',
  styleUrls: ['./hpd-output-files.component.scss']
})

export class HpdOutputFilesComponent {

  @Input() delivery!: DeliveryReadDTO

  files: HpdOutputFileRow[] = []
  unaccessibleFiles: { [key: string]: string } = {}
  pagination: HpdOutputFilePagination = {total: 0, first: 0, last: 0, perPage: 5}

  constructor(
    private deliveryService: DeliveryService,
    private errorUIService: ErrorUiService,
  ) {
  }

  ngAfterContentInit(): void {
    this.files = this.getTableItems(this.delivery.filenames)
    this.pagination = this.getTablePagination(this.files)
  }

  onSearch(value: string) {
    let items = this.getTableItems(this.delivery.filenames).filter(x => x.displayName.includes(value))
    this.files = items
    this.pagination = this.getTablePagination(items)
  }

  getTablePagination(items: any[]): HpdOutputFilePagination {
    return {
      total: items.length,
      first: 0,
      last: Math.min(5, items.length),
      perPage: 5
    }
  }

  getTableItems(items: string[]): HpdOutputFileRow[] {
    return items.slice().map(f => {
      let fileDate = f.split('_')[4]
      return {
        fileName: f,
        displayName: fileDate.substring(0, 8)
      }
    });
  }

  hasMorePages(): boolean {
    return this.pagination.total > this.pagination.perPage
  }

  hasPrev(): boolean {
    return this.pagination.first > 0
  }

  prevPage() {
    if (this.hasPrev()) {
      let first = Math.max(this.pagination.first - this.pagination.perPage, 0)
      this.pagination = {
        ...this.pagination,
        first: first,
        last: first + this.pagination.perPage
      }
    }
  }

  hasNext(): boolean {
    return this.pagination.last < this.pagination.total
  }

  nextPage() {
    if (this.hasNext()) {
      let last = Math.min(this.pagination.last + this.pagination.perPage, this.pagination.total)
      this.pagination = {
        ...this.pagination,
        first: last - this.pagination.perPage,
        last: last
      }
    }
  }

  onSort(sortEvent: SortEvent) {
    let dir = sortEvent.direction === 'asc' ? 1 : sortEvent.direction === 'desc' ? -1 : 1;
    this.files = this.files?.sort((a, b) => (a < b ? 1 : -1) * dir);
  }

  hasOldStorageClass(file: HpdOutputFileRow) {
    return Object.keys(this.unaccessibleFiles).some(x => x === file.fileName)
  }

  getStorageClass(file: HpdOutputFileRow) {
    return this.unaccessibleFiles.hasOwnProperty(file.fileName) ? this.unaccessibleFiles[file.fileName] : "Unaccessible storage class"
  }

  async downloadHPDFile(fileName: string) {
    let presignedUrl: string | undefined

    await lastValueFrom(
      this.deliveryService.deliveryControllerGeneratePresignedUrl({
        subscriberId: this.delivery?.subscriberId,
        deliveryDate: this.delivery?.deliveryDate,
        filename: fileName,
        sendFile: this.delivery?.sendFile
      } as DeliveryGeneratePresignedUrlDTO).pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? null : o),
        map(o => o as PresignedUrlDTO),
        tap(o => {
          presignedUrl = o?.presignedUrl
          if (!(o?.storageClass === "STANDARD" || o?.storageClass === "STANDARD_IA")) {
            this.unaccessibleFiles[fileName] = o.storageClass
          }
        })
      )
    )

    if (presignedUrl) {
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      document.body.appendChild(anchor);

      anchor.href = presignedUrl;
      anchor.target = '_blank';
      anchor.download = fileName;
      anchor.click();

      document.body.removeChild(anchor);
    }
  }

  trackByFile(index: number, item: HpdOutputFileRow) {
    return item.fileName;
  }
}
