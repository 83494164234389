import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild, ViewContainerRef } from '@angular/core';
import { CrifDtHeaderComponent } from './crf-dt-header.component';
import { CrifDtRowComponent } from './crf-dt-row.component';

export interface TableDataQuery {
  pagination?: { size: number },
  sorting?: { column: string, direction: string },
  filter?: { [k: string]: number | string | boolean }
}

@Component({
  selector: 'crf-dt-table',
  templateUrl: './crf-dt-table.component.html',
  styleUrls: ['./crf-dt-table.component.scss'],
})
export class DataTableComponent implements AfterViewInit {
  @Input() title!: string
  @Input() creationStrategy: 'none' | 'modal' | 'page' = 'none'
  @Input() creationUrl!: any[] | string | null | undefined
  @Input() items!: any[]
  @Input() showPagination: boolean = false
  @Input() loading: boolean = false
  @Input() showRefresh: boolean = false
  @Input() showActions: 'row' | 'head' | '' = 'row'
  @Output() createItem: EventEmitter<void> = new EventEmitter()
  @Output() refreshItem: EventEmitter<void> = new EventEmitter()
  @Output() loadMore: EventEmitter<void> = new EventEmitter()
  @ContentChildren(CrifDtRowComponent) public rows!: QueryList<CrifDtRowComponent>;
  @ContentChildren(CrifDtHeaderComponent) columns!: QueryList<CrifDtHeaderComponent>;
  @ViewChild('projectedDisplayColumns', { read: ViewContainerRef }) _projectedDisplayColumns!: ViewContainerRef;

  get hasActions() {
    if (this.showActions.length == 0) return false;
    return this.rows?.toArray()?.some(o => o.hasActions) || this.showActions !== 'row'
  }

  ngAfterViewInit() {
    for (let i = this._projectedDisplayColumns.length; i > 0; i--) {
      this._projectedDisplayColumns.detach();
    }
    this.columns.forEach(column => {
      this._projectedDisplayColumns.insert(column._view);
    });
  }

  get cols() {
    return this.columns.length + (this.showActions === 'row' ? 1 : 0)
  }

  get titleWithItemsCount(): string {
    return this.title.replace('###', ''+this.items.length);
  }
}
