<template-base [title]="'contributors.title' | translate" [subTitle]="'contributors.subTitle' | translate">
  <div all>
    <crf-form-fields [fields]="formFields" [noCard]="true" [collapsable]="true" (crfSubmit)="search($event)"
      (clear)="clearSearch()" [loading]="loading" />
    <div class="row">
      <div class="col-12">
        <crf-dt-table [title]="'contributors.items' | translate" [items]="contributors.items"
          [showRefresh]="contributors.loaded" (refreshItem)="refresh()" [showPagination]="contributors.hasMore"
          [loading]="loading" creationStrategy="page" creationUrl="/configuration/contributors/create"
          (loadMore)="loadMore()">
          <th crf-dt-header field="contributorId" type="string" sortable="contributorId" (sort)="onSort($event)">
            {{ 'contributors.tbl.contributorId' | translate }}
          </th>
          <th crf-dt-header field="contributorName" type="string" sortable="contributorName" (sort)="onSort($event)">
            {{ 'contributors.tbl.contributorName' | translate }}
          </th>
          <th crf-dt-header field="filters" type="string" sortable="filters" (sort)="onSort($event)">
            {{ 'contributors.tbl.filters' | translate }}
          </th>
          <th crf-dt-header field="errorThreshold" type="number" sortable="errorThreshold" (sort)="onSort($event)">
            {{ 'contributors.tbl.errorThreshold' | translate }}
          </th>
          <th crf-dt-header field="createdAt" type="string" sortable="createdAt" (sort)="onSort($event)">
            {{ 'contributors.tbl.createdAt' | translate }}
          </th>
          <th crf-dt-header field="updatedAt" type="string" sortable="updatedAt" (sort)="onSort($event)">
            {{ 'contributors.tbl.updatedAt' | translate }}
          </th>
          <tr crf-dt-row *ngFor="let item of contributors.items; trackBy: trackByContributor">
            <td crf-dt-cell>{{ item.contributorId }} </td>
            <td crf-dt-cell>{{ item.contributorName }} </td>
            <td crf-dt-cell>{{ (item.filters.length > 0 ? 'common.yes' : 'common.no') | translate }}</td>
            <td crf-dt-cell>{{ item.errorThreshold || ('contributors.tbl.errorThresholdInherited' | translate) }} </td>
            <td crf-dt-cell>{{ item.createdAt | date:"dd/MM/yyyy hh:mm a z" }}</td>
            <td crf-dt-cell>{{ item.updatedAt | date:"dd/MM/yyyy hh:mm a z" }}</td>
            <crf-dt-actions>
              <crf-dt-action (click)="onEdit(item)">{{ 'common.view' | translate }}</crf-dt-action>
              <crf-dt-action (click)="onDelete(item)">{{ 'common.delete' | translate }}</crf-dt-action>
            </crf-dt-actions>
          </tr>
          <span crf-dt-no-item>
            {{ 'contributors.noItems' | translate }}
          </span>
          <span crf-dt-loading>
            {{ 'common.loading' | translate }}
          </span>
        </crf-dt-table>
      </div>
    </div>
  </div>
</template-base>
