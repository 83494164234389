import { Injectable } from '@angular/core';
import { PaginatedService } from '../paginated.service';
import { ErrorUiService } from '@shared/services/error/error-ui.service';
import { ElaborationReadDTO, ElaborationSearchDTO, ElaborationService } from '@/apis/monitoring';

@Injectable({
  providedIn: 'root'
})
export class ElaborationsService extends PaginatedService<ElaborationReadDTO> {

  constructor(errorUIService: ErrorUiService, private elaborationService: ElaborationService) {
    super(errorUIService)
  }

  public async load(limit: number): Promise<ElaborationReadDTO[]> {
    return await super._load((a, b) => this.elaborationService.elaborationControllerFindAll(a, b), limit)
  }

  public async search(limit: number, search: ElaborationSearchDTO): Promise<ElaborationReadDTO[]> {
    return await this._search((a, b, c) => this.elaborationService.elaborationControllerSearch(a, b, c), limit, search)
  }
}
