import {JobStepReadDTO, WarningDTO} from '@/apis/monitoring';
import {JobStepsService} from '@/services/job-steps/job-steps.service';
import {Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SortEvent} from '@shared/directives/sortable.directive';

@Component({
  selector: 'job-steps-table',
  templateUrl: './job-steps.component.html',
  styleUrls: ['./job-steps.component.scss']
})

export class JobStepsComponent {

  @Input() jobId!: string
  @Input() jobType!: "ETL" | "EPD" | "HPD"
  @Input() steps!: JobStepReadDTO[]
  @Input() warnings!: WarningDTO[]

  @ViewChild('processTable') table!: ElementRef;
  @Output() closeCard = new EventEmitter<void>();

  public loading: boolean = true

  constructor(
    private jobStepService: JobStepsService
  ) {
  }

  ngAfterContentInit(): void {
    this.loadSteps()
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes["jobId"] && !changes["jobId"].firstChange &&
    this.loadSteps();
  }

  onRefresh() {
    this.loadSteps()
  }

  loadSteps(): void {
    this.loading = true
    this.steps = []

    this.jobStepService
      .getAll(this.jobId, this.jobType)
      .then(x => {
        this.steps = x
      })
      .finally(() => {
        this.loading = false
        window.setTimeout(() => {
          this.table.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 100)
      })
  }

  getStatusTextBackground(step: JobStepReadDTO): string {
    let color: string;
    switch (step.status) {
      case "COMPLETE":
        color = "text-bg-success";
        break;
      case "FAILED":
        color = "text-bg-danger";
        break;
      case "RUNNING":
        color = "text-bg-info";
        break;
      default:
        color = "text-bg-secondary";
        break;
    }
    return color;
  }

  getElapsedTime(step: JobStepReadDTO): string {
    if (!step.createdAt || !step.endedAt) {
      return "-"
    }
    const diff = new Date(step.endedAt).getTime() - new Date(step.createdAt).getTime();
    const hours = Math.trunc(diff / (1000 * 3600));
    const minutes = Math.trunc((diff - (hours * 3600 * 1000)) / (1000 * 60));
    const seconds = Math.trunc((diff - (hours * 3600 * 1000) - (minutes * 60 * 1000)) / 1000);
    const millis = diff - (hours * 3600 * 1000) - (minutes * 60 * 1000) - (seconds * 1000);
    return hours.toString() + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2) + '.' + ('000' + millis).slice(-3);
  }

  onSort(sortEvent: SortEvent, prop: keyof JobStepReadDTO | "elapsed") {
    let dir = sortEvent.direction === 'asc' ? 1 : sortEvent.direction === 'desc' ? -1 : 1;

    switch (prop) {
      case "name":
        this.steps = this.steps.sort((a, b) => (a.name < b.name ? -1 : 1) * dir);
        break;
      case "status":
        this.steps = this.steps.sort((a, b) => (a.status < b.status ? -1 : 1) * dir);
        break;
      case "createdAt":
        this.steps = this.steps.sort((a, b) => ((a.createdAt?.getTime() ?? 0) - (b.createdAt?.getTime() ?? 0)) * dir);
        break;
      case "endedAt":
        this.steps = this.steps.sort((a, b) => ((a.endedAt?.getTime() ?? 0) - (b.endedAt?.getTime() ?? 0)) * dir);
        break;
      case "elapsed":
        this.steps = this.steps.sort((a, b) => (((a.endedAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0)) - ((b.endedAt?.getTime() ?? 0) - (b.createdAt?.getTime() ?? 0))) * dir);
        break;
    }
  }

  onClose() {
    this.closeCard.emit();
  }

  trackByStep(index: number, item: JobStepReadDTO) {
    return `${index}-job-step-${item.createdAt}`;
  }

  stepWarnings(step: string): WarningDTO[] {
    return this.warnings.filter(x => x.stepName == step)
  }
}
