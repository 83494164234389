/**
 * CRIF Data Flow configuration
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContributorFilterItemDTO { 
    type: ContributorFilterItemDTO.TypeEnum;
    match?: string;
    col?: string;
    candidates?: Array<string>;
    enabled?: boolean;
    copyColumns?: Array<string>;
}
export namespace ContributorFilterItemDTO {
    export type TypeEnum = 'REPLACE';
    export const TypeEnum = {
        Replace: 'REPLACE' as TypeEnum
    };
}


