import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'htranslate',
})
export class HTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, ...args: string[]): string {
    if (!value.startsWith('#')) return value;
    return this.translateService.instant(value);
  }
}
