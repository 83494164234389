export * from './delivery.service';
import { DeliveryService } from './delivery.service';
export * from './elaboration.service';
import { ElaborationService } from './elaboration.service';
export * from './error.service';
import { ErrorService } from './error.service';
export * from './globalSetting.service';
import { GlobalSettingService } from './globalSetting.service';
export * from './jobStep.service';
import { JobStepService } from './jobStep.service';
export const APIS = [DeliveryService, ElaborationService, ErrorService, GlobalSettingService, JobStepService];
