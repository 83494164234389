import { Injectable } from '@angular/core';
import { AbstractLoggerService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class AuthLoggerService implements AbstractLoggerService {

  logError(message: any, ...args: any[]): void {
    console.error(message, args);
    if ((message as string).endsWith("no refresh token found, please login")) {
      setTimeout(() => location.reload(), 5000);
    }
  }

  logWarning(message: any, ...args: any[]): void {
    console.warn(message, args);
  }

  logDebug(message: any, ...args: any[]): void {
    console.info(message, args);
  }
}
