import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CrifAbstractComponent } from './crf-abstract-field.component';
import { UniqueIdService } from '../utils/unique-id.service';

@Component({
  selector: 'crf-datepicker',
  template: `
    <label [for]="id" class="form-label">{{ label }}</label>
    <div class="input-group has-validation">
      <input #input
              [id]="id"
              class="form-control"
              [type]="type"
              [attr.disabled]="disabled?true:undefined"
              [attr.readonly]="readonly?true:undefined"
              [value]="value | date:format"
              (blur)="touch();"
              [ngClass]="{'is-invalid': control?.touched && control?.invalid}"
              (input)="update($event.target);"
              [min]="min"
              [max]="max"
              >
        <div class="invalid-feedback">
          {{errors | json }}
        </div>
      </div>
      <div class="cds-form-control-hint">
        {{hintText}}
      </div>`
})
export class CrifDatePickerComponent extends CrifAbstractComponent<string> implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() label: string = '';
  @Input() beforeText?: boolean = false
  @Input() placeholder?: string
  @Input() format?: string = 'yyyy-MM-dd'
  @Input() type?: 'date' | string = 'date'
  @Input() hintText: string | undefined;
  @Input() min?: string;
  @Input() max?: string;

  private datePipe: DatePipe

  constructor(
    idService: UniqueIdService,
    @Self() @Optional() override control?: NgControl
  ) {
    super(idService, control)
    this.datePipe = new DatePipe('en-US');
  }

  update(ev: (EventTarget | null)) {
    if (!this.disabled) {
      const t = (ev as HTMLInputElement)
      const __t = this.datePipe.transform(t?.value, this.format) || ''
      this.setValue(__t)
    }
  }
}
