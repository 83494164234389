<div class="cds-sidebar-toggle">
  <button type="button" class="cds-sidebar-toggle-button">
    <i class="fa-solid fa-arrow-left cds-sidebar-toggle-icon expanded"></i>
    <i class="fa-solid fa-arrow-right cds-sidebar-toggle-icon collapsed"></i>
  </button>
</div>
<div class="cds-sidebar-header">
  <div class="cds-sidebar-header-icon">
    <i class="fa-solid fa-user"></i>
  </div>
  <div class="cds-sidebar-header-user">
    <div class="cds-sidebar-header-user-title">{{ user?.name || ('sidebar.noUser' | translate) }}</div>
    <div class="cds-sidebar-header-user-subtitle">{{ user?.email || ('sidebar.noEmail' | translate) }}</div>
    <a class="cds-sidebar-header-action cds-sidebar-header-no-action">
      <div class="cds-sidebar-header-action-text">{{environment.env}} {{ 'sidebar.environment' | translate }}</div>
    </a>
    <a (click)="logout()" class="cds-sidebar-header-action">
      <div class="cds-sidebar-header-action-icon">
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
      </div>
      <div class="cds-sidebar-header-action-text">Logout</div>
    </a>
  </div>
</div>
<div class="cds-sidebar-menu">
  <div class="cds-sidebar-menu-item" style="cursor: pointer;" (click)="expandMenuItem($event)">
    <button class="cds-sidebar-menu-item-toggle" type="button" data-bs-toggle="collapse"
      data-bs-target="#monitoring" [attr.aria-expanded]="true"
      aria-controls="monitoring">
      <i class="collapsed fas fa-caret-right"></i>
      <i class="expanded fas fa-caret-down"></i>
    </button>
    <div class="cds-sidebar-menu-item-icon">
      <i class="fa-solid fa-list-check"></i>
    </div>
    <div class="cds-sidebar-menu-item-text">{{ 'sidebar.monitoring' | translate }}</div>
  </div>
  <div id="monitoring" class="collapse show">
    <a href class="cds-sidebar-menu-item" [routerLinkActive]="['active']"
      [routerLink]="'/monitoring/etl'">
      <div class="cds-sidebar-menu-item-spacer"></div>
      <div class="cds-sidebar-menu-item-icon"><i class="fa-solid fa-upload"></i></div>
      <div class="cds-sidebar-menu-item-text">ETL</div>
    </a>
    <a class="cds-sidebar-menu-item" [routerLinkActive]="['active']"
      [routerLink]="'/monitoring/epd'">
      <div class="cds-sidebar-menu-item-spacer"></div>
      <div class="cds-sidebar-menu-item-icon"><i class="fa-solid fa-download"></i></div>
      <div class="cds-sidebar-menu-item-text">EPD</div>
    </a>
    <a class="cds-sidebar-menu-item" [routerLinkActive]="['active']"
      [routerLink]="'/monitoring/hpd'">
      <div class="cds-sidebar-menu-item-spacer"></div>
      <div class="cds-sidebar-menu-item-icon"><i class="fa-solid fa-database"></i></div>
      <div class="cds-sidebar-menu-item-text">HPD</div>
    </a>
  </div>
  <a class="cds-sidebar-menu-item" [routerLinkActive]="['active']"
    [routerLink]="'/configuration/contributors'">
    <div class="cds-sidebar-menu-item-icon">
      <i class="fa-solid fa-landmark"></i>
    </div>
    <div class="cds-sidebar-menu-item-text">{{ 'sidebar.contributors' | translate }}</div>
  </a>
  <a class="cds-sidebar-menu-item" [routerLinkActive]="['active']"
    [routerLink]="'/configuration/subscribers'">
    <div class="cds-sidebar-menu-item-icon">
      <i class="fa-solid fa-users"></i>
    </div>
    <div class="cds-sidebar-menu-item-text">{{ 'sidebar.subscribers' | translate }}</div>
  </a>
  <div class="cds-sidebar-menu-item" style="cursor: pointer;" (click)="expandMenuItem($event)">
    <button class="cds-sidebar-menu-item-toggle" type="button" data-bs-toggle="collapse"
      data-bs-target="#process-execution" [attr.aria-expanded]="false"
      aria-controls="process-execution">
      <i class="collapsed fas fa-caret-right"></i>
      <i class="expanded fas fa-caret-down"></i>
    </button>
    <div class="cds-sidebar-menu-item-icon">
      <i class="fa-solid fa-bolt"></i>
    </div>
    <div class="cds-sidebar-menu-item-text">{{ 'sidebar.engine' | translate }}</div>
  </div>
  <div id="process-execution" class="collapse">
    <a href class="cds-sidebar-menu-item" [routerLinkActive]="['active']" [routerLink]="'/engine/epd'">
      <div class="cds-sidebar-menu-item-spacer"></div>
      <div class="cds-sidebar-menu-item-icon">
        <i class="fa-solid fa-paper-plane"></i>
      </div>
      <div class="cds-sidebar-menu-item-text">{{ 'sidebar.epdExecution' | translate }}</div>
    </a>
    <a href class="cds-sidebar-menu-item" [routerLinkActive]="['active']" [routerLink]="'/engine/hpd'">
      <div class="cds-sidebar-menu-item-spacer"></div>
      <div class="cds-sidebar-menu-item-icon">
        <i class="fa-solid fa-briefcase"></i>
      </div>
      <div class="cds-sidebar-menu-item-text">{{ 'sidebar.hpdExecution' | translate }}</div>
    </a>
  </div>
  <a class="cds-sidebar-menu-item" [routerLinkActive]="['active']"
    [routerLink]="'/configuration/global-settings'">
    <div class="cds-sidebar-menu-item-icon">
      <i class="fa-solid fa-gears"></i>
    </div>
    <div class="cds-sidebar-menu-item-text">{{ 'sidebar.settings' | translate }}</div>
  </a>
</div>
