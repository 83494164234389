import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component'
import { HpdOutputFilesComponent } from './hpd-output-files/hpd-output-files.component'
import { ErrorModalComponent } from './error-modal/error-modal.component'
import { FormFieldsModalComponent } from './form-fields-modal/form-fields-modal.component'
import { RowDetailsModalComponent } from './row-details-modal/row-details-modal.component'
import { SearchBarComponent } from './search-bar/search-bar.component'

export * from './error-modal/error-modal.component'
export * from './row-details-modal/row-details-modal.component'
export * from './confirm-modal/confirm-modal.component'
export * from './form-fields-modal/form-fields-modal.component'

export const MODALS = [
  ErrorModalComponent,
  RowDetailsModalComponent,
  ConfirmModalComponent,
  SearchBarComponent,
  FormFieldsModalComponent,
  HpdOutputFilesComponent
]