import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CrifAbstractComponent } from './crf-abstract-field.component';
import { UniqueIdService } from '../utils/unique-id.service';

@Component({
  selector: 'crf-checkbox',
  template: `
  <div class="form-check form-switch">
    <label class="form-check-label" [for]="id">
      <span *ngIf="beforeText">{{ label }}</span>
      <input
        class="form-check-input"
        type="checkbox"
        [id]="id"
        [attr.disabled]="disabled?true:undefined"
        [attr.readonly]="readonly?true:undefined"
        [attr.checked]="value?true:undefined"
        [value]="value"
        (blur)="touch();"
        [ngClass]="{'is-invalid': control?.touched && control?.invalid}"
        (input)="update($event.target);"
        >
      <span *ngIf="!beforeText">{{ label }}</span>
    </label>
  </div>`
})
export class CrifCheckboxComponent extends CrifAbstractComponent<boolean> implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() label: string = '';
  @Input() beforeText: boolean = false

  constructor(idService: UniqueIdService, @Self() @Optional() override control?: NgControl) {
    super(idService, control)
  }

  update(ev: (EventTarget | null)) {
    if (!this.disabled) {
      const t = (ev as HTMLInputElement)
      this.setValue(t?.checked)
    }
  }
}
