import { CoreService, StartEPDRequestDTO, StartEPDResponseDTO, StartHPDRequestDTO, StartHPDResponseDTO } from '@/apis/engine';
import { Injectable } from '@angular/core';
import { ErrorUiService } from '@shared/services/error/error-ui.service';
import { Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EngineService {

  constructor(
    private engineServiceApi: CoreService,
    private errorUIService: ErrorUiService) { }

  public startHPD(model: StartHPDRequestDTO): Observable<StartHPDResponseDTO> {
    return this.engineServiceApi.coreControllerStartHPD(model)
      .pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? null : o),
        map(o => o as StartHPDResponseDTO),
        filter(o => !!o),
      )
  }

  public startEPD(model: StartEPDRequestDTO): Observable<StartEPDResponseDTO> {
    return this.engineServiceApi.coreControllerStartEPD(model)
      .pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? null : o),
        map(o => o as StartHPDResponseDTO),
        filter(o => !!o),
      )
  }
}