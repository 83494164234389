import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { CrifAbstractModalComponent } from '@shared/components/crf-modals/crf-abstract-modal.component';
import { DialogCloseReason } from '@shared/components/crf-modals/dialog-data';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent extends CrifAbstractModalComponent<Error & { [k: string]: any }, void> {

  onClose(reason: DialogCloseReason) {
    this.close({ reason, result: null })
  }

  get isHttpError(): boolean {
    return this.data.content?.name === 'HttpErrorResponse'
  }

  get httpErrorResponseStatus(): string {
    return this.data.content ? (this.data.content as HttpErrorResponse).statusText : '';
  }

  get isStringContent(): boolean {
    return typeof(this.data.content) === 'string';
  }
}