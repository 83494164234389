<div class="row">
  <div class="col-5">
    <div class="card h-100 mb-3">
      <div class="card-body">
        <crf-tree-view [nodes]="treeEvents" [maxDepth]="3" [readonly]="readonly" [eventNodes]="treeEvents"
          (depthChange)="treeCurrentDepth=$event" (selectedItem)="treeItemClick($event)"
          (nodeAdd)="treeAddClick($event)" (nodeInsert)="treeInsertClick($event)"
          (nodeRemove)="treeRemoveClick($event)" (nodeClone)="treeCloneClick($event)"
          (nodeDragAndDrop)="treeDragAndDrop($event)"></crf-tree-view>
      </div>
    </div>
  </div>
  <div class="col-7">
    <div class="text-center text-bg-light p-2" *ngIf="treeCurrentDepth == -1 || readonly">
      {{ 'subscribers.events.noEventFilterOrConditionSelected' | translate }}
    </div>
    <fieldset [hidden]="treeCurrentDepth == -1 || readonly">
      <h6>{{ selectedEventName }}</h6>
      <crf-form-fields class="d-block" #eventForm [fields]="eventFormFields" [noActions]="true" [noCard]="true"
        [hidden]="treeCurrentNode == null || readonly || treeCurrentDepth !== 0"
        (formChange)="eventFormChanged($event)" />
      <hr>
      <div class="text-center text-bg-light p-2" *ngIf="treeCurrentDepth < 1">
        {{ 'subscribers.events.noFilterOrConditionSelected' | translate }}
      </div>

      <fieldset [hidden]="treeCurrentDepth < 1">
        <h6>
          {{ selectedFilterName }}
        </h6>
        <crf-form-fields class="d-block" #filterForm [fields]="filterFormFields" [noActions]="true" [noCard]="true"
          (formChange)="filterFormChanged($event)" />
        <hr>

        <div class="text-center text-bg-light p-2" *ngIf="treeCurrentDepth < 3">
          {{ 'subscribers.events.noConditionSelected' | translate }}
        </div>

        <fieldset [hidden]="treeCurrentDepth < 3 ">
          <h6>
            {{ selectedConditionName }}
          </h6>
          <crf-form-fields class="d-block" #conditionForm [fields]="conditionFormFields" [noActions]="true"
            [noCard]="true" [hidden]="treeCurrentNode == null || readonly || treeCurrentDepth !== 3"
            (formChange)="conditionFormChanged($event)" />
        </fieldset>

      </fieldset>
    </fieldset>
  </div>
</div>
