import { Component, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CrifFormFieldsComponent } from '@shared/components/crf-form-fields/crf-form-fields.component';
import { FormFieldsDefinition } from '@shared/components/crf-form-fields/form-fields';
import { CrifAbstractModalComponent } from '@shared/components/crf-modals/crf-abstract-modal.component';
import { DialogCloseReason } from '@shared/components/crf-modals/dialog-data';
import { LanguagesService } from '@shared/services/languages/languages.service';
import { CronDefinition } from './cron-definition';

@Component({
  selector: 'crf-form-fields-modal',
  template: `
    <crf-modal-simple-template [title]="data.title| translate" [okLabel]="data.ok" [cancelLabel]="data.cancel" (close)="onClose($event)">
      <span class="d-block mb-3" [innerHTML]="'common.cron.text' | translate"></span>
      <crf-form-fields #form [fields]="formFields" [noActions]="true" [noCard]="true" (crfSubmit)="submit($event)" />
    </crf-modal-simple-template>
  `
})
export class CronModalComponent extends CrifAbstractModalComponent<CronDefinition, CronDefinition> implements AfterViewInit {
  @ViewChild('form') cronForm!: CrifFormFieldsComponent;

  constructor(activeModal: NgbActiveModal, private languagesService: LanguagesService, private cdRef: ChangeDetectorRef) {
    super(activeModal);
  }

  formFields: FormFieldsDefinition = {
    minute: { labeli18n: "common.cron.minute", type: 'text', cols: "col-6" },
    hour: { labeli18n: "common.cron.hour", type: 'text', cols: "col-6" },
    dayOfMonth: { labeli18n: "common.cron.dayOfMonth", type: 'text', cols: "col-6" },
    month: { labeli18n: "common.cron.month", type: 'text', cols: "col-6" },
    dayOfWeek: { labeli18n: "common.cron.dayOfWeek", type: 'text', cols: "col-6" },
    year: { labeli18n: "common.cron.year", type: 'text', cols: "col-6" },
  }

  onClose(reason: DialogCloseReason) {
    if (reason !== 'ok') this.close({ reason, result: null })
    if (reason === 'ok') {
      this.cronForm?.submitForm()
    }
  }

  ngAfterViewInit() {
    this.cronForm.form.form.setValue(this.data.content || {})
    this.cdRef.detectChanges();
  }

  submit(result: any) {
    this.close({ reason: 'ok', result })
  }
}
