import {
  ContributorCreateDTO,
  ContributorFilterItemDTO,
  SubscriberEventItemDTO,
  SubscriberReadDTO
} from '@/apis/configuration';
import {
  DeliveryReadDTO,
  DeliveryService,
  ElaborationReadDTO,
  ElaborationService,
  ErrorService,
  PresignedUrlDTO
} from '@/apis/monitoring';
import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CrifAbstractModalComponent} from '@shared/components/crf-modals/crf-abstract-modal.component';
import {DialogCloseReason} from '@shared/components/crf-modals/dialog-data';
import {UniqueIdService} from '@shared/components/utils/unique-id.service';
import {ErrorUiService} from '@shared/services/error/error-ui.service';
import {catchError, lastValueFrom, map, of, tap} from 'rxjs';

type DialogData = Error |
  SubscriberReadDTO |
  ContributorCreateDTO |
  SubscriberEventItemDTO |
  ElaborationReadDTO |
  ContributorFilterItemDTO |
  DeliveryReadDTO |
  string

@Component({
  selector: 'app-row-details-modal',
  templateUrl: './row-details-modal.component.html',
  styleUrls: ['./row-details-modal.component.scss']
})

export class RowDetailsModalComponent extends CrifAbstractModalComponent<DialogData, void> implements AfterViewInit {

  fileLoading: boolean = true
  elaborationFileUrl: string | undefined
  elaborationErrorFileUrl: string | undefined

  dataSubscriber!: SubscriberReadDTO
  dataContributor: ContributorCreateDTO | undefined
  dataContributorFilter: ContributorFilterItemDTO | undefined
  dataElaboration: ElaborationReadDTO | undefined

  EPDData: DeliveryReadDTO | undefined
  EPDFileUrls: any[] | undefined

  HPDData: DeliveryReadDTO | undefined
  HPDFileNames: string[] | undefined
  HPDFilesPage: { total: number, current: number, perPage: number } = {total: 0, current: 0, perPage: 5}

  dataDeliveryWithInputParameters: DeliveryReadDTO | undefined

  text: string | undefined

  constructor(
    activeModal: NgbActiveModal,
    private elaborationService: ElaborationService,
    private errorService: ErrorService,
    private deliveryService: DeliveryService,
    private errorUIService: ErrorUiService,
    private uiqueIdService: UniqueIdService,
    private cdref: ChangeDetectorRef
  ) {
    super(activeModal);
  }

  onClose(reason: DialogCloseReason) {
    this.close({reason, result: null})
  }

  ngAfterViewInit(): void {
    this.elaborationErrorFileUrl = ''
    this.fileLoading = true
    switch (this.data.entity) {
      case 'subscriber':
        this.dataSubscriber = this.data.content as SubscriberReadDTO
        break;
      case 'contributor':
        this.dataContributor = this.data.content as ContributorCreateDTO
        break;
      case 'contributors-filter':
        this.dataContributorFilter = this.data.content as ContributorFilterItemDTO
        break;
      case 'elaboration':
        this.dataElaboration = this.data.content as ElaborationReadDTO
        if (this.dataElaboration.filename) {
          this.getElaborationFile(this.dataElaboration).then()
          this.getElaborationErrorFile(this.dataElaboration).then()
        }
        break;
      case 'epd':
        this.EPDData = this.data.content as DeliveryReadDTO
        if (this.EPDData.filenames && this.EPDData.filenames.length > 0) {
          this.getEPDfile(this.EPDData).then()
        }
        break;
      case 'hpd':
        this.HPDData = this.data.content as DeliveryReadDTO
        if (this.HPDData.filenames && this.HPDData.filenames.length > 0) {
          this.getHPDFile(this.HPDData).then()
        }
        break;
      case 'delivery-input-parameters':
        this.dataDeliveryWithInputParameters = this.data.content as DeliveryReadDTO
        break;
      case 'text':
        this.text = this.data.content as string
        break;
    }
    this.cdref.detectChanges()
  }

  async getElaborationFile(etl: ElaborationReadDTO) {
    this.fileLoading = true;
    await lastValueFrom(this.elaborationService.elaborationControllerGeneratePresignedUrl({
      contributorId: etl.contributorId,
      failedId: etl.failedId,
      filename: etl.filename,
      elaborationDate: etl.filename.split('_')[4]?.split('T')[0]
    }).pipe(
      catchError((error) => {
        if (error.status === 404) {
          return of({
            error: false
          });
        } else {
          throw (error);
        }
      }),
      this.errorUIService.manageUI('error'),
      map(o => ((o as any).error === true) ? null : o),
      map(o => o as PresignedUrlDTO),
      tap(o => {
        this.elaborationFileUrl = o?.presignedUrl;
      })));
    this.fileLoading = false;
  }

  async getElaborationErrorFile(etl: ElaborationReadDTO) {
    this.fileLoading = true
    await lastValueFrom(this.errorService.errorControllerGeneratePresignedUrl({
      filename: etl.filename,
      contributionId: etl.contributionId,
      contributorId: etl.contributorId,
      contributionDate: etl.filename.split('_')[4]?.split('T')[0]
    }).pipe(
      catchError((error) => {
        if (error.status === 404) {
          return of({
            error: false
          });
        } else {
          throw (error);
        }
      }),
      this.errorUIService.manageUI('error'),
      map(o => ((o as any).error === true) ? null : o),
      map(o => o as PresignedUrlDTO),
      tap(o => {
        this.elaborationErrorFileUrl = o?.presignedUrl
      })))
    this.fileLoading = false
  }

  async getEPDfile(delivery: DeliveryReadDTO) {
    this.fileLoading = true
    let fileUrls: any[] = []

    for (let deliveryIndex in delivery.filenames) {
      await lastValueFrom(this.deliveryService.deliveryControllerGeneratePresignedUrl({
        filename: delivery.filenames[deliveryIndex],
        subscriberId: delivery.subscriberId,
        deliveryDate: delivery.deliveryDate
      }).pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? null : o),
        map(o => o as PresignedUrlDTO),
        tap(o => {
          fileUrls.push({
            fileUrl: o?.presignedUrl,
            filename: delivery.filenames[deliveryIndex],
            storageClass: o?.storageClass,
            disabled: !(o?.storageClass === "STANDARD" || o?.storageClass === "STANDARD_IA")
          })
        })
      ))
    }

    this.EPDFileUrls = fileUrls
    this.fileLoading = false
  }

  async getHPDFile(delivery: DeliveryReadDTO) {
    this.fileLoading = true
    this.HPDFilesPage = {total: 0, current: 0, perPage: 5}

    let fileNames: string[] = []

    for (let deliveryIndex in delivery.filenames) {
      fileNames.push(delivery.filenames[deliveryIndex])
    }

    this.HPDFileNames = fileNames
    this.HPDFilesPage.total = Math.ceil(fileNames.length / this.HPDFilesPage.perPage)
    this.fileLoading = false
  }

  getElapsedTime(job: ElaborationReadDTO | DeliveryReadDTO): string {
    if (!job.createdAt || !job.completedAt) {
      return "-"
    }
    const diff = new Date(job.completedAt).getTime() - new Date(job.createdAt).getTime();
    const hours = Math.trunc(diff / (1000 * 3600));
    const minutes = Math.trunc((diff - (hours * 3600 * 1000)) / (1000 * 60));
    const seconds = Math.trunc((diff - (hours * 3600 * 1000) - (minutes * 60 * 1000)) / 1000);
    const millis = diff - (hours * 3600 * 1000) - (minutes * 60 * 1000) - (seconds * 1000);
    return hours.toString() + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2) + '.' + ('000' + millis).slice(-3);
  }

  getErrorRatio(etl: ElaborationReadDTO): string {
    if (etl.numberOfInvalidTransactions && etl.numberOfTransactions) {
      return `${((etl.numberOfInvalidTransactions / etl.numberOfTransactions) * 100).toFixed(4)}%`
    }

    return "-"
  }

  trackByFilename(index: number, item: any) {
    return item.filename;
  }

  trackByFilter(index: number, item: ContributorFilterItemDTO) {
    return `${index}-contributor-filter-${item.type}`;
  }
}
