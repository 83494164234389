import { CommonModule } from '@angular/common';
import { NgModule, forwardRef } from '@angular/core';
import { AbstractLoggerService, AuthInterceptor, AuthModule } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { TemplateBaseComponent } from './templates';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from '../app-routing.module';
import { NotFoundComponent, SupportComponent } from './pages';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTranslatePipe } from './pipes/translate/htranslate.pipe';
import { SortableDirective } from './directives/sortable.directive';
import { CrifDtRowComponent } from './components/crf-dt-table/crf-dt-row.component';
import { CrifFormFieldsComponent } from './components/crf-form-fields/crf-form-fields.component';
import { CrifDtCellComponent } from './components/crf-dt-table/crf-dt-cell.component';
import { DataTableComponent } from './components/crf-dt-table/crf-dt-table.component';
import { CrifDtHeaderComponent } from './components/crf-dt-table/crf-dt-header.component';
import { CrifSwitchComponent } from './components/crf-inputs/crf-switch.component';
import { CrifInputComponent } from './components/crf-inputs/crf-input.component';
import { CrifTextAreaComponent } from './components/crf-inputs/crf-text-area.component';
import { CrifCheckboxComponent } from './components/crf-inputs/crf-checkbox.component';
import { ModalSimpleTemplateComponent } from './templates/modal-simple-template/modal-simple-template.component';
import { CrfHeaderComponent } from './components/layout/crf-header.component';
import { CrfSidebarComponent } from './components/layout/crf-sidebar.component';
import { ApisModule } from '@/apis/apis.module';
import { CrifDtActionsComponent } from './components/crf-dt-table/crf-actions.component';
import { CrifDtActionComponent } from './components/crf-dt-table/crf-action.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthLoggerService } from './auth/auth-logger.service';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { OptionComponent } from './components/crf-inputs/options.component';
import { SelectComponent } from './components/crf-inputs/select.component';
import { CrifDatePickerComponent } from './components/crf-inputs/crf-datepicker.component';
import { CrifTreeViewComponent } from './components/crf-dt-tree/tree-view.component';
import { DasherizePipe } from './pipes/dasherize.pipe';
import { CrfCronComponent } from './components/crf-inputs/cron/crf-cron.component';
import { CronModalComponent } from './components/crf-inputs/cron/cron-modal.component';
import { translateBrowserLoaderFactory } from './services/languages/translate-browser.loader';
import { TransferState } from '@angular/platform-browser';
import { CrifCodeBlockComponent } from './components/crf-inputs/crf-code-block.component';
import { CodeDirective } from './directives/code.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiSelectComponent } from './components/crf-inputs/multi-select.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    TemplateBaseComponent,
    NotFoundComponent,
    SupportComponent,
    DataTableComponent,
    HTranslatePipe,
    SortableDirective,
    CrifFormFieldsComponent,
    CrifDtRowComponent,
    CrifDtCellComponent,
    CrifDtHeaderComponent,
    CrifSwitchComponent,
    CrifInputComponent,
    CrifTextAreaComponent,
    CrifCheckboxComponent,
    ModalSimpleTemplateComponent,
    CrfHeaderComponent,
    CrfSidebarComponent,
    CrifDtActionsComponent,
    CrifDtActionComponent,
    ClickStopPropagationDirective,
    OptionComponent,
    SelectComponent,
    CrifDatePickerComponent,
    CrifTreeViewComponent,
    DasherizePipe,
    CrfCronComponent,
    CronModalComponent,
    CrifCodeBlockComponent,
    CodeDirective,
    MultiSelectComponent
  ],
  imports: [
    AuthModule.forRoot({
      config: [{
        redirectUrl: window.location.origin,
        forbiddenRoute: "/forbidden",
        unauthorizedRoute: "/unauthorized",
        scope: "openid",
        responseType: "code",
        ...environment.cognito
      }]
    }),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      defaultLanguage: environment.i18n.defaultLanguage,
    }),
    ApisModule,
    NgSelectModule,
    DragDropModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TemplateBaseComponent,
    NotFoundComponent,
    SupportComponent,
    AuthModule,
    TranslateModule,
    NgbModule,
    ApisModule,
    DataTableComponent,
    HTranslatePipe,
    SortableDirective,
    CrifFormFieldsComponent,
    CrifDtRowComponent,
    CrifDtCellComponent,
    CrifDtHeaderComponent,
    CrifSwitchComponent,
    CrifInputComponent,
    CrifTextAreaComponent,
    CrifCheckboxComponent,
    ModalSimpleTemplateComponent,
    CrfHeaderComponent,
    CrfSidebarComponent,
    CrifDtActionsComponent,
    CrifDtActionComponent,
    ClickStopPropagationDirective,
    OptionComponent,
    SelectComponent,
    CrifDatePickerComponent,
    CrifTreeViewComponent,
    CrfCronComponent,
    CrifCodeBlockComponent,
    CodeDirective,
    MultiSelectComponent,
    DragDropModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: AbstractLoggerService, useClass: AuthLoggerService },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CrifSwitchComponent),
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CrifInputComponent),
      multi: true
    }
  ]
})
export class SharedModule { }
