/**
 * CRIF Data Flow Monitoring
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ErrorReadDTO { 
    type: ErrorReadDTO.TypeEnum;
    failedMessage?: string;
    failedCode?: string;
    stackTrace?: string;
    errorId: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}
export namespace ErrorReadDTO {
    export type TypeEnum = 'ETL' | 'EPD' | 'HPD';
    export const TypeEnum = {
        Etl: 'ETL' as TypeEnum,
        Epd: 'EPD' as TypeEnum,
        Hpd: 'HPD' as TypeEnum
    };
}


