import {BehaviorSubject, filter, lastValueFrom, map, Observable, tap} from 'rxjs';
import {ErrorUiService as ErrorUIService} from '@shared/services/error/error-ui.service';
import {Injectable} from '@angular/core';
import {SortEvent} from '@shared/directives/sortable.directive';
import {reverse, sortBy} from 'lodash-es';

interface PartialResponse<T> {
  data: Array<T>;
  page: number;
  limit: number;
  total: number;
  lastScanned: string;
}

type SearchFunc<Result> = (search?: any, limit?: number, ls?: string) => Observable<PartialResponse<Result>>
type FindFunc<Result> = () => Observable<Result>
type ResultFunf<Result> = () => Observable<Result>
type GFunc<Result> = (limit?: number, ls?: string) => Observable<PartialResponse<Result>>

export type ServiceStatus<T> = { items: T[], loaded: boolean, hasMore: boolean }

@Injectable({
  providedIn: 'root'
})
export abstract class PaginatedService<Result> {

  constructor(private errorUIService: ErrorUIService) {
  }

  private lastScanned: string = ""
  private _hasMore: boolean = false
  private _loaded: boolean = false
  private _items: Array<Result> = []
  private _sortedItems: Array<Result> = []
  private _sortEvent?: SortEvent
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public get hasMore() {
    return this._hasMore
  }

  public get loading() {
    return this._loading
  }

  public get loaded() {
    return this._loaded
  }

  public get items() {
    return this._sortedItems
  }

  public get loading$() {
    return this._loading.asObservable()
  }

  public reset(reload: boolean) {
    if (reload) {
      this.lastScanned = ""
      this._loaded = false
    }
    this._hasMore = false
    this._loading.next(false)
    this._sortedItems = this._items = []
  }

  protected async _search(func: SearchFunc<Result>, limit: number, search: any): Promise<Result[]> {
    this._loading.next(true)
    const res = await lastValueFrom(func(search, limit, this.lastScanned === '' ? undefined : this.lastScanned)
      .pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? {data: [], page: 0, limit: 0, total: 0, lastScanned: ''} : o),
        map(o => o as PartialResponse<Result>),
        tap(o => this.lastScanned = o.lastScanned),
        tap(o => this._hasMore = o.data.length > 0),
        map(o => o.data)
      ));
    this._loading.next(false)
    this._loaded = true
    this._sortedItems = this._items = [...this._items, ...res]
    return this._items
  }

  protected async _load(func: GFunc<Result>, limit: number): Promise<Result[]> {
    this._loading.next(true)
    const res = await lastValueFrom(func(limit, this.lastScanned === '' ? undefined : this.lastScanned)
      .pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? {data: [], page: 0, limit: 0, total: 0, lastScanned: ''} : o),
        map(o => o as PartialResponse<Result>),

        tap(o => this.lastScanned = o.lastScanned),
        tap(o => this._hasMore = o.data.length > 0),
        map(o => o.data)
      ));
    this._loading.next(false)
    this._loaded = true
    this._sortedItems = this._items = [...this._items, ...res]
    return this._items
  }

  protected _get(func: FindFunc<Result>): Observable<Result> {
    return func()
      .pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? null : o),
        map(o => o as Result),
        filter(o => !!o))
  }

  protected _create(func: ResultFunf<Result>): Observable<Result> {
    return func()
      .pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? null : o),
        map(o => o as Result),
        filter(o => !!o),
        tap(o => {
          this.items.push(o)
          this.sort()
        })
      );
  }

  protected _delete<R>(func: ResultFunf<R>, pred: (o: Result) => boolean): Observable<void> {
    return func()
      .pipe(
        this.errorUIService.manageUI('error'),
        tap(o => {
          const idx = this.items.findIndex(o => pred?.(o))
          this.items.splice(idx, 1)
        }),
        map(o => {
          return;
        }),
      );
  }

  protected _update(func: ResultFunf<Result>, pred: (o: Result) => boolean): Observable<Result> {
    return func()
      .pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? null : o),
        map(o => o as Result),
        filter(o => !!o),
        tap(o => {
          const idx = this.items.findIndex(o => pred?.(o))
          this.items[idx] = o
          this.sort()
        }));
  }

  public get status(): ServiceStatus<Result> {
    return {
      hasMore: this.hasMore,
      loaded: this.loaded,
      items: this.items
    }
  }

  public sort(sortEvent?: SortEvent) {
    if (sortEvent) {
      this._sortEvent = sortEvent;
    }

    if (this._sortEvent && this._sortEvent.column !== '') {
      const column = this._sortEvent.column;
      const direction = this._sortEvent.direction;

      if (direction === '') {
        this._sortedItems = this._items;
      } else {
        const sortedItems = sortBy(this._items, [(o: any) => {
          return o[column] || ''
        }]);
        this._sortedItems = direction === 'asc' ? sortedItems : reverse(sortedItems);
      }
    }
  }

  public sortItems(sortEvent: SortEvent, mappingFunction: (item: any) => number) {
    if (sortEvent) {
      this._sortEvent = sortEvent;
    }
    if (sortEvent.direction === '') {
      this._sortedItems = this._items;
      return;
    }

    const sortedItems = sortBy(this._items, [mappingFunction]);
    this._sortedItems = sortEvent.direction === 'asc' ? sortedItems : reverse(sortedItems);
  }
}
