import {Component} from '@angular/core';
import {
  GlobalSettingValueDescriptionComponent
} from "@/pages/global-settings/global-setting-value-description/global-setting-value-description.component";

@Component({
  selector: 'app-global-setting-account-purpose-domain',
  templateUrl: '../global-setting-value-description/global-setting-value-description.component.html',
  styleUrls: ['../global-setting-value-description/global-setting-value-description.component.scss']
})
export class GlobalSettingAccountPurposeDomainComponent extends GlobalSettingValueDescriptionComponent {

  override ngOnInit() {
    this.setGlobalSettingId("accountPurposeDomain");
    this.setActionsMode(false, false);
    super.ngOnInit();
  }

}
