/**
 * CRIF Data Flow Monitoring
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WarningDTO } from './warningDTO';


export interface DeliverySearchDTO { 
    deliveryId?: string;
    type?: string;
    status?: string;
    warnings?: Array<WarningDTO>;
    subscriberId?: string;
    culture?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    updatedAtFrom?: string;
    updatedAtTo?: string;
    completedAtFrom?: string;
    completedAtTo?: string;
}

