<template-base [title]=" 'contributors.title' | translate"
	[subTitle]="(editMode ? 'contributors.edit': 'contributors.create') | translate">
	<div all>
		<crf-form-fields #contributorForm [fields]="formFields" [noActions]="true" [noCard]="true"
			(crfSubmit)="createButtonClick($event)" />
		<div class="row">
			<div class="col-12">
				<crf-dt-table creationStrategy="modal" (createItem)="onCreateFilterItem()" [items]="filterItems"
					[title]="'contributors.filters.title' | translate" showActions="row">
					<th crf-dt-header field="type" type="string">{{ 'contributors.filters.ent.type' | translate }}</th>
					<th crf-dt-header field="match" type="string">{{ 'contributors.filters.ent.match' | translate }}</th>
					<th crf-dt-header field="col" type="string">{{ 'contributors.filters.ent.col' | translate }}</th>
					<th crf-dt-header field="candidates" type="string">{{ 'contributors.filters.ent.candidates' | translate }}</th>
					<th crf-dt-header field="copyColumns" type="string">{{ 'contributors.filters.ent.copyColumns' | translate }}</th>
					<th crf-dt-header field="enabled" type="string">{{ 'contributors.filters.ent.enabled' | translate }}</th>
					<tr crf-dt-row *ngFor="let item of filterItems; trackBy: trackByFilter">
						<td crf-dt-cell>{{ item.type }} </td>
						<td crf-dt-cell>{{ item.match }}</td>
						<td crf-dt-cell>{{ item.col }}</td>
						<td crf-dt-cell style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
							{{ item.candidates }}
						</td>
						<td crf-dt-cell style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
							{{ item.copyColumns }}
						</td>
						<td crf-dt-cell>
							<crf-switch [ngModel]="item.enabled" [disabled]="true" />
						</td>
						<crf-dt-actions>
							<crf-dt-action (click)="onFilterItemEdit(item)">
								{{ 'common.edit' | translate }}
							</crf-dt-action>
							<crf-dt-action (click)="onFilterItemDelete(item)">
								{{'common.delete' | translate}}
							</crf-dt-action>
						</crf-dt-actions>
					</tr>
					<span crf-dt-no-item>
						{{ 'contributors.filters.noItems' | translate }}
					</span>
				</crf-dt-table>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col-3">
				<button [disabled]="loading" type="button" class="btn btn-light mb-2"
					(click)="undoButtonClick()">
          <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="loading"></i>
					{{ 'common.cancel' | translate }}
				</button>
        &nbsp;&nbsp;&nbsp;
				<button [disabled]="loading" type="button" class="btn btn-primary mb-2"
					(click)="contributorForm.submitForm()">
          <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="loading"></i>
					{{ (editMode ? 'common.save' : 'common.create') | translate }}
				</button>
			</div>
      <div class="col-9"></div>
		</div>
	</div>
</template-base>
