<template-base>
	<div all>
		<crf-form-fields #globalSettingForm [fields]="formFields" [noActions]="true" [noCard]="true"
			(crfSubmit)="updateButtonClick($event)" />
		<hr>
		<div class="row">
			<div class="col-12">
				<button [disabled]="loading" type="button" class="btn btn-primary mb-2"
					(click)="globalSettingForm.submitForm()">
          <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="loading"></i>
					{{ 'common.save' | translate }}
				</button>
			</div>
		</div>
	</div>
</template-base>
