import { Injectable } from '@angular/core';
import { ErrorType } from './error';
import { Observable, OperatorFunction, catchError, of } from 'rxjs';
import { environment } from '~/environments/environment';
import { ModalService } from '@shared/components/crf-modals/modal.service';
import { LanguagesService } from '../languages/languages.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorUiService {

  constructor(
    private modalService: ModalService,
    private language: LanguagesService
  ) { }

  private showDialog(type: ErrorType, error?: any) {
    if (!environment.production) {
      console.info("%c Error service: ShowDialog", 'background: #ffcdc9; color: #000', error)
    }
    this.modalService.openDialog<Error, void>('error-modal-component', {
      type,
      title: this.language.translate('dialogs.error.title'),
      content: error,
      action: 'error',
      ok: this.language.translate('common.ok'),
    })
  }

  public manageUI<T>(type: ErrorType = 'error'): OperatorFunction<T, T | { error: boolean, data: any }> {
    return (source: Observable<T>) => {
      return source.pipe(
        catchError((error, k) => {
          this.showDialog(type, error);
          return of({
            error: true,
            data: error
          });
        }),
      );
    };
  }
}
