<template-base [title]="('monitoring.title' | translate) + ' > ' + ('monitoring.etl.title' | translate)">
  <div all>
    <crf-form-fields #filtersForm [fields]="formFields" [noCard]="true" [collapsable]="true"
                     (crfSubmit)="search($event)" (clear)="clearSearch()"
                     [loading]="elaborationsLoading || contributorsLoading"
                     (valueChange)="checkDates($event)"/>
    <crf-dt-table [title]="'monitoring.etl.search.items' | translate" [items]="etl.items" [showRefresh]="etl.loaded"
                  (refreshItem)="refresh()" [showPagination]="etl.hasMore"
                  [loading]="elaborationsLoading || contributorsLoading"
                  (loadMore)="loadMore()" showActions="row">
      <th crf-dt-header field="elaborationId" type="string" sortable="elaborationId" (sort)="onSort($event)">
        {{ 'monitoring.etl.tbl.elaborationId' | translate }}
      </th>
      <th crf-dt-header field="contributorId" type="string" sortable="contributorId" (sort)="onSort($event)">
        {{ 'monitoring.etl.tbl.contributorId' | translate }}
      </th>
      <th crf-dt-header field="contributionId" type="string" sortable="contributionId" (sort)="onSort($event)">
        {{ 'monitoring.etl.tbl.contributionId' | translate }}
      </th>
      <th crf-dt-header field="status" type="string" sortable="status" (sort)="onSort($event)">
        {{ 'monitoring.etl.tbl.status' | translate }}
      </th>
      <th crf-dt-header field="createdAt" type="string" sortable="createdAt" (sort)="onSort($event)">
        {{ 'monitoring.etl.tbl.createdAt' | translate }}
      </th>
      <th crf-dt-header field="completedAt" type="string" sortable="completedAt" (sort)="onSort($event)">
        {{ 'monitoring.etl.tbl.completedAt' | translate }}
      </th>
      <th crf-dt-header field="elapsed" type="string" sortable="elapsed" (sort)="onSort($event)">
        {{ 'monitoring.etl.tbl.elapsed' | translate }}
      </th>
      <th crf-dt-header field="numberOfInvalidTransactions" type="string" sortable="numberOfInvalidTransactions"
          (sort)="onSort($event)">
        {{ 'monitoring.etl.tbl.numberOfInvalidTransactions' | translate }}
      </th>
      <tr crf-dt-row class="align-middle" *ngFor="let item of etl.items; trackBy: trackByETL">
        <td crf-dt-cell>{{ item.elaborationId }}</td>
        <td crf-dt-cell>{{ item.contributorId }}</td>
        <td crf-dt-cell>{{ item.contributionId }}</td>
        <td crf-dt-cell>
          <div class="badge cds-badge active {{ getStatusTextBackground(item) }}">{{ item.status | titlecase }}</div>
        </td>
        <td crf-dt-cell>{{ item.createdAt | date:"dd/MM/yyyy hh:mm a z" }}</td>
        <td crf-dt-cell>{{ item.completedAt | date:"dd/MM/yyyy hh:mm a z" }}</td>
        <td crf-dt-cell>{{ getElapsedTime(item) }}</td>
        <td crf-dt-cell>{{ item.numberOfInvalidTransactions }}</td>
        <crf-dt-actions>
          <crf-dt-action (click)="showDetails(item)">{{ 'monitoring.actions.details' | translate }}</crf-dt-action>
          <crf-dt-action (click)="showInputParameters(item)">
            {{ 'monitoring.actions.inputParameters' | translate }}
          </crf-dt-action>
          <crf-dt-action (click)="showProcessSteps(item)">{{
              'monitoring.actions.processSteps' | translate
            }}
          </crf-dt-action>
        </crf-dt-actions>
      </tr>
      <span crf-dt-no-item>
        {{ 'monitoring.etl.noItems' | translate }}
      </span>
      <span crf-dt-loading>
        {{ 'common.loading' | translate }}
      </span>
    </crf-dt-table>

    <job-steps-table *ngIf="toShowStepForId" jobType="ETL"
                     [jobId]="toShowStepForId??''" (closeCard)="closeStepsCard()"
                     [warnings]="[]">
    </job-steps-table>
  </div>
</template-base>
