import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CrifAbstractComponent } from './crf-abstract-field.component';
import { UniqueIdService } from '../utils/unique-id.service';

@Component({
  selector: 'crf-textarea',
  template: `
    <label [for]="id" class="form-label">
      {{ label }}
      <i class="fa fa-lg fa-info-circle text-info" *ngIf="tooltip" placement="end" [ngbTooltip]="tooltip"></i>
    </label>
    <div>
      <div class="input-group has-validation">
        <textarea [id]="id"
               class="form-control"
               [attr.disabled]="disabled?true:undefined"
               [attr.readonly]="readonly?true:undefined"
               [value]="value"
               (blur)="touch();"
               [ngClass]="{'is-invalid': control?.touched && control?.invalid}"
               (input)="update($event.target);"
               [rows]="rows || 4"
               >
        </textarea>
        <div class="invalid-feedback">
          {{errors | json }}
        </div>
        <br/>
      </div>
      <div class="cds-form-control-hint">
        {{hintText}}
      </div>
    </div>`
})
export class CrifTextAreaComponent extends CrifAbstractComponent<string> implements ControlValueAccessor, OnInit {
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() label: string = '';
  @Input() beforeText: boolean = false
  @Input() placeholder!: string
  @Input() hintText!: string | undefined
  @Input() rows!: number | undefined
  @Input() defaultValue?: string = undefined;
  @Input() tooltip?: string = undefined;

  constructor(idService: UniqueIdService, @Self() @Optional() override control?: NgControl) {
    super(idService, control)
  }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.setValue(this.defaultValue);
    }
  }

  update(ev: (EventTarget | null)) {
    if (!this.disabled) {
      const t = (ev as HTMLInputElement)
      this.setValue(t?.value)
    }
  }
}
