<div *ngIf="delivery">

  <h5 class="modal-title text-primary pb-2">
    {{ 'monitoring.hpd.outputFiles.title' | translate }}
  </h5>

  <div class="mb-3">
    <search-bar (searchEvent)="onSearch($event)"></search-bar>
  </div>

  <crf-dt-table [items]="files??[]">
    <th crf-dt-header field="name" type="string" sortable="date" class="th-lg pointer" (sort)="onSort($event)">
      {{ 'monitoring.hpd.outputFiles.tableHeaderDate' | translate }}
    </th>
    <th crf-dt-header type="string">
      {{ 'monitoring.hpd.outputFiles.tableHeaderName' | translate }}
    </th>
    <th crf-dt-header type="string">
      {{ 'monitoring.hpd.outputFiles.tableHeaderActions' | translate }}
    </th>
    <tr crf-dt-row class="align-middle"
        *ngFor="let item of files?.slice(pagination.first, pagination.last); trackBy: trackByFile">
      <td crf-dt-cell style="width: 25%;">
        <div>
          <span style="min-width: 25%;">
            {{ item.displayName }}
          </span>
        </div>
      </td>
      <td crf-dt-cell style="width: 50%;">
        <div>
          <span style="min-width: 50%; font-size: .9em">
            {{ item.fileName }}
          </span>
        </div>
      </td>
      <td crf-dt-cell>
        <a (click)="downloadHPDFile(item.fileName)"
           [class]="hasOldStorageClass(item) ? 'btn btn-primary btn-sm me-2 disabled' : 'btn btn-primary btn-sm me-2'"
           [attr.disabled]="hasOldStorageClass(item) ? true : null">
          <i class="fa fa-download"></i>
          {{
            hasOldStorageClass(item) ? "Storage - " + getStorageClass(item)
              : 'monitoring.hpd.outputFiles.tableDownloadAction' | translate
          }}
        </a>
      </td>
    </tr>
    <span crf-dt-no-item>
      {{ 'monitoring.hpd.outputFiles.noItems' | translate }}
    </span>
  </crf-dt-table>
</div>

<div class="row mt-3" *ngIf="hasMorePages()">
  <div class="col-12 d-flex justify-content-end align-items-center">
    {{ pagination.first + 1 }}-{{ pagination.last }}&nbsp;&nbsp;{{
      'monitoring.hpd.outputFiles.tablePageDescriptionOf' |
        translate
    }}&nbsp;{{ pagination.total }} {{ 'monitoring.hpd.outputFiles.tablePageDescriptionFiles' | translate }}

    <button (click)="prevPage()" [attr.disabled]="hasPrev()? undefined:'disalbed'"
            class="btn cds-btn btn-primary btn-sm ms-3">
      {{ 'monitoring.hpd.outputFiles.tablePaginationPrev' | translate }}
    </button>

    <button (click)="nextPage()" [attr.disabled]="hasNext()? undefined:'disalbed'"
            class="btn cds-btn btn-primary btn-sm ms-2">
      {{ 'monitoring.hpd.outputFiles.tablePaginationNext' | translate }}
    </button>
  </div>
</div>
