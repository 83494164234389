import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { GlobalSettingsService } from '@/services/global-settings/global-settings.service';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Injectable({
  providedIn: 'root'
})
export class OauthService {

  private configId: string = environment.cognito.configId

  constructor(
    protected http: HttpClient,
    private oidcSecurityService: OidcSecurityService,
    private globalSetting: GlobalSettingsService) {

    oidcSecurityService
      .checkAuthMultiple()
      .subscribe(([{ isAuthenticated, userData, accessToken, idToken }]) => {
        if (isAuthenticated) {
          this.globalSetting.init()
        }
      })
  }

  public login(redirectUrl: string = '') {
    this.oidcSecurityService.authorize(this.configId);
  }

  public logout() {
    this.oidcSecurityService.logoffLocal(this.configId);
    const currentLocation = this.generateLogoutUri();
    window.location.href = `${environment.cognito.authority}/logout?client_id=${environment.cognito.clientId}&logout_uri=${currentLocation}`;
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated(this.configId);
  }

  public get idToken$(): Observable<string> {
    return this.oidcSecurityService.getIdToken(this.configId);
  }

  public get accessToken$(): Observable<string> {
    return this.oidcSecurityService.getAccessToken(this.configId);
  }

  get user$() {
    return this.oidcSecurityService.userData$
  }

  /**
   * this method is used to prevent
   * Client_DOM_XSS attack
   */
  private generateLogoutUri(){
      var url = new URL(window.location.href);
      const baseUrl = environment.baseUrl.endsWith('/') ? environment.baseUrl : `${environment.baseUrl}/`;
      const webBaseUrl = baseUrl.replace('://api.', '://web.');
      const logoutUrl = url.href.startsWith(webBaseUrl) ? url.href : webBaseUrl;
      return decodeURI(sanitizeUrl(logoutUrl));
  }
}
