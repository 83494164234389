<crf-modal-simple-template [title]="data.title" [okLabel]="data.ok" (close)="onClose($event)" [color]="data.type">
  <ng-container *ngIf="dataContributor">
    <div class="mb-2">
      <span class="d-block">
        <strong>{{ 'contributors.form.id' | translate }}</strong>: {{ dataContributor.contributorId }}
      </span>
      <span class="d-block">
        <strong>{{ 'contributors.form.name' | translate }}</strong>: {{ dataContributor.contributorName }}
      </span>
      <span class="d-block" *ngIf="dataContributor.errorThreshold">
        <strong>{{ 'contributors.form.errorThreshold' | translate }}</strong>: {{ dataContributor.errorThreshold }}
      </span>
    </div>
    <h6 class="d-block"> {{ 'contributors.filters.title' | translate }} </h6>
    <crf-dt-table [items]="dataContributor.filters">
      <th crf-dt-header field="type" type="string">{{ 'contributors.filters.ent.type' | translate }}</th>
      <th crf-dt-header field="match" type="string">{{ 'contributors.filters.ent.match' | translate }}</th>
      <th crf-dt-header field="col" type="string">{{ 'contributors.filters.ent.col' | translate }}</th>
      <th crf-dt-header field="candidates" type="string">{{ 'contributors.filters.ent.candidates' | translate }}
      </th>
      <th crf-dt-header field="enabled" type="string">{{ 'contributors.filters.ent.enabled' | translate }}</th>
      <tr crf-dt-row *ngFor="let item of dataContributor.filters; trackBy: trackByFilter">
        <td crf-dt-cell>{{ item.type }}</td>
        <td crf-dt-cell>{{ item.match }}</td>
        <td crf-dt-cell>{{ item.col }}</td>
        <td crf-dt-cell>{{ item.candidates }}</td>
        <td crf-dt-cell>
          <crf-switch [(ngModel)]="item.enabled" [disabled]="true"/>
        </td>
      </tr>
      <span crf-dt-no-item>
        {{ 'contributors.noItems' | translate }}
      </span>
    </crf-dt-table>
  </ng-container>
  <ng-container *ngIf="dataSubscriber">
    <div class="mb-2">
      <span class="d-block">
        <strong>{{ 'subscribers.form.id' | translate }}</strong>: {{ dataSubscriber.subscriberId }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.form.name' | translate }}</strong>: {{ dataSubscriber.subscriberName }}
      </span>
      <span class="d-block">
        <strong>{{ 'subscribers.form.region' | translate }}</strong>: {{ dataSubscriber.region }}
      </span>
    </div>
    <h6 class="d-block"> {{ 'subscribers.events.title' | translate }} </h6>
    <crf-subscriber-tree [events]="dataSubscriber.events" [readonly]="true"/>
  </ng-container>
  <ng-container *ngIf="dataContributorFilter">
    <div class="mb-2">
      <h5 class="d-block"> {{ 'dialogs.detail' | translate }} </h5>
      <span class="d-block">
        <strong>{{ 'contributors.filters.ent.type' | translate }}</strong>: {{ dataContributorFilter.type }}
      </span>
      <span class="d-block">
        <strong>{{ 'contributors.filters.ent.match' | translate }}</strong>: {{ dataContributorFilter.match }}
      </span>
      <span class="d-block">
        <strong>{{ 'contributors.filters.ent.col' | translate }}</strong>: {{ dataContributorFilter.col }}
      </span>
      <span class="d-block">
        <strong>{{ 'contributors.filters.ent.candidates' | translate }}</strong>: {{ dataContributorFilter.candidates }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="dataElaboration">
    <div class="mb-2">
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.elaborationId' | translate }}</strong>: {{ dataElaboration.elaborationId }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.status' | translate }}</strong>: {{ dataElaboration.status }}
      </span>
      <span class="d-block" *ngIf="dataElaboration.failedMessage">
        <strong>{{ 'monitoring.etl.tbl.failedMessage' | translate }}</strong>: {{ dataElaboration.failedMessage }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.contributorId' | translate }}</strong>: {{ dataElaboration.contributorId }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.contributionId' | translate }}</strong>: {{ dataElaboration.contributionId }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.createdAt' | translate }}</strong>:
        {{ dataElaboration.createdAt | date:"dd/MM/yyyy hh:mm a z" }}
      </span>
      <span class="d-block" *ngIf="dataElaboration.completedAt != undefined">
        <strong>{{ 'monitoring.etl.tbl.completedAt' | translate }}</strong>:
        {{ dataElaboration.completedAt | date:"dd/MM/yyyy hh:mm a z" }}
      </span>
      <span class="d-block" *ngIf="dataElaboration.completedAt == undefined">
        <strong>{{ 'monitoring.etl.tbl.completedAt' | translate }}</strong>: -
      </span>
      <span class="d-block" *ngIf="dataElaboration.completedAt != undefined">
        <strong>{{ 'monitoring.etl.tbl.elapsed' | translate }}</strong>:
        {{ getElapsedTime(dataElaboration) }}
      </span>
      <span class="d-block" *ngIf="dataElaboration.completedAt == undefined">
        <strong>{{ 'monitoring.etl.tbl.elapsed' | translate }}</strong>: -
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.numberOfSubjects' | translate }}</strong>:
        {{ dataElaboration.numberOfSubjects }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.numberOfAccounts' | translate }}</strong>:
        {{ dataElaboration.numberOfAccounts }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.numberOfRecords' | translate }}</strong>:
        {{ dataElaboration.numberOfRecords }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.numberOfTransactions' | translate }}</strong>:
        {{ dataElaboration.numberOfTransactions }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.numberOfInvalidTransactions' | translate }}</strong>: {{
          dataElaboration.numberOfInvalidTransactions
        }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.errorRatio' | translate }}</strong>: {{
          getErrorRatio(dataElaboration)
        }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.etl.tbl.errorThreshold' | translate }}</strong>: {{
          dataElaboration.errorThreshold
        }}
      </span>
      <br/>
      <div class="d-flex col">
        <a [attr.disabled]="!elaborationFileUrl ? true:undefined"
           [class]="!elaborationFileUrl ? 'btn btn-primary btn-sm me-2 disabled':'btn btn-primary btn-sm me-2'"
           [attr.href]="elaborationFileUrl ? elaborationFileUrl : undefined"
           [attr.target]="elaborationFileUrl ? '_blank' : undefined">
          <i class="fa fa-download"></i>
          {{ 'monitoring.etl.tbl.downloadInputFile' | translate }}
        </a>
        <a [attr.disabled]="!elaborationErrorFileUrl ? true:undefined"
           [class]="!elaborationErrorFileUrl ? 'btn btn-primary btn-sm me-2 disabled':'btn btn-primary btn-sm me-2'"
           [attr.href]="elaborationErrorFileUrl ? elaborationErrorFileUrl : undefined"
           [attr.target]="elaborationErrorFileUrl ? '_blank' : undefined">
          <i class="fa fa-download"></i>
          {{ 'monitoring.etl.tbl.downloadErrorFile' | translate }}
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="EPDData">
    <div class="mb-2">
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.deliveryId' | translate }}</strong>: {{ EPDData.deliveryId }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.status' | translate }}</strong>: {{ EPDData.status }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.subscriberId' | translate }}</strong>: {{ EPDData.subscriberId }}
      </span>
      <span class="d-block" *ngIf="EPDData.failedMessage">
        <strong>{{ 'monitoring.epd.tbl.failedMessage' | translate }}</strong>: {{ EPDData.failedMessage }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.culture' | translate }}</strong>: {{ EPDData.culture }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.triggerMode' | translate }}</strong>: {{ EPDData.triggerMode?? "-" }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.deliveryDate' | translate }}</strong>: {{ EPDData.deliveryDate }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.createdAt' | translate }}</strong>:
        {{ EPDData.createdAt | date:"dd/MM/yyyy hh:mm a z" }}
      </span>
      <span class="d-block" *ngIf="EPDData.completedAt != undefined">
        <strong>{{ 'monitoring.epd.tbl.completedAt' | translate }}</strong>:
        {{ EPDData.completedAt | date:"dd/MM/yyyy hh:mm a z" }}
      </span>
      <span class="d-block" *ngIf="EPDData.completedAt == undefined">
        <strong>{{ 'monitoring.epd.tbl.completedAt' | translate }}</strong>: -
      </span>
      <span class="d-block" *ngIf="EPDData.completedAt != undefined">
        <strong>{{ 'monitoring.epd.tbl.elapsed' | translate }}</strong>:
        {{ getElapsedTime(EPDData) }}
      </span>
      <span class="d-block" *ngIf="EPDData.completedAt == undefined">
        <strong>{{ 'monitoring.epd.tbl.elapsed' | translate }}</strong>: -
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.numberOfSubjects' | translate }}</strong>:
        {{ EPDData.numberOfSubjects }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.numberOfAccounts' | translate }}</strong>:
        {{ EPDData.numberOfAccounts }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.numberOfTransactions' | translate }}</strong>:
        {{ EPDData.numberOfTransactions }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.epd.tbl.numberOfRecords' | translate }}</strong>:
        {{ EPDData.numberOfRecords }}
      </span>
      <span class="d-block" *ngFor="let item of EPDFileUrls; trackBy: trackByFilename">
        <strong>{{ 'monitoring.epd.tbl.outputFilename' | translate }} </strong>:
        {{ item.filename }}
      </span>
      <br/>
      <span class="d-block h-25 mt-2" *ngFor="let item of EPDFileUrls; let i=index; trackBy: trackByFilename">
        <a [attr.disabled]="!item.fileUrl || item.disabled? true:undefined"
           [class]="item.disabled ? 'btn btn-primary btn-sm me-2 disabled':'btn btn-primary btn-sm me-2'"
           [attr.href]="item.fileUrl ? item.fileUrl:undefined" [attr.target]="item.fileUrl ? '_blank' : undefined">
          <i class="fa fa-download"></i>

          <ng-container *ngIf="!item.disabled">
            {{ 'monitoring.epd.tbl.downloadOutputFile' | translate }} {{
              item.filename | slice:-21:-19
            }}/{{ item.filename | slice:-23:-21 }}/{{ item.filename | slice:-27:-23 }}
          </ng-container>

          {{ item.disabled ? "Storage - " + item.storageClass : "" }}
        </a>
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="HPDData">
    <div class="mb-2">
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.deliveryId' | translate }}</strong>: {{ HPDData.deliveryId }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.status' | translate }}</strong>: {{ HPDData.status }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.subscriberId' | translate }}</strong>: {{ HPDData.subscriberId }}
      </span>
      <span class="d-block" *ngIf="HPDData.failedMessage">
        <strong>{{ 'monitoring.hpd.tbl.failedMessage' | translate }}</strong>: {{ HPDData.failedMessage }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.culture' | translate }}</strong>: {{ HPDData.culture }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.deliveryDate' | translate }}</strong>: {{ HPDData.deliveryDate }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.createdAt' | translate }}</strong>:
        {{ HPDData.createdAt | date:"dd/MM/yyyy hh:mm a z" }}
      </span>
      <span class="d-block" *ngIf="HPDData.completedAt != undefined">
        <strong>{{ 'monitoring.hpd.tbl.completedAt' | translate }}</strong>:
        {{ HPDData.completedAt | date:"dd/MM/yyyy hh:mm a z" }}
      </span>
      <span class="d-block" *ngIf="HPDData.completedAt == undefined">
        <strong>{{ 'monitoring.hpd.tbl.completedAt' | translate }}</strong>: -
      </span>
      <span class="d-block" *ngIf="HPDData.completedAt != undefined">
        <strong>{{ 'monitoring.hpd.tbl.elapsed' | translate }}</strong>:
        {{ getElapsedTime(HPDData) }}
      </span>
      <span class="d-block" *ngIf="HPDData.completedAt == undefined">
        <strong>{{ 'monitoring.hpd.tbl.elapsed' | translate }}</strong>: -
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.numberOfSubjects' | translate }}</strong>:
        {{ HPDData.numberOfSubjects }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.numberOfAccounts' | translate }}</strong>:
        {{ HPDData.numberOfAccounts }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.numberOfTransactions' | translate }}</strong>:
        {{ HPDData.numberOfTransactions }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.numberOfRecords' | translate }}</strong>:
        {{ HPDData.numberOfRecords }}
      </span>
      <span class="d-block">
        <strong>{{ 'monitoring.hpd.tbl.numberOfFiles' | translate }}</strong>:
        {{ HPDData.filenames?.length ?? 0 }}
      </span>
      <br/>
      <hpd-output-files *ngIf="HPDData" [delivery]="HPDData"></hpd-output-files>
    </div>
  </ng-container>

  <ng-container *ngIf="dataDeliveryWithInputParameters">
    <div class="mb-2">
      <span class="d-block">
        <strong>{{ 'engine.form.subscriberId' | translate }}</strong>: {{
          dataDeliveryWithInputParameters?.subscriberId
        }}
      </span>
      <span class="d-block">
        <strong>{{ 'engine.form.culture' | translate }}</strong>: {{ dataDeliveryWithInputParameters?.culture }}
      </span>
      <span class="d-block">
        <strong>{{ 'engine.form.startDate' | translate }}</strong>: {{ dataDeliveryWithInputParameters?.startDate }}
      </span>
      <span class="d-block">
        <strong>{{ 'engine.form.endDate' | translate }}</strong>: {{ dataDeliveryWithInputParameters?.endDate }}
      </span>
      <span class="d-block">
        <strong>{{ 'engine.form.isAccountable' | translate }}</strong>: {{
          dataDeliveryWithInputParameters?.isAccountable
        }}
      </span>
      <span class="d-block">
        <strong>{{ 'engine.form.sendFile' | translate }}</strong>: {{ dataDeliveryWithInputParameters?.sendFile }}
      </span>
      <br/>
      <span class="d-block">
        <strong>{{ 'engine.form.filters' | translate }}</strong>:
      </span>
      <crf-codeblock [defaultValue]="dataDeliveryWithInputParameters.query"/>
    </div>
  </ng-container>

  <ng-container *ngIf="text">
    <span> {{ text }}</span>
  </ng-container>
</crf-modal-simple-template>
