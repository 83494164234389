<template-base [title]="'subscribers.title' | translate" [subTitle]="'subscribers.subTitle' | translate">
  <div all>
    <crf-form-fields [fields]="formFields" [noCard]="true" [collapsable]="true" (crfSubmit)="search($event)"
                     (clear)="clearSearch()" [loading]="loading"/>
    <div class="row">
      <div class="col">
        <crf-dt-table [title]="'subscribers.items' | translate" [items]="subscribers.items"
                      [showRefresh]="subscribers.loaded" (refreshItem)="refresh()"
                      [showPagination]="subscribers.hasMore"
                      [loading]="loading" creationStrategy="page" creationUrl="/configuration/subscribers/create"
                      (loadMore)="loadMore()" showActions="row">
          <th crf-dt-header field="subscriberId" type="string" sortable="subscriberId" (sort)="onSort($event)">
            {{ 'subscribers.tbl.subscriberId'|translate }}
          </th>
          <th crf-dt-header field="subscriberName" type="string" sortable="subscriberName" (sort)="onSort($event)">
            {{ 'subscribers.tbl.subscriberName'|translate }}
          </th>
          <th crf-dt-header field="events" type="string" sortable="events" (sort)="onSort($event)">
            {{ 'subscribers.tbl.events'|translate }}
          </th>
          <th crf-dt-header field="region" type="string" sortable="region" (sort)="onSort($event)">
            {{ 'subscribers.tbl.region'|translate }}
          </th>
          <th crf-dt-header field="createdAt" type="string" sortable="createdAt" (sort)="onSort($event)">
            {{ 'subscribers.tbl.createdAt'|translate }}
          </th>
          <th crf-dt-header field="updatedAt" type="string" sortable="updatedAt" (sort)="onSort($event)">
            {{ 'subscribers.tbl.updatedAt'|translate }}
          </th>
          <tr crf-dt-row *ngFor="let item of subscribers.items; trackBy: trackBySubscriber">
            <td crf-dt-cell>{{ item.subscriberId }}</td>
            <td crf-dt-cell>{{ item.subscriberName }}</td>
            <td crf-dt-cell>{{ (item.events.length > 0 ? 'common.yes' : 'common.no') | translate }}</td>
            <td crf-dt-cell>{{ getRegionName(item.region) }}</td>
            <td crf-dt-cell>{{ item.createdAt | date:"dd/MM/yyyy hh:mm a z" }}</td>
            <td crf-dt-cell>{{ item.updatedAt | date:"dd/MM/yyyy hh:mm a z" }}</td>
            <crf-dt-actions>
              <crf-dt-action (click)="onEdit(item)">{{ 'common.view' | translate }}</crf-dt-action>
              <crf-dt-action (click)="onDelete(item)">{{ 'common.delete' | translate }}</crf-dt-action>
            </crf-dt-actions>
          </tr>
          <span crf-dt-no-item>
            {{ 'subscribers.noItems' | translate }}
          </span>
          <span crf-dt-loading>
            {{ 'common.loading' | translate }}
          </span>
        </crf-dt-table>
      </div>
    </div>
  </div>
</template-base>
