import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {filter, lastValueFrom, map, Subscription, switchMap, tap} from 'rxjs';
import {GlobalSettingReadDTO} from "@/apis/monitoring";
import {ValueDescriptionDto} from "@/pages/global-settings/global-setting-value-description/valueDescription.dto";
import {CrifFormFieldsComponent} from "@shared/components/crf-form-fields/crf-form-fields.component";
import {SortableDirective, SortEvent} from '@shared/directives/sortable.directive';
import {reverse, sortBy} from 'lodash-es';

@Component({
  selector: 'app-global-setting-value-description',
  templateUrl: './global-setting-value-description.component.html',
  styleUrls: ['./global-setting-value-description.component.scss']
})
export class GlobalSettingValueDescriptionComponent implements OnInit, OnDestroy {
  @ViewChild('filtersForm') filtersForm!: CrifFormFieldsComponent;
  formFields: FormFieldsDefinition

  globalSettingId: string = ""

  items: ValueDescriptionDto[] = [];
  searchableItems: ValueDescriptionDto[] = [];
  sortedItems: ValueDescriptionDto[] = [];
  sortEvent?: SortEvent;
  loading: boolean = false;
  enableColumnActions: boolean = false;
  enableAddButton: boolean = false;
  valueMapping: string = "";
  descriptionMapping: string = "";

  @ViewChildren(SortableDirective) headers!: QueryList<SortableDirective>;

  private subscription: Subscription = new Subscription();

  constructor(
    private modalService: ModalService,
    private language: LanguagesService,
    private globalSettingsService: GlobalSettingsService
  ) {
    this.formFields = {};
  }

  setGlobalSettingId(globalSettingId: string) {
    this.globalSettingId = globalSettingId
  }

  setActionsMode(enableColumnActions: boolean = false, enableAddButton: boolean = false) {
    this.enableColumnActions = enableColumnActions;
    this.enableAddButton = enableAddButton;
  }

  setMapping(valueMapping: string, descriptionMapping: string) {
    this.valueMapping = valueMapping;
    this.descriptionMapping = descriptionMapping;
  }

  async search(data: ValueDescriptionDto) {
    this.items = this.searchableItems;
    if (data.description && data.description.length > 0)
      this.items = this.items.filter(item => item.description.toLowerCase().includes(data.description));
    if (data.value && data.value.length > 0)
      this.items = this.items.filter(item => item.value.toLowerCase().includes(data.value))
    this.sort();
  }

  async clearSearch() {
    this.items = this.searchableItems;
    this.sort();
  }

  onCreateItem() {
    this.subscription = this.modalService.openDialog<FormFieldsDefinition, ValueDescriptionDto>('form-fields-modal-component', {
      type: 'info',
      title: this.language.translate(`globalSetting.${this.globalSettingId}.modal.addTitle`),
      content: {
        value: {
          labeli18n: `globalSetting.${this.globalSettingId}.field.value.label`,
          type: 'text',
          cols: "col-12"
        },
        description: {
          labeli18n: `globalSetting.${this.globalSettingId}.field.description.label`,
          type: 'text',
          cols: "col-12"
        },
      },
      action: 'details',
      entity: 'value-description',
      ok: this.language.translate('common.ok'),
      cancel: this.language.translate('common.cancel'),
    }).pipe(
      filter(o => o.reason === 'ok'),
      filter(o => !!o.result),
      map(o => o.result as ValueDescriptionDto),
      tap(async o => {
        let newSettings = this.items;
        newSettings.push(o);
        newSettings = newSettings.sort(
          (a: ValueDescriptionDto, b: ValueDescriptionDto) => a.value != undefined && b.value != undefined && a.value > b.value || '' ? 1 : -1
        );
        this.items = [];
        this.loading = true;
        await lastValueFrom(this.globalSettingsService.update(this.globalSettingId, {
            settings: newSettings,
          })
            .pipe(
              filter(o => !!o),
              tap(() => this.items = newSettings),
              tap(() => this.searchableItems = newSettings),
              tap(() => this.loading = false),
              tap(() => this.sort()),
              switchMap(o =>
                this.modalService.openDialog<string, void>('error-modal-component', {
                  title: this.language.translate(`globalSetting.${this.globalSettingId}.modal.addTitle`),
                  content: this.language.translate(`globalSetting.${this.globalSettingId}.modal.addText`),
                  ok: this.language.translate('common.ok'),
                  action: 'update-success',
                  type: 'success'
                })
              ),
            )
        );
      })
    ).subscribe();
  }

  async onItemDelete(itemToDelete: ValueDescriptionDto): Promise<void> {
    let newSettings = this.items.filter(item => item.value !== itemToDelete.value);
    this.loading = true;
    this.items = [];
    await lastValueFrom(this.globalSettingsService.update(this.globalSettingId, {
        settings: newSettings,
      })
        .pipe(
          filter(o => !!o),
          tap(() => this.items = newSettings),
          tap(() => this.searchableItems = newSettings),
          tap(() => this.loading = false),
          tap(() => this.sort()),
          switchMap(o =>
            this.modalService.openDialog<string, void>('error-modal-component', {
              title: this.language.translate(`globalSetting.${this.globalSettingId}.modal.deleteTitle`),
              content: this.language.translate(`globalSetting.${this.globalSettingId}.modal.deleteText`),
              ok: this.language.translate('common.ok'),
              action: 'update-success',
              type: 'success'
            })
          ),
        )
    );
  }

  async loadGlobalSetting(): Promise<void> {
    await lastValueFrom(this.globalSettingsService.findOne(this.globalSettingId)
      .pipe(
        tap((globalSettingReadDTO: GlobalSettingReadDTO) => {
          const settings: any = globalSettingReadDTO.settings && globalSettingReadDTO.settings.length > 0 ?
            this.getTransformedSettings(globalSettingReadDTO.settings) : [];
          this.items = settings;
          this.searchableItems = settings;
          this.sort();
        })
      )
    );
  }

  private getTransformedSettings(settings: any[]): any[] {
    return this.valueMapping && this.descriptionMapping ?
        settings.map(item => {
          return { value: item[this.valueMapping], description: item[this.descriptionMapping] };
        }) : settings;
  }

  ngOnInit(): void {
    this.loadGlobalSetting().then();
    this.formFields = {
      value: {labeli18n: `globalSetting.${this.globalSettingId}.field.value.label`, type: 'text', cols: "col-6"},
      description: {
        labeli18n: `globalSetting.${this.globalSettingId}.field.description.label`,
        type: 'text',
        cols: "col-6"
      }
    }
  }

  onSort(sortEvent: SortEvent) {
    if (sortEvent !== undefined) {
      this.sortEvent = sortEvent;
    }
    this.sort();
  }

  private sort() {
    if (this.sortEvent === undefined) {
      this.sortedItems = this.items;
      return;
    }

    const column = this.sortEvent.column;

    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (column !== '') {
      const direction = this.sortEvent.direction;
      if (direction === '') {
        this.sortedItems = this.items;
      } else {
        const sortedItems = sortBy(this.items, [(o: any) => {
          return o[column] || ''
        }]);
        this.sortedItems = direction === 'asc' ? sortedItems : reverse(sortedItems);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  trackByValueDescription(index: number, item: ValueDescriptionDto) {
    return item.value + item.description;
  }
}
