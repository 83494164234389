import {SubscriberReadDTO, SubscriberSearchDTO} from '@/apis/configuration';
import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {ServiceStatus} from '@/services/paginated.service';
import {SubscribersService} from '@/services/subscribers/subscribers.service';
import {Component, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {SortableDirective, SortEvent} from '@shared/directives/sortable.directive';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {filter, lastValueFrom, Subscription, switchMap, tap} from 'rxjs';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent {

  subscribers: ServiceStatus<SubscriberReadDTO>
  formFields: FormFieldsDefinition
  private subscriptions: Subscription[] = []
  private _search: SubscriberSearchDTO = {}
  public loading: boolean = false;

  @ViewChildren(SortableDirective) headers!: QueryList<SortableDirective>;

  constructor(
    private subscribersService: SubscribersService,
    private languagesService: LanguagesService,
    private modalService: ModalService,
    private router: Router,
    private globalSettingsService: GlobalSettingsService
  ) {
    this.subscribers = subscribersService.status
    this.formFields = {
      subscriberName: { labeli18n: "subscribers.search.subscriberName", type: 'text', cols: "col-4" },
      subscriberId: { labeli18n: "subscribers.search.subscriberId", type: 'text', cols: "col-4" },
      region: {
        labeli18n: "subscribers.search.region", type: 'select', cols: "col-4",
        options: globalSettingsService.regions
      },
      updatedAtFrom: { labeli18n: "subscribers.search.updatedAtFrom", type: 'date', format: 'yyyy-MM-dd', cols: "col-4" },
      updatedAtTo: { labeli18n: "subscribers.search.updatedAtTo", type: 'date', format: 'yyyy-MM-dd', cols: "col-4" },
    }
    this.subscriptions.push(this.subscribersService.loading$.subscribe(o => this.loading = o))
    globalSettingsService.initialized.subscribe(o => {
      if (o) {
        let regions = globalSettingsService.regions ? [...globalSettingsService.regions] : [];
        regions.unshift({value: "", text: ""});
        this.formFields['region'].options = regions;
      }
    })
  }

  async search(data: SubscriberSearchDTO) {
    this._search = data
    this.subscribersService.reset(true)
    this.subscribers = this.subscribersService.status
    await this.subscribersService.search(100, this._search)
    this.subscribers = this.subscribersService.status
  }

  async loadMore() {
    await this.subscribersService.search(100, this._search)
    this.subscribers = this.subscribersService.status
  }

  async clearSearch() {
    this._search = {}
    this.subscribersService.reset(true)
    this.subscribers = this.subscribersService.status
    await this.subscribersService.search(100, this._search)
    this.subscribers = this.subscribersService.status
  }

  ngOnInit(): void {
    this.globalSettingsService.init();
    if (!this.subscribers.loaded) {
      this.subscribersService.reset(true)
      this.subscribers = this.subscribersService.status
      this.subscribersService.search(100, this._search)
        .then(() => {
          this.subscribers = this.subscribersService.status
        }).catch()
    }
  }

  async refresh() {
    this.subscribersService.reset(true)
    this.subscribers = this.subscribersService.status
    await this.subscribersService.search(100, this._search)
    this.subscribers = this.subscribersService.status
  }

  showDetails(rowItem: SubscriberReadDTO) {
    this.modalService.openDialog<SubscriberReadDTO, void>('row-details-modal-component', {
      type: 'info',
      title: this.languagesService.translate('subscribers.detail'),
      content: rowItem,
      action: 'details',
      entity: 'subscriber',
      ok: this.languagesService.translate('common.ok'),
    })
  }

  getRegionName(regionId: string | undefined): string {
    if (!regionId) return "";
    return this.formFields['region'].options?.find(item => item.value === regionId)?.text || "";
  }

  async onDelete(item: SubscriberReadDTO) {
    await lastValueFrom(this.modalService.openDialog<SubscriberReadDTO, boolean>('confirm-modal-component', {
      type: 'warning',
      title: this.languagesService.translate('dialogs.subscribers.titleDelete'),
      content: item,
      action: 'delete-confirm',
      entity: 'subscriber',
      ok: this.languagesService.translate('common.ok'),
      cancel: this.languagesService.translate('common.cancel')
    }).pipe(
      filter(o => o.reason === 'ok'),
      switchMap(o => this.subscribersService.delete(item.subscriberId)),
      tap(o => this.modalService.openDialog<string, void>('error-modal-component', {
        title: this.languagesService.translate('subscribers.modal.deleteTitle'),
        content: this.languagesService.translate('subscribers.modal.deleteText'),
        ok: this.languagesService.translate('common.ok'),
        action: 'delete-success',
        type: 'success'
      }))
    )).catch(reason => {});
  }

  async onEdit(item: SubscriberReadDTO) {
    return await this.router.navigate([`/configuration/subscribers/${item.subscriberId}`])
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s?.unsubscribe()
    }
  }

  onSort(sortEvent: SortEvent) {
    for (const header of this.headers) {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    }
    this.subscribersService.sort(sortEvent);
    this.subscribers = this.subscribersService.status;
  }

  trackBySubscriber(index: number, item: SubscriberReadDTO) {
    return item.subscriberId;
  }
}
