<div class=" mb-3">
  <div class="container-fluid" *ngIf="!(isHandset$ | async) else mobileMode">
    <div class="row">
      <div class="col-12">
        <h1 *ngIf="title">{{ title }}</h1>
        <h3 *ngIf="subTitle">{{ subTitle }}</h3>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <ng-container [ngTemplateOutlet]="all"></ng-container>
        <ng-container [ngTemplateOutlet]="default"></ng-container>
      </div>
    </div>
  </div>
  <ng-template class="container-fluid" #mobileMode>
    <ng-container [ngTemplateOutlet]="all"></ng-container>
    <ng-container [ngTemplateOutlet]="mobile"></ng-container>
  </ng-template>
</div>

<ng-template #all>
  <ng-content select="[all]"></ng-content>
</ng-template>

<ng-template #default>
  <ng-content select="[default]"></ng-content>
</ng-template>

<ng-template #mobile>
  <ng-content select="[mobile]"></ng-content>
</ng-template>