import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { EngineEpdComponent } from './engine/engine-epd/engine-epd.component';
import { EngineHpdComponent } from './engine/engine-hpd/engine-hpd.component';
import { AddSubscriberComponent } from './configuration/add-subscriber/add-subscriber.component';
import { AddContributorComponent } from './configuration/add-contributor/add-contributor.component';
import { ContributorsComponent } from './configuration/contributors/contributors.component';
import { SubscribersComponent } from './configuration/subscribers/subscribers.component';
import { ElaborationsComponent } from './monitoring/elaborations/elaborations.component';
import { DeliveryEPDComponent } from './monitoring/delivery-epd/delivery-epd.component';
import { DeliveryHPDComponent } from './monitoring/delivery-hpd/delivery-hpd.component';
import { SubscriberTreeComponent } from './components/subscriber-tree/subscriber-tree.component';
import { GlobalSettingHomeComponent } from "@/pages/global-settings/global-setting-home/global-setting-home.component";
import {
  GlobalSettingValueDescriptionComponent
} from "@/pages/global-settings/global-setting-value-description/global-setting-value-description.component";
import {
  GlobalSettingCurrencyDomainComponent
} from "@/pages/global-settings/global-setting-currency-domain/global-setting-currency-domain.component";
import {
  GlobalSettingMerchantCategoryCodeDomainComponent
} from "@/pages/global-settings/global-setting-merchant-category-code-domain/global-setting-merchant-category-code-domain.component";
import {
  GlobalSettingPurposeCodeDomainComponent
} from "@/pages/global-settings/global-setting-purpose-code-domain/global-setting-purpose-code-domain.component";
import {
  GlobalSettingCountryCodeDomainComponent
} from "@/pages/global-settings/global-setting-country-code-domain/global-setting-country-code-domain.component";
import {
  GlobalSettingDynamicFormComponent
} from "@/pages/global-settings/global-setting-dynamic-form/global-setting-dynamic-form.component";
import {
  GlobalSettingSendMailComponent
} from "@/pages/global-settings/global-setting-send-mail/global-setting-send-mail.component";
import {
  GlobalSettingElaborationConfigurationComponent
} from "@/pages/global-settings/global-setting-elaboration-configuration/global-setting-elaboration-configuration.component";
import { JobStepsComponent } from './monitoring/job-step/job-steps.component';
import {
  GlobalSettingAccountPurposeDomainComponent
} from '@/pages/global-settings/global-setting-account-purpose-domain/global-setting-account-purpose-domain.component';
import {
  GlobalSettingTransactionCultureDomainComponent
} from '@/pages/global-settings/global-setting-transaction-culture-domain/global-setting-transaction-culture-domain.component';

@NgModule({
  declarations: [
    AddContributorComponent,
    AddSubscriberComponent,
    ContributorsComponent,
    SubscribersComponent,
    GlobalSettingCountryCodeDomainComponent,
    GlobalSettingCurrencyDomainComponent,
    GlobalSettingDynamicFormComponent,
    GlobalSettingElaborationConfigurationComponent,
    GlobalSettingHomeComponent,
    GlobalSettingMerchantCategoryCodeDomainComponent,
    GlobalSettingPurposeCodeDomainComponent,
    GlobalSettingSendMailComponent,
    GlobalSettingValueDescriptionComponent,
    EngineEpdComponent,
    EngineHpdComponent,
    HomeComponent,
    ElaborationsComponent,
    DeliveryEPDComponent,
    DeliveryHPDComponent,
    SubscriberTreeComponent,
    JobStepsComponent,
    GlobalSettingAccountPurposeDomainComponent,
    GlobalSettingTransactionCultureDomainComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule
  ],
  exports: [
    AddContributorComponent,
    AddSubscriberComponent,
    ContributorsComponent,
    SubscribersComponent,
    EngineEpdComponent,
    EngineHpdComponent,
    HomeComponent,
    ElaborationsComponent,
    DeliveryEPDComponent,
    DeliveryHPDComponent,
    SubscriberTreeComponent,
    JobStepsComponent
  ],
})
export class PagesModule {
}
