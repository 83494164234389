<div class="cds-body">
    <div class="cds-sidebar"></div>
    <div class="cds-content">
        <div class="cds-container container-fluid">
            <crf-header image="assets/img/product_logo.svg"></crf-header>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div *ngIf="!user" class="bg-white opacity-75 fixed-top w-100 h-100">
  <div class="text-center position-absolute top-50 start-50 translate-middle">
    <h1 class="text-nowrap">{{ "common.sessionExpired.title" | translate }}</h1>
    <h5 class="text-nowrap mb-5">{{ "common.sessionExpired.description" | translate }}</h5>
    <div class="cds-loader"></div>
  </div>
</div>
