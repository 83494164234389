<div class="row" *ngIf="creationStrategy !=='none' || title || showRefresh">
  <div class="col">
    <h3 *ngIf="title" class="float-start"> {{ titleWithItemsCount }}</h3>
    <ng-container *ngIf="creationStrategy === 'page'">
      <button type="button" class="btn btn-outline-primary float-end mb-2 me-3" [routerLink]="creationUrl">
        <i class="fa-solid fa-plus"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="creationStrategy === 'modal'">
      <button type="button" class="btn btn-outline-primary float-end mb-2 me-3" (click)="createItem.emit()">
        <i class="fa-solid fa-plus"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="showRefresh">
      <button type="button" class="btn btn-outline-primary float-end mb-2 me-3" (click)="refreshItem.emit()"
        [disabled]="loading">
        <i class="fa-solid fa-arrows-rotate"></i>
      </button>
    </ng-container>
  </div>
</div>
<div class="row">
  <div class="col">
    <table class="table table-striped">
      <thead>
        <tr>
          <ng-container #projectedDisplayColumns></ng-container>
          <th *ngIf="hasActions" crf-dt-cell>Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-content select="tr[crf-dt-row]" />
        <tr crf-dt-row *ngIf="!loading && items.length === 0" class="text-center">
          <td crf-dt-cell [colSpan]="cols" style="border: 0;">
            <ng-content select="[crf-dt-no-item]" />
          </td>
        </tr>
        <tr crf-dt-row *ngIf="loading" class="text-center">
          <td crf-dt-cell [colSpan]="cols" style="border: 0;">
            <ng-content select="[crf-dt-loading]" />
          </td>
        </tr>
      </tbody>
      <tfoot *ngIf="showPagination">
        <tr crf-dt-row>
          <td crf-dt-cell [colSpan]="cols" style="border: 0;">
            <button type="button" class="btn btn-primary float-end btn-sm mt-2" [disabled]="loading"
              (click)="loadMore.emit()">
              <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="loading"></i>
              {{ 'common.lmore' | translate }}
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
