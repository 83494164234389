import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {CrifAbstractComponent} from './crf-abstract-field.component';
import {UniqueIdService} from '../utils/unique-id.service';
import {FormFieldOption} from '../crf-form-fields/form-fields';

@Component({
  selector: 'crf-input',
  template: `
    <label [for]="id" class="form-label">{{ label }}</label>
    <div class="input-group has-validation">
      <input #input
        [id]="id"
        class="form-control"
        [type]="type"
        [attr.disabled]="disabled?true:undefined"
        [attr.readonly]="readonly?true:undefined"
        [value]="value ? value : defaultValue ? defaultValue : ''"
        (blur)="touch();"
        [ngClass]="{'is-invalid': control?.touched && control?.invalid}"
        (input)="update($event.target);"
        [placeholder]="placeholder"
        autocomplete="off"
        [attr.list]="id+'-dl'">
      <datalist [id]="id+'-dl'">
        <option *ngFor="let item of datalist; trackBy: trackByData" [value]="item.value">
          {{item.text}}
        </option>
      </datalist>
      <div class="invalid-feedback">
        {{errors | json }}
      </div>
    </div>
    <div class="cds-form-control-hint">
      {{hintText}}
    </div>`
})
export class CrifInputComponent extends CrifAbstractComponent<string> implements ControlValueAccessor, OnInit {
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() label: string = '';
  @Input() beforeText: boolean = false
  @Input() type: "text" | 'email' | 'number' | 'password' | 'search' | 'tel' | 'url' | string = 'text'
  @Input() placeholder!: string
  @Input() defaultValue!: string
  @Input() hintText: string | undefined;
  @Input() datalist: FormFieldOption[] | undefined;

  constructor(idService: UniqueIdService, @Self() @Optional() override control?: NgControl) {
    super(idService, control)
  }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.setValue(this.defaultValue);
    }
  }

  update(ev: (EventTarget | null)) {
    if (!this.disabled) {
      const t = (ev as HTMLInputElement)
      this.setValue(t?.value)
    }
  }

  trackByData(index: number, item: FormFieldOption) {
    return `${index}-input-${this.id}`;
  }
}
