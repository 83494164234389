import {StartEPDRequestDTO} from '@/apis/engine';
import {EngineService} from '@/services/engine/engine.service';
import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {SubscribersService} from '@/services/subscribers/subscribers.service';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {CrifFormFieldsComponent} from '@shared/components/crf-form-fields/crf-form-fields.component';
import {FormFieldOption, FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {lastValueFrom, filter, switchMap, tap} from 'rxjs';
import {SubscriberReadDTO} from "@/apis/configuration";

@Component({
  selector: 'app-engine-epd',
  templateUrl: './engine-epd.component.html',
  styleUrls: ['./engine-epd.component.scss']
})
export class EngineEpdComponent implements AfterViewInit {
  formFields: FormFieldsDefinition
  disabled: boolean = false
  @ViewChild('iFormFields') iFormFields!: CrifFormFieldsComponent;
  subscribers: any[] = []
  subscribersService: SubscribersService
  globalSettings: GlobalSettingsService

  constructor(private modalService: ModalService, private language: LanguagesService, private engineService: EngineService, globalSettings: GlobalSettingsService, subscribersService: SubscribersService) {
    this.subscribersService = subscribersService
    this.globalSettings = globalSettings
    this.formFields = {}
  }

  async startEngine(value: StartEPDRequestDTO) {
    if (!value.subscriberId || !value.culture) {
      this.modalService.openDialog<string, void>('error-modal-component', {
        type: 'error',
        title: this.language.translate('dialogs.error.title'),
        content: this.language.translate("engine.epd.mandatoryError"),
        action: 'error',
        ok: this.language.translate('common.ok'),
      })

      return;
    }

    this.disabled = true;

    lastValueFrom(this.modalService.openDialog<StartEPDRequestDTO, boolean>('confirm-modal-component', {
      type: 'warning',
      title: this.language.translate('dialogs.engine.titleConfirm'),
      content: value,
      action: 'start-confirm',
      entity: 'start-epd',
      ok: this.language.translate('common.ok'),
      cancel: this.language.translate('common.cancel')
    }).pipe(
      filter(o => o.reason === 'ok'),
      switchMap(o => this.engineService.startEPD(value)),
      tap(o => {
        this.modalService.openDialog<string, void>('error-modal-component', {
          type: 'info',
          title: this.language.translate('dialogs.detail'),
          content: this.language.translate("engine.form.submitted"),
          action: 'details',
          ok: this.language.translate('common.ok'),
        });
        this.disabled = false;
      })
    )).catch(reason => {
      this.disabled = false;
    });
  }

  async ngAfterViewInit(): Promise<void> {
    this.subscribersService.load(999999).then((response: SubscriberReadDTO[]) => {
      this.subscribers = response.map((subscriber: SubscriberReadDTO) => ({
        value: subscriber.subscriberId,
        text: `${subscriber.subscriberId} - ${subscriber.subscriberName}`,
        events: subscriber.events
      }));

      this.formFields = {
        subscriberId: {
          labeli18n: "engine.form.subscriberId", type: 'select', cols: "col-6",
          options: this.subscribers
        },
        culture: {
          labeli18n: "engine.form.culture", type: 'select', cols: "col-6",
          options: []
        },
      }
    });
  }

  updateCultures(data: StartEPDRequestDTO) {
    if (data.hasOwnProperty('subscriberId')) {

      let cultures: FormFieldOption[] = [];
      if (data.subscriberId) {
        const subscriber = this.subscribers.find(s => s.value === data.subscriberId);
        const eventCultures = subscriber.events.map((e: { culture: any; }) => e.culture);
        cultures = this.globalSettings.cultures?.filter(c => eventCultures.includes(c.value)) || [];
      }
      this.formFields['culture'].options = cultures;

      if (this.iFormFields.form.form.controls['culture'].value)
        this.iFormFields.form.form.controls['culture'].setValue('');
    }
  }
}
