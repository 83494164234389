import {
  SubscriberEventItemDTO,
  SubscriberFilterItemDTO,
  SubscriberGroupsDTO,
  SubscriberGroupsItemDTO
} from '@/apis/configuration';
import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TreeNode} from '@shared/components/crf-dt-tree/tree-node.model';
import {CrifFormFieldsComponent} from '@shared/components/crf-form-fields/crf-form-fields.component';
import {FormFieldOption, FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {UniqueIdService} from '@shared/components/utils/unique-id.service';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {cloneDeep} from 'lodash-es';
import {filter, Subscription, tap} from 'rxjs';

type TreeEventDataType = SubscriberEventItemDTO | SubscriberFilterItemDTO | SubscriberGroupsItemDTO

export class TreeNodePath {
  node: TreeNode<any>;
  level: number;
  childIndex: number;

  constructor(node: TreeNode<any>, level: number, childIndex: number) {
    this.node = node;
    this.level = level;
    this.childIndex = childIndex;
  }
}

@Component({
  selector: 'crf-subscriber-tree',
  templateUrl: './subscriber-tree.component.html',
  styleUrls: ['./subscriber-tree.component.scss']
})
export class SubscriberTreeComponent implements OnChanges, OnDestroy {

  @ViewChild('eventForm') eventForm!: CrifFormFieldsComponent;
  @ViewChild('filterForm') filterForm!: CrifFormFieldsComponent;
  @ViewChild('conditionForm') conditionForm!: CrifFormFieldsComponent;
  @Input() events!: SubscriberEventItemDTO[]
  @Input() readonly: boolean = false;
  @Output() eventsChange: EventEmitter<SubscriberEventItemDTO[]> = new EventEmitter<SubscriberEventItemDTO[]>()

  treeEvents: TreeNode<TreeEventDataType>[] = []
  treeCurrentNode: TreeNode<any> | null = null;
  treeCurrentDepth: number = -1;
  eventFormFields: FormFieldsDefinition = {};
  filterFormFields: FormFieldsDefinition = {};
  conditionFormFields: FormFieldsDefinition = {};
  selectedEventName: string = "";
  selectedFilterName: string = "";
  selectedConditionName: string = "";
  columns: FormFieldOption[] = [];

  private readonly domainCodeColumnValues = ['cardacceptorcountry', 'currency', 'merchantcategorycode', 'purposecode', 'accountpurpose', 'transactionculture']

  private subscriptions: Subscription[] = []

  constructor(
    private modalService: ModalService,
    private language: LanguagesService,
    private uiqueIdService: UniqueIdService,
    private globalSettingsService: GlobalSettingsService
  ) {
    this.eventFormFields = {
      enabled: {labeli18n: "subscribers.events.event.enabled", type: 'switch', cols: "col-3"},
      scheduler: {labeli18n: "subscribers.events.event.scheduler", type: 'cron', cols: "col-4"},
      culture: {
        labeli18n: "subscribers.events.event.culture", type: 'select', cols: "col-5",
        options: globalSettingsService.cultures
      },
      emptyTaggedDescription: {
        labeli18n: "subscribers.events.event.emptyTaggedDescription",
        type: 'switch',
        cols: "col-12"
      },
    }
    this.filterFormFields = {
      enabled: {
        labeli18n: "subscribers.events.filter.enabled",
        type: 'switch',
        cols: "col-3"
      },
      type: {
        labeli18n: "subscribers.events.filter.type", type: 'select', cols: "col-4", options: [
          {text: "Filter", value: "FILTER"},
          {text: "Starter", value: "STARTER"}
        ]
      },
      value: {
        labeli18n: "subscribers.events.filter.value",
        type: 'text',
        cols: "col-5",
        hintText: language.translate("subscribers.events.filter.valueHint")
      }
    }
    this.conditionFormFields = {
      logic: {
        labeli18n: "subscribers.events.condition.logic", type: 'select', cols: "col-3", options: [
          {text: language.translate("subscribers.events.condition.logicAnd"), value: "and"},
          {text: language.translate("subscribers.events.condition.logicOr"), value: "or"}
        ]
      },
      column: {
        labeli18n: "subscribers.events.condition.column", type: 'select', cols: "col-5",
        options: [] // to be initialized asynchronously
      },
      condition: {
        labeli18n: "subscribers.events.condition.operator", type: 'select', cols: "col-4", options: [
          {text: "!=", value: "!="},
          {text: "<", value: "<"},
          {text: "<=", value: "<="},
          {text: "=", value: "="},
          {text: ">", value: ">"},
          {text: ">=", value: ">="},
          {text: "in", value: "in"},
          {text: "not in", value: "not in"}
        ]
      },
      value: {labeli18n: "subscribers.events.condition.value", type: 'textarea', cols: "col-12"},
    }

    this.subscriptions.push(
      globalSettingsService.initialized.subscribe(o => {
        if (o) {
          let cultures = globalSettingsService.cultures ? [...globalSettingsService.cultures] : [];
          cultures.unshift({value: "", text: ""});
          this.eventFormFields['culture'].options = cultures;
        }
      })
    )

    this.subscriptions.push(
      globalSettingsService.initialized.subscribe(o => {
        if (o) {
          let columns = globalSettingsService.columns ? [...globalSettingsService.columns.map(column => {
            const fixedColumn = cloneDeep(column);
            fixedColumn.value = fixedColumn.value.toLowerCase();
            return fixedColumn;
          })] : [];
          this.columns = columns;
          columns.unshift({value: "", text: "", type: ""});
          this.conditionFormFields['column'].options = columns;
        }
      })
    )
  }

  getNodePath(treeNodes: TreeNode<TreeEventDataType>[], level: number, path: TreeNodePath[]): TreeNodePath[] {
    const pathLength: number = path.length;
    let childIndex: number = 0;
    for (let nodeIndex in treeNodes) {
      const actualNode: TreeNode<TreeEventDataType> = treeNodes[nodeIndex];

      if (actualNode.id == this.treeCurrentNode?.id) {
        path.push({node: actualNode, level, childIndex});
        return path;
      }
      if (actualNode.children && actualNode.children.length > 0) {
        const returnedPath: TreeNodePath[] = this.getNodePath(actualNode.children, level + 1, path);
        if (returnedPath.length > pathLength) {
          returnedPath.push({node: actualNode, level, childIndex});
          return returnedPath;
        }
      }
      childIndex++;
    }
    return [];
  }

  setSelectedNodeNames(node: TreeNode<any>, depth: number) {
    const nodePath = this.getNodePath(this.treeEvents, 0, []);
    this.selectedEventName = "";
    this.selectedFilterName = "";
    this.selectedConditionName = "";
    for (let nodeIndex in nodePath) {
      const pathNode = nodePath[nodeIndex];
      switch (pathNode.level) {
        case 0:
          this.selectedEventName = this.computeName(pathNode.node, pathNode.childIndex)
          break;
        case 1:
          const treeEvent = this.treeEvents.find(e => e.id === nodePath[nodePath.length - 1].node.id);
          this.selectedFilterName = this.computeName(pathNode.node, pathNode.childIndex, pathNode.level, treeEvent?.children);
          break;
        case 3:
          this.selectedConditionName = `${this.language.translate("subscribers.events.condition.condition")} ${pathNode.childIndex + 1}`;
          break;
      }
    }
  }

  treeAddClick({nodes, depth}: { nodes: TreeNode<any>[], depth: number }) {
    let node
    switch (depth) {
      case 0:
        node = {
          id: `event-${this.uiqueIdService.id}`,
          name: (d) => `${this.language.translate("subscribers.events.event.event")} ${d.$idx + 1}`,
          expanded: true,
          data: {scheduler: '', culture: '', enabled: true, emptyTaggedDescription: false, eventId: '', name: ''},
          children: [
            {
              id: `filter-${this.uiqueIdService.id}`,
              name: (d) => `${this.language.translate('subscribers.events.filter.' + d.type.toLowerCase())} ${d.$idx + 1}`,
              data: {type: 'STARTER', value: '', enabled: true}
            } as TreeNode<SubscriberFilterItemDTO>
          ] as TreeNode<SubscriberFilterItemDTO>[]
        } as TreeNode<SubscriberEventItemDTO>;
        break;
      case 1:
        node = {
          id: `filter-${this.uiqueIdService.id}`,
          name: (d) => `${this.language.translate('subscribers.events.filter.' + d.type.toLowerCase())} ${d.$idx + 1}`,
          expanded: true,
          data: {type: 'FILTER', value: '', enabled: true, groups: []},
          children: []
        } as TreeNode<SubscriberFilterItemDTO>;
        break;
      case 2:
        node = {
          id: `group-${this.uiqueIdService.id}`,
          name: (d) => `${this.language.translate("subscribers.events.group.group")} ${d.$idx + 1}`,
          selected: false,
          children: []
        } as TreeNode<void>;
        break;
      case 3:
        node = {
          id: `condition-${this.uiqueIdService.id}`,
          name: (d) => d.column || `${this.language.translate("subscribers.events.condition.condition")} ${d.$idx + 1}`,
          expanded: false,
          data: {logic: 'and', column: '', condition: '', value: ''}
        } as TreeNode<SubscriberGroupsItemDTO & { logic: string }>;
        break;
    }
    if (node) {
      nodes.push(node)
      this.treeItemClick({node, depth})

      const starter = node?.children?.at(0) as TreeNode<SubscriberFilterItemDTO>;
      const isNewEvent = starter && depth == 0
      if (isNewEvent)
        this.treeItemClick({node: starter, depth: 1})
      else
        this.setSelectedNodeNames(node, depth);
    }
  }

  treeInsertClick({node, depth}: { node: TreeNode<any>, depth: number }) {
    if (!node.children) {
      node.children = []
    }
    let newNode
    switch (depth) {
      case 0:
        newNode = {
          id: `filter-${this.uiqueIdService.id}`,
          name: (d) => `${this.language.translate('subscribers.events.filter.' + d.type.toLowerCase())} ${d.$idx + 1}`,
          expanded: false,
          data: {type: 'FILTER', value: '', enabled: true}
        } as TreeNode<SubscriberFilterItemDTO>;
        this.selectedEventName = "";
        this.selectedFilterName = "";
        this.selectedConditionName = "";
        break;
      case 1:
        newNode = {
          id: `group-${this.uiqueIdService.id}`,
          name: (d) => `${this.language.translate("subscribers.events.group.group")} ${d.$idx + 1}`,
          selected: false
        } as TreeNode<void>;
        this.selectedFilterName = "";
        this.selectedConditionName = "";
        break;
      case 2:
        newNode = {
          id: `condition-${this.uiqueIdService.id}`,
          name: (d) => d.column || `${this.language.translate("subscribers.events.condition.condition")} ${d.$idx + 1}`,
          expanded: false,
          data: {logic: 'and', column: '', condition: '', value: ''}
        } as TreeNode<SubscriberGroupsItemDTO & { logic: string }>;
        this.selectedConditionName = "";
        break;
    }
    if (newNode) {
      node.children.push(newNode)
      this.treeItemClick({node: newNode, depth: depth + 1})
      node.expanded = true
    }
  }

  treeRemoveClick({nodes, node, depth}: { nodes: any[], node: any, depth: number }) {
    if (depth === 0) {
      const s = this.modalService.openDialog<SubscriberEventItemDTO, boolean>('confirm-modal-component', {
        type: 'warning',
        title: this.language.translate('dialogs.subscribers.events.titleDelete'),
        content: node.data,
        action: 'delete-confirm',
        entity: 'subscribers-event',
        ok: this.language.translate('common.ok'),
        cancel: this.language.translate('common.cancel')
      }).pipe(
        filter(o => o.reason === 'ok'),
        tap(() => nodes.splice(nodes.indexOf(node), 1)),
        tap(() => this._updateEvents())
      ).subscribe();

      this.subscriptions.push(s)
    }

    if (depth === 1) {
      const s = this.modalService.openDialog<SubscriberFilterItemDTO, boolean>('confirm-modal-component', {
        type: 'warning',
        title: this.language.translate('dialogs.subscribers.events.titleDelete'),
        content: node.data,
        action: 'delete-confirm',
        entity: 'subscribers-filter',
        ok: this.language.translate('common.ok'),
        cancel: this.language.translate('common.cancel')
      }).pipe(
        filter(o => o.reason === 'ok'),
        tap(() => nodes.splice(nodes.indexOf(node), 1)),
        tap(() => this._updateEvents())
      ).subscribe();

      this.subscriptions.push(s)
    }

    if (depth === 2) {
      const s = this.modalService.openDialog<string, boolean>('confirm-modal-component', {
        type: 'warning',
        title: this.language.translate('dialogs.subscribers.events.titleDelete'),
        content: node.data,
        action: 'delete-confirm',
        entity: 'subscribers-group',
        ok: this.language.translate('common.ok'),
        cancel: this.language.translate('common.cancel')
      }).pipe(
        filter(o => o.reason === 'ok'),
        tap(() => nodes.splice(nodes.indexOf(node), 1)),
        tap(() => this._updateEvents())
      ).subscribe();

      this.subscriptions.push(s)
    }

    if (depth === 3) {
      const s = this.modalService.openDialog<SubscriberGroupsItemDTO, boolean>('confirm-modal-component', {
        type: 'warning',
        title: this.language.translate('dialogs.subscribers.events.titleDelete'),
        content: node.data,
        action: 'delete-confirm',
        entity: 'subscribers-condition',
        ok: this.language.translate('common.ok'),
        cancel: this.language.translate('common.cancel')
      }).pipe(
        filter(o => o.reason === 'ok'),
        tap(() => nodes.splice(nodes.indexOf(node), 1)),
        tap(() => this._updateEvents())
      ).subscribe();

      this.subscriptions.push(s)
    }

  }

  treeItemClick({node, depth}: { node: TreeNode<any>, depth: number }) {
    this.treeCurrentDepth = depth
    this.treeCurrentNode = node
    const nodePath = this.getNodePath(this.treeEvents, 0, []);
    for (let nodeIndex in nodePath) {
      const pathNode = nodePath[nodeIndex];
      switch (pathNode.level) {
        case 0:
          this._setEventForm(pathNode.node);
          break;
        case 1:
          this._setFilterForm(pathNode.node);
          break;
        case 3:
          this._setConditionForm(pathNode.node);
          break;
      }
    }
    this.setSelectedNodeNames(this.treeCurrentNode, depth);
  }

  treeCloneClick({nodes, node, depth}: { nodes: any[], node: any, depth: number }) {
    const clonedNode = cloneDeep(node);
    this.updateId(clonedNode, depth);
    nodes.splice(nodes.indexOf(node), 0, clonedNode);
    this._updateEvents();
  }

  private updateId(node: any, depth: number) {
    switch (depth) {
      case 0:
        node.id = `event-${this.uiqueIdService.id}`;
        node.children.forEach((child: any) => this.updateId(child, depth + 1));
        break;
      case 1:
        node.id = `filter-${this.uiqueIdService.id}`;
        node.children.forEach((child: any) => this.updateId(child, depth + 1));
        break;
      case 2:
        node.id = `group-${this.uiqueIdService.id}`;
        node.children.forEach((child: any) => this.updateId(child, depth + 1));
        break;
      case 3:
        node.id = `condition-${this.uiqueIdService.id}`;
        break;
    }
  }

  treeDragAndDrop({previousParentNodeId, previousIndex, currentParentNodeId, currentIndex}:
                    {
                      previousParentNodeId: string,
                      previousIndex: number,
                      currentParentNodeId: string,
                      currentIndex: number
                    }
  ) {
    const previousParentNode = this.findNodeById(previousParentNodeId);
    if (previousParentNode?.children) {
      if (previousParentNodeId === currentParentNodeId) {
        moveItemInArray(previousParentNode.children, previousIndex, currentIndex);
      } else {
        const currentParentNode = this.findNodeById(currentParentNodeId);
        if (currentParentNode?.children) {
          transferArrayItem(
            previousParentNode.children,
            currentParentNode?.children,
            previousIndex,
            currentIndex,
          );
        }
      }
      this._updateEvents();
    }
  }

  private findNodeById(nodeId: string): TreeNode<TreeEventDataType> | undefined {
    const depth = this.getDepthByNodeId(nodeId);
    let nodes = this.treeEvents;
    for (let i = 1; i <= depth; i++) {
      nodes = nodes.flatMap(node => node.children || []);
    }
    return nodes.find(n => n.id === nodeId);
  }

  private getDepthByNodeId(nodeId: string): number {
    let depth = 0;
    if (nodeId.startsWith('filter-')) {
      depth = 1;
    } else if (nodeId.startsWith('group-')) {
      depth = 2;
    } else if (nodeId.startsWith('condition-')) {
      depth = 3;
    }
    return depth;
  }

  eventFormChanged(data: SubscriberEventItemDTO) {
    if (data) {
      this.getNodePath(this.treeEvents, 0, [])
        .filter(node => node.level === 0)
        .forEach(node => {
          Object.assign(node.node.data, data);
          this._updateEvents();
        });
    }
  }

  filterFormChanged(data: SubscriberFilterItemDTO) {
    if (data) {

      if (data.type === SubscriberFilterItemDTO.TypeEnum.Filter && !this.filterFormFields['value'].hidden) {
        this.filterFormFields['value'].hidden = true;
        this.filterForm.form.form.controls['value']?.setValue('');
      }

      if (data.type === SubscriberFilterItemDTO.TypeEnum.Starter && this.filterFormFields['value'].hidden) {
        this.filterFormFields['value'].hidden = false;
        this.treeCurrentDepth = 2;
      }

      if (data.type === SubscriberFilterItemDTO.TypeEnum.Filter && this.filterFormFields['enabled'].disabled) {
        this.filterFormFields['enabled'].disabled = false;
      }

      if (data.type === SubscriberFilterItemDTO.TypeEnum.Starter && !this.filterFormFields['enabled'].disabled) {
        this.filterFormFields['enabled'].disabled = true;
        this.filterForm.form.form.controls['enabled']?.setValue(true);
      }

      this.getNodePath(this.treeEvents, 0, [])
        .filter(node => node.level === 1)
        .forEach(node => {
          Object.assign(node.node.data, data);
          this.setSelectedNodeNames(node.node, 1);
          this._updateEvents();
        });
    }
  }

  conditionFormChanged(data: SubscriberGroupsItemDTO & { logic: string }) {
    if (data) {
      this.getNodePath(this.treeEvents, 0, [])
        .filter(node => node.level === 3)
        .forEach(node => {
          Object.assign(node.node.data, data);
          this._updateEvents();
        });

      const column = this.columns.find(column => column.value === data.column);
      this.conditionFormFields['value'].hintText = column?.type ?
        this.language.translate('subscribers.events.condition.valueHint.' + column.type) : '';
      this.conditionFormFields['value'].tooltip = this.domainCodeColumnValues.includes(column?.value || '') ?
        this.language.translate('subscribers.events.condition.domainCodeTooltip') : '';
    }
  }

  private _setEventForm(node: TreeNode<TreeEventDataType>): TreeNode<SubscriberEventItemDTO> {
    const _node: TreeNode<SubscriberEventItemDTO> = node as any;
    this.eventForm.form.form.setValue({
      scheduler: _node.data?.scheduler || '',
      culture: _node.data?.culture || '',
      enabled: _node.data?.enabled || false,
      emptyTaggedDescription: _node.data?.emptyTaggedDescription || false
    })
    return _node
  }

  private _setFilterForm(node: TreeNode<TreeEventDataType>): TreeNode<SubscriberFilterItemDTO> {
    const _node: TreeNode<SubscriberFilterItemDTO> = node as any;
    this.filterForm.form.form.setValue({
      type: _node.data?.type || '',
      value: _node.data?.value || '',
      enabled: _node.data?.type === SubscriberFilterItemDTO.TypeEnum.Starter ? true : (_node.data?.enabled || false)
    })
    return _node
  }

  private _setConditionForm(node: TreeNode<TreeEventDataType> | undefined): TreeNode<SubscriberGroupsItemDTO & {
    logic: string
  }> | undefined {
    if (!node) {
      this.conditionForm.form.form.setValue({
        logic: '',
        column: '',
        condition: '',
        value: ''
      })
      return undefined;
    }
    const _node: TreeNode<SubscriberGroupsItemDTO & { logic: string }> = node as any;
    this.conditionForm.form.form.setValue({
      logic: _node.data?.logic || '',
      column: _node.data?.column || '',
      condition: _node.data?.condition || '',
      value: _node.data?.value || ''
    })
    return _node
  }

  private _eventTree2Dto(node: TreeNode<any>[]): SubscriberEventItemDTO[] {
    return node.map(e => {
      const _event = e as TreeNode<SubscriberEventItemDTO>
      return {
        scheduler: _event.data?.scheduler || '',
        culture: _event.data?.culture || '',
        enabled: _event.data?.enabled || false,
        emptyTaggedDescription: _event.data?.emptyTaggedDescription || false,
        eventId: _event.data?.eventId || '',
        name: _event.data?.name || '',
        filters: _event?.children?.map((f: any) => {
          const _filter = f as TreeNode<SubscriberFilterItemDTO>
          return {
            type: _filter.data?.type || '',
            value: _filter.data?.value || '',
            enabled: _filter.data?.enabled || false,
            groups: _filter?.children?.map((g: any) => {
              const _group = g as TreeNode<SubscriberGroupsDTO>
              return {
                allOf: _group?.children?.filter((c: any) => c.data?.logic === 'and')
                  .map((c: any) => {
                    const _condition = c as TreeNode<SubscriberGroupsItemDTO & { logic: string }>
                    return {
                      column: _condition.data?.column || '',
                      condition: _condition.data?.condition || '',
                      value: _condition.data?.value || ''
                    } as SubscriberGroupsItemDTO
                  }),
                anyOf: _group?.children?.filter((c: any) => c.data?.logic === 'or')
                  .map((c: any) => {
                    const _condition = c as TreeNode<SubscriberGroupsItemDTO & { logic: string }>
                    return {
                      column: _condition.data?.column || '',
                      condition: _condition.data?.condition || '',
                      value: _condition.data?.value || ''
                    } as SubscriberGroupsItemDTO
                  })
              } as SubscriberGroupsDTO
            })
          } as SubscriberFilterItemDTO
        })
      } as SubscriberEventItemDTO
    })
  }

  private _dto2FilterTree(data: SubscriberEventItemDTO[]): TreeNode<any>[] {
    return data.map((event: SubscriberEventItemDTO) => {
      return {
        id: `event-${this.uiqueIdService.id}`,
        name: (d) => `${this.language.translate("subscribers.events.event.event")} ${d.$idx + 1}`,
        expanded: true,
        data: {
          scheduler: event.scheduler,
          culture: event.culture,
          enabled: event.enabled,
          emptyTaggedDescription: event.emptyTaggedDescription,
          eventId: event.eventId,
          name: event.name
        },
        children: event.filters?.map((filter: SubscriberFilterItemDTO) => {
          return {
            id: `filter-${this.uiqueIdService.id}`,
            name: (d) => `${this.language.translate('subscribers.events.filter.' + d.type.toLowerCase())} ${d.$idx + 1}`,
            expanded: true,
            data: {type: filter.type, value: filter.value, enabled: filter.enabled},
            children: filter.groups?.map((group: SubscriberGroupsDTO) => {
              return {
                id: `group-${this.uiqueIdService.id}`,
                name: (d) => `${this.language.translate("subscribers.events.group.group")} ${d.$idx + 1}`,
                expanded: true,
                selected: false,
                children: [
                  ...group.allOf?.map((condition: SubscriberGroupsItemDTO) => {
                    return {
                      id: `condition-${this.uiqueIdService.id}`,
                      name: (d) => d.column || `${this.language.translate("subscribers.events.condition.condition")} ${d.$idx + 1}`,
                      expanded: true,
                      data: {
                        logic: 'and',
                        column: condition.column,
                        condition: condition.condition,
                        value: condition.value
                      }
                    } as TreeNode<SubscriberGroupsItemDTO & { logic: string }>
                  }) || [],
                  ...group.anyOf?.map((condition: SubscriberGroupsItemDTO) => {
                    return {
                      id: `condition-${this.uiqueIdService.id}`,
                      name: (d) => d.column || `${this.language.translate("subscribers.events.condition.condition")} ${d.$idx + 1}`,
                      expanded: true,
                      data: {
                        logic: 'or',
                        column: condition.column,
                        condition: condition.condition,
                        value: condition.value
                      }
                    } as TreeNode<SubscriberGroupsItemDTO & { logic: string }>
                  }) || []
                ]
              }
            })
          }
        })
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this._updated) {
      this.treeEvents = this._dto2FilterTree(this.events)
      this._updated = false
    }
  }

  private _updated: boolean = false

  private _updateEvents() {
    this.events = this._eventTree2Dto(this.treeEvents)
    this._updated = true
    this.eventsChange.emit(this.events)

  }

  computeName(node: TreeNode<any> | null, idx: number, depth?: number, nodes?: TreeNode<any>[]): string {
    if (!node) return "";
    if (typeof node.name === 'string') {
      return node.name;
    }
    if (typeof node.name === 'function') {
      return node.name(Object.assign(node?.data || {}, {$idx: this.getIdx(node, idx, depth, nodes)}));
    }
    return node.id;
  }

  private getIdx(node: TreeNode<any>, idx: number, depth?: number, nodes?: TreeNode<any>[]): number {
    if (depth === 1 && nodes) {
      const previousDifferentTypeNodesCount = nodes.slice(0, idx).filter(n => n.data?.type !== node.data.type).length;
      return idx - previousDifferentTypeNodesCount;
    }
    return idx;
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(sub=>{
      if (sub) {
        sub.unsubscribe();
      }
    })
  }
}
