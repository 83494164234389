<crf-modal-simple-template [title]="data.title" [okLabel]="data.ok" (close)="onClose($event)" [color]="data.type">
  <ng-container *ngIf="isHttpError; else def">
    <div *ngIf="data.content?.['error']?.fault; else status">
      <h6>
        {{ data.content?.['error']?.fault?.message + " ("+ data.content?.['error']?.fault?.code + ")"}}
      </h6>
      <span>
        {{ data.content?.['error']?.fault?.description }}
      </span>
    </div>
    <ng-template #status>
      <h6>
        {{ httpErrorResponseStatus }}
      </h6>
    </ng-template>
  </ng-container>
  <ng-template #def>
    <div *ngIf="isStringContent">
      {{ data.content }}
    </div>
    <span *ngIf="!isStringContent">
      {{ data.content | json }}
    </span>
  </ng-template>
</crf-modal-simple-template>