<template-base [title]="'subscribers.title' | translate"
	[subTitle]="(editMode ? 'subscribers.edit': 'subscribers.create') | translate">
	<div all>
		<crf-form-fields #subscriberForm [fields]="formFields" [noActions]="true" [noCard]="true"
			(crfSubmit)="createButtonClick($event)" />
		<h3>{{ 'subscribers.events.title' | translate }}</h3>
		<crf-subscriber-tree [(events)]="events"/>
		<hr>
		<div class="row">
			<div class="col-3">
				<button [disabled]="loading" type="button" class="btn btn-light mb-2"
					(click)="undoButtonClick()">
          <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="loading"></i>
					{{ 'common.cancel' | translate }}
				</button>
        &nbsp;&nbsp;&nbsp;
				<button [disabled]="loading" type="button" class="btn btn-primary mb-2"
					(click)="submitForm()">
          <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="loading"></i>
					{{ (editMode ? 'common.save' : 'common.create') | translate }}
				</button></div>
      <div class="col-9"></div>
		</div>
	</div>
</template-base>
