import {Component} from '@angular/core';
import {
  GlobalSettingValueDescriptionComponent
} from "@/pages/global-settings/global-setting-value-description/global-setting-value-description.component";

@Component({
  selector: 'app-global-setting-currency-domain',
  templateUrl: '../global-setting-value-description/global-setting-value-description.component.html',
  styleUrls: ['../global-setting-value-description/global-setting-value-description.component.scss']
})
export class GlobalSettingCurrencyDomainComponent extends GlobalSettingValueDescriptionComponent {

  override ngOnInit() {
    this.setGlobalSettingId("currencyDomain");
    this.setActionsMode(false, false);
    super.ngOnInit();
  }

}
