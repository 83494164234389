/**
 * CRIF Data Flow Monitoring
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WarningDTO } from './warningDTO';


export interface DeliveryUpdateDTO { 
    status: DeliveryUpdateDTO.StatusEnum;
    triggerMode?: string;
    subscriberId: string;
    culture: string;
    query: string;
    filenames: Array<string>;
    warnings?: Array<WarningDTO>;
    elaborationIds: Array<string>;
    completedAt?: string;
    failedId?: string;
    failedMessage: string;
    numberOfSubjects?: number;
    numberOfAccounts?: number;
    numberOfTransactions?: number;
    numberOfRecords?: number;
}
export namespace DeliveryUpdateDTO {
    export type StatusEnum = 'COMPLETE' | 'RUNNING' | 'INVALID' | 'FAILED';
    export const StatusEnum = {
        Complete: 'COMPLETE' as StatusEnum,
        Running: 'RUNNING' as StatusEnum,
        Invalid: 'INVALID' as StatusEnum,
        Failed: 'FAILED' as StatusEnum
    };
}


