import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TreeNode} from './tree-node.model';
import {LanguagesService} from "@shared/services/languages/languages.service";
import {FormFieldOption} from '../crf-form-fields/form-fields';
import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {cloneDeep} from 'lodash-es';
import {CdkDrag, CdkDragDrop, CdkDropList} from '@angular/cdk/drag-drop';
import {SubscriberFilterItemDTO} from '@/apis/configuration';

@Component({
  selector: 'crf-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class CrifTreeViewComponent {

  @Input() nodes: TreeNode<any>[] = [];
  @Input() depth: number = 0;
  @Input() maxDepth?: number
  @Input() readonly: boolean = false;
  @Input() parentNode?: TreeNode<any>;
  @Input() eventNodes: TreeNode<any>[] = [];
  @Output() depthChange = new EventEmitter<number>();
  @Output() selectedItem: EventEmitter<{ node: TreeNode<any>, depth: number }> = new EventEmitter<{ node: TreeNode<any>, depth: number }>();
  @Output() nodeAdd: EventEmitter<{ nodes: TreeNode<any>[], depth: number }> = new EventEmitter<{ nodes: TreeNode<any>[], depth: number }>();
  @Output() nodeInsert: EventEmitter<{ node: TreeNode<any>, depth: number }> = new EventEmitter<{ node: TreeNode<any>, depth: number }>();
  @Output() nodeRemove: EventEmitter<{ nodes: TreeNode<any>[], node: TreeNode<any>, depth: number }> = new EventEmitter<{ nodes: TreeNode<any>[], node: TreeNode<any>, depth: number }>();
  @Output() nodeClone: EventEmitter<{ nodes: TreeNode<any>[], node: TreeNode<any>, depth: number }> = new EventEmitter<{ nodes: TreeNode<any>[], node: TreeNode<any>, depth: number }>();
  @Output() nodeDragAndDrop: EventEmitter<{ previousParentNodeId: string, previousIndex: number, currentParentNodeId: string, currentIndex: number }> = new EventEmitter<{ previousParentNodeId: string, previousIndex: number, currentParentNodeId: string, currentIndex: number }>();

  expandedNodes: Set<TreeNode<any>> = new Set<TreeNode<any>>();

  private columns: FormFieldOption[];

  constructor(
    private language: LanguagesService,
    private globalSettingsService: GlobalSettingsService
  ) {
    this.columns = globalSettingsService.columns ? [...globalSettingsService.columns.map(column => {
      const fixedColumn = cloneDeep(column);
      fixedColumn.value = fixedColumn.value.toLowerCase();
      return fixedColumn;
    })] : [];
  }

  toggleNode(node: TreeNode<any>): void {
    if (this.isExpanded(node)) {
      this.expandedNodes.delete(node);
    } else {
      this.expandedNodes.add(node);
    }
  }

  isExpanded(node: TreeNode<any>): boolean {
    return this.expandedNodes.has(node);
  }

  onItemClicked(node: TreeNode<any>, depth: number): void {
    this.selectedItem.emit({ node, depth });
    this.depthChange.emit(depth);
  }

  computeName(node: TreeNode<any>, idx: number, depth: number, nodes: TreeNode<any>[]): string {
    if (typeof node.name === 'string') {
      return node.name;
    }
    if (typeof node.name === 'function') {
      let name: string = node.name(Object.assign(node?.data || {}, { $idx: this.getIdx(node, idx, depth, nodes) }));
      switch (depth) {
        case 0:
          name += ` (${node.data.scheduler} - ${node.data.culture})`;
          name += `${node.data.enabled ? '' : ' <i>' + this.language.translate("subscribers.events.event.disabled") + '</i>'}`;
          break;
        case 1:
          name += `${node.data.type == 'STARTER' && node.data.value.length ? ' (' + node.data.value + ')' : ''}`;
          name += `${node.data.enabled ? '' : ' <i>' + this.language.translate("subscribers.events.event.disabled") + '</i>'}`;
          break;
        case 3:
          name = !node.data?.condition && !node.data?.value ?
            `<b>${node.data.logic}</b> ${name}` :
            `<b>${node.data.logic}</b> ${this.getColumnName(node.data.column)} ${node.data.condition} ${(node.data.condition != 'in' && node.data.condition != 'not in') ? node.data.value : `(${this.language.translate('subscribers.events.condition.list')})`}`;
          break;
      }
      return name;
    }
    return node.id;
  }

  private getIdx(node: TreeNode<any>, idx: number, depth: number, nodes: TreeNode<any>[]): number {
    if (depth === 1) {
      const previousDifferentTypeNodesCount = nodes.slice(0, idx).filter(n => n.data.type !== node.data.type).length;
      return idx - previousDifferentTypeNodesCount;
    }
    return idx;
  }

  private getColumnName(columnKey: string) {
    return this.columns.find(c => c.value == columnKey)?.text;
  }

  computeAddButtonName(depth: number): string {
    let addButtonName: string = this.language.translate("common.add");
    switch (depth) {
      case 0:
        addButtonName += " " + this.language.translate("subscribers.events.event.event");
        break;
      case 1:
        addButtonName += " " + this.language.translate("subscribers.events.filter.filter");
        break;
      case 2:
        addButtonName += " " + this.language.translate("subscribers.events.group.group");
        break;
      case 3:
        addButtonName += " " + this.language.translate("subscribers.events.condition.condition");
        break;
    }
    return addButtonName;
  }

  get hasChildren(): boolean {
    return this.nodes.some(n => n.children && n.children.length)
  }

  drop(event: CdkDragDrop<any>) {
    this.nodeDragAndDrop.emit({
      previousParentNodeId: event.previousContainer.id.substring(10),
      previousIndex: event.previousIndex,
      currentParentNodeId: event.container.id.substring(10),
      currentIndex: event.currentIndex
    });
  }

  get dropListId(): string {
    return this.parentNode ? `drop-list-${this.parentNode.id}` : '';
  }

  get dropListOtherIds(): string[] {
    if (!this.parentNode || this.eventNodes.length === 0) return [];

    let nodes = this.eventNodes;
    for (let i = 1; i < this.depth; i++) {
      nodes = nodes.flatMap(node => node.children || []);
      if (i === 1) {
        nodes = nodes.filter(node => node.data.type !== SubscriberFilterItemDTO.TypeEnum.Starter);
      }
    }
    const otherNodes = nodes.filter(node => node.id != this.parentNode?.id);
    return otherNodes.map(node => `drop-list-${node.id}`);
  }

  /**
   * Predicate function to avoid the sorting of "Add" item.
   * The condition is different when dropping on same drag list
   * or different drag list.
   */
  sortPredicate(index: number, drag: CdkDrag, drop: CdkDropList): boolean {
    return drag.dropContainer === drop ? index < drop.data.length : index <= drop.data.length;
  }

  getPlaceholderMinHeight(node: TreeNode<any>, depth: number): string {
    const minHeightPx = (depth === 2 && node.children) ? 22 * (node.children.length + 2) : 22;
    return `min-height:${minHeightPx}px;`
  }

  trackByNode(index: number, item: TreeNode<any>) {
    return item.id;
  }
}
