<template-base [title]="'globalSetting.title' | translate">
  <div all>
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="send-mail-tab" data-bs-toggle="tab" data-bs-target="#send-mail-tab-pane"
              type="button" role="tab" aria-controls="send-mail-tab-pane" aria-selected="true" (click)="onTabChanged('sendMail')">
              {{ 'globalSetting.SendMail.tab' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="elaboration-configuration-tab" data-bs-toggle="tab" data-bs-target="#elaboration-configuration-tab-pane" type="button"
              role="tab" aria-controls="elaboration-configuration-tab-pane" aria-selected="false" (click)="onTabChanged('elaborationConfiguration')">
              {{ 'globalSetting.elaborationConfiguration.tab' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="country-code-domain-tab" data-bs-toggle="tab" data-bs-target="#country-code-domain-tab-pane" type="button"
              role="tab" aria-controls="country-code-domain-tab-pane" aria-selected="false" (click)="onTabChanged('countryCodeDomain')">
              {{ 'globalSetting.countryCodeDomain.tab' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="currency-domain-tab" data-bs-toggle="tab" data-bs-target="#currency-domain-tab-pane" type="button"
              role="tab" aria-controls="currency-domain-tab-pane" aria-selected="false" (click)="onTabChanged('currencyDomain')">
              {{ 'globalSetting.currencyDomain.tab' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="merchant-category-code-domain-tab" data-bs-toggle="tab" data-bs-target="#merchant-category-code-domain-tab-pane" type="button"
              role="tab" aria-controls="merchant-category-code-domain-tab-pane" aria-selected="false" (click)="onTabChanged('merchantCategoryCodeDomain')">
              {{ 'globalSetting.merchantCategoryCodeDomain.tab' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="purpose-code-domain-tab" data-bs-toggle="tab" data-bs-target="#purpose-code-domain-tab-pane" type="button"
              role="tab" aria-controls="purpose-code-domain-tab-pane" aria-selected="false" (click)="onTabChanged('purposeCodeDomain')">
              {{ 'globalSetting.purposeCodeDomain.tab' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="account-purpose-domain-tab" data-bs-toggle="tab" data-bs-target="#account-purpose-domain-tab-pane" type="button"
              role="tab" aria-controls="account-purpose-domain-tab-pane" aria-selected="false" (click)="onTabChanged('accountPurposeDomain')">
              {{ 'globalSetting.accountPurposeDomain.tab' | translate }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="transaction-culture-domain-tab" data-bs-toggle="tab" data-bs-target="#transaction-culture-domain-tab-pane" type="button"
              role="tab" aria-controls="transaction-culture-domain-tab-pane" aria-selected="false" (click)="onTabChanged('transactionCultureDomain')">
              {{ 'globalSetting.transactionCultureDomain.tab' | translate }}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="send-mail-tab-pane" role="tabpanel" aria-labelledby="send-mail-tab"
            tabindex="0">
            <app-global-setting-send-mail #sendMail />
          </div>
          <div class="tab-pane fade" id="elaboration-configuration-tab-pane" role="tabpanel" aria-labelledby="elaboration-configuration-tab" tabindex="0">
            <app-global-setting-elaboration-configuration #elaborationConfiguration />
          </div>
          <div class="tab-pane fade" id="country-code-domain-tab-pane" role="tabpanel" aria-labelledby="country-code-domain-tab" tabindex="0">
            <app-global-setting-country-code-domain #countryCodeDomain />
          </div>
          <div class="tab-pane fade" id="currency-domain-tab-pane" role="tabpanel" aria-labelledby="currency-domain-tab" tabindex="0">
            <app-global-setting-currency-domain #currencyDomain />
          </div>
          <div class="tab-pane fade" id="merchant-category-code-domain-tab-pane" role="tabpanel" aria-labelledby="merchant-category-code-domain-tab" tabindex="0">
            <app-global-setting-merchant-category-code-domain #merchantCategoryCodeDomain />
          </div>
          <div class="tab-pane fade" id="purpose-code-domain-tab-pane" role="tabpanel" aria-labelledby="purpose-code-domain-tab" tabindex="0">
            <app-global-setting-purpose-code-domain #purposeCodeDomain />
          </div>
          <div class="tab-pane fade" id="account-purpose-domain-tab-pane" role="tabpanel" aria-labelledby="account-purpose-domain-tab" tabindex="0">
            <app-global-setting-account-purpose-domain #accountPurposeDomain />
          </div>
          <div class="tab-pane fade" id="transaction-culture-domain-tab-pane" role="tabpanel" aria-labelledby="transaction-culture-domain-tab" tabindex="0">
            <app-global-setting-transaction-culture-domain #transactionCultureDomain />
          </div>
        </div>
      </div>
    </div>
  </div>
</template-base>
