<div class="drop-list" [style.margin-left.px]="depth === 0 ? 0 : 16"
     cdkDropList [cdkDropListDisabled]="depth < 2" [id]="dropListId" [cdkDropListConnectedTo]="dropListOtherIds"
     [cdkDropListData]="nodes" [cdkDropListSortPredicate]="sortPredicate" (cdkDropListDropped)="drop($event)">
  <ng-container>
    <div class="drop-list-item" *ngFor="let node of nodes; let last= last; let i = index; trackBy: trackByNode" [cdkDragDisabled]="depth < 2"
         cdkDrag [cdkDragData]="node">
      <div class="drop-list-item-placeholder" [style]="getPlaceholderMinHeight(node, depth)" *cdkDragPlaceholder></div>
      <span class="line d-block clearfix"
            [style.margin-left.px]="node.children &&  node.data?.type !== 'STARTER' ? 0 : 16"
            (click)="onItemClicked(node, depth)">
        <span class="float-start">
          <i *ngIf="node.children && node.data?.type !== 'STARTER'" class="text-primary fa-solid fa-caret-down"
             [style]="(node.data?.type=== 'STARTER' ? 'opacity:0; pointer-events:none;' : '') + 'width: 16px; text-align: center;'"
             [class.collapsed]="!node.expanded" data-bs-toggle="collapse" click-stop-propagation
             [attr.data-bs-target]="'#collapse-' + node.id | dasherize" [attr.aria-expanded]="node.expanded"></i>
          <span class="text-break" [innerHtml]="computeName(node, i, depth, nodes)"></span>
        </span>
        <span class="float-end" *ngIf="!readonly">
          <span (click)="nodeClone.emit({nodes, node, depth})" click-stop-propagation>
            <i class="text-primary fa-solid fa-clone me-1"></i>
          </span>
          <span (click)="nodeRemove.emit({nodes, node, depth})" click-stop-propagation>
            <i class="text-primary fa-solid fa-trash me-1"></i>
          </span>
        </span>
      </span>
      <div class="collapse show" [id]="'collapse-' + node.id | dasherize" [class.show]="node.expanded" [style]="node.data?.type=== 'STARTER' ? 'background:#f1f1f1!important;pointer-events:none!important' : ''">
        <crf-tree-view *ngIf="node.children && node.data?.type !== 'STARTER'"
          [nodes]="node.children" [maxDepth]="maxDepth" [readonly]="readonly" [depth]="depth + 1"
          [parentNode]="node" [eventNodes]="eventNodes" (selectedItem)="selectedItem.emit($event)"
          (nodeAdd)="nodeAdd.emit($event)" (nodeInsert)="nodeInsert.emit($event)"
          (nodeRemove)="nodeRemove.emit($event)" (nodeClone)="nodeClone.emit($event)"
          (nodeDragAndDrop)="nodeDragAndDrop.emit($event)"></crf-tree-view>
      </div>
    </div>
    <div *ngIf="!readonly" cdkDrag [cdkDragDisabled]="true">
      <span class="line d-block" (click)="nodeAdd.emit({nodes, depth})" [style.margin-left.px]="16">
        <i class="text-primary fa-solid fa-plus-square" style="width: 16px; text-align: center;"></i>
        {{ computeAddButtonName(depth) }}
      </span>
    </div>
  </ng-container>
</div>
