/**
 * CRIF Data Flow Monitoring
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ElaborationSearchDTO { 
    status?: string;
    elaborationId?: string;
    contributorId?: string;
    contributionId?: string;
    type?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    updatedAtFrom?: string;
    updatedAtTo?: string;
    completedAtFrom?: string;
    completedAtTo?: string;
}

