import {
  KeyValueDto,
  GlobalSettingService,
  ElaborationService,
  DeliveryService,
  GlobalSettingReadDTO,
  GlobalSettingUpdateDTO,
  KeyValueTypeDto
} from '@/apis/monitoring';
import { Injectable } from '@angular/core';
import { FormFieldOption } from '@shared/components/crf-form-fields/form-fields';
import { ErrorUiService } from '@shared/services/error/error-ui.service';
import { BehaviorSubject, Observable, tap, map, forkJoin } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService {

  public cultures: FormFieldOption[] | undefined
  public regions: FormFieldOption[] | undefined
  public columns: FormFieldOption[] | undefined
  public types: FormFieldOption[] | undefined
  public currency: FormFieldOption[] | undefined
  public elaborationStatus: FormFieldOption[] | undefined
  public deliveryStatus: FormFieldOption[] | undefined

  private _initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public get initialized(): Observable<boolean> {
    return this._initialized.asObservable()
  }

  public init() {
    forkJoin([
      this.globalSettingService.globalSettingControllerGlobalSettingCultureEnum(),
      this.globalSettingService.globalSettingControllerGlobalSettingAwsRegionEnum(),
      this.globalSettingService.globalSettingControllerEtlFields(),
      this.globalSettingService.globalSettingControllerGlobalSettingTypeEnum(),
      this.globalSettingService.globalSettingControllerGlobalSettingCurrencyEnum(),
      this.elaboration.elaborationControllerElaborationStatusEnum(),
      this.delivery.deliveryControllerDeliveryStatusEnum()
    ])
      .pipe(
        this.errorUIService.manageUI('error'),
        map(o => ((o as any).error === true) ? null : o),
        map(o => o as any[][]),
        tap(([cultures, regions, columns, types, currency, elaborationStatus, deliveryStatus]) => {
          this.cultures = this.adapt(cultures)
          this.regions = this.adapt(regions)
          this.columns = this.adaptWithType(columns)
          this.types = this.adapt(types)
          this.currency = this.adapt(currency)
          this.elaborationStatus = this.adapt(elaborationStatus)
          this.deliveryStatus = this.adapt(deliveryStatus)
          this._initialized.next(true)
        }))
      .subscribe()
  }


  private adapt(input: KeyValueDto[]): FormFieldOption[] {
    return input.map(o => {
      return {
        value: o.key,
        text: o.value,
      }
    })
  }

  private adaptWithType(input: KeyValueTypeDto[]): FormFieldOption[] {
    return input.map(o => {
      return {
        value: o.key,
        text: o.value,
        type: o.type
      }
    })
  }

  constructor(
    private globalSettingService: GlobalSettingService,
    private elaboration: ElaborationService,
    private delivery: DeliveryService,
    private errorUIService: ErrorUiService) {
  }

  public findOne(globalSettingId: string): Observable<GlobalSettingReadDTO> {
    return this.globalSettingService.globalSettingControllerFindOne(globalSettingId);
  }

  public update(globalSettingId: string, globalSettingUpdateDTO: GlobalSettingUpdateDTO): Observable<GlobalSettingReadDTO> {
    return this.globalSettingService.globalSettingControllerUpdate(globalSettingId, globalSettingUpdateDTO);
  }
}
