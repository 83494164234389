import { JobStepReadDTO, JobStepService } from '@/apis/monitoring';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class JobStepsService {
  constructor(
    private service: JobStepService,
  ) {
  }

  public async getAll(id: string, jobType: 'ETL' | 'EPD' | 'HPD'): Promise<JobStepReadDTO[]> {

    let initial = { ...defaultSteps[jobType] }
    let jobSteps = await this.service.jobStepControllerFindAllSteps(id).toPromise()

    jobSteps?.forEach(step => initial[step.name] = {
      ...step,
      createdAt: step?.createdAt ? new Date(step?.createdAt) : undefined,
      endedAt: step?.endedAt ? new Date(step?.endedAt) : undefined
    });

    return Object.values(initial);
  }
}

const defaultSteps: {
  [key in 'ETL' | 'EPD' | 'HPD']: { [key: string]: JobStepReadDTO }
} = {
  "ETL": {
    "ACCESS_DATA": { name: "ACCESS_DATA", status: "NOT_EXECUTED" },
    "CHECK_DATA_QUALITY": { name: "CHECK_DATA_QUALITY", status: "NOT_EXECUTED" },
    "CHECK_DUPLICATE_UUIDS": { name: "CHECK_DUPLICATE_UUIDS", status: "NOT_EXECUTED" },
    "APPLY_TRANSFORMATIONS": { name: "APPLY_TRANSFORMATIONS", status: "NOT_EXECUTED" },
    "WRITE_ERROR_FILE": { name: "WRITE_ERROR_FILE", status: "NOT_EXECUTED" },
    "GET_METRICS": { name: "GET_METRICS", status: "NOT_EXECUTED" },
    "CHECK_INVALID_ROWS": { name: "CHECK_INVALID_ROWS", status: "NOT_EXECUTED" },
    "SAVE_DATA": { name: "SAVE_DATA", status: "NOT_EXECUTED" },
    "BACKUP": { name: "BACKUP", status: "NOT_EXECUTED" }
  },
  "EPD": {
    "GET_SUBSCRIBER_SECRETS": { name: "GET_SUBSCRIBER_SECRETS", status: "NOT_EXECUTED" },
    "ACCESS_DATA": { name: "ACCESS_DATA", status: "NOT_EXECUTED" },
    "FILTER_DATA": { name: "FILTER_DATA", status: "NOT_EXECUTED" },
    "GET_METRICS": { name: "GET_METRICS", status: "NOT_EXECUTED" },
    "CHECK_NUMBER_OF_ROWS": { name: "CHECK_NUMBER_OF_ROWS", status: "NOT_EXECUTED" },
    "WRITE_DATA_TO_S3": { name: "WRITE_DATA_TO_S3", status: "NOT_EXECUTED" },
    "LIST_GENERATED_FILES": { name: "LIST_GENERATED_FILES", status: "NOT_EXECUTED" },
    "SEND_FILES_AND_BACKUP": { name: "SEND_FILES_AND_BACKUP", status: "NOT_EXECUTED" },
    "SAVE_ACCOUNTING": { name: "SAVE_ACCOUNTING", status: "NOT_EXECUTED" }
  },
  "HPD": {
    "GET_SUBSCRIBER_SECRETS": { name: "GET_SUBSCRIBER_SECRETS", status: "NOT_EXECUTED" },
    "ACCESS_DATA": { name: "ACCESS_DATA", status: "NOT_EXECUTED" },
    "FILTER_DATA": { name: "FILTER_DATA", status: "NOT_EXECUTED" },
    "GET_METRICS": { name: "GET_METRICS", status: "NOT_EXECUTED" },
    "CHECK_NUMBER_OF_ROWS": { name: "CHECK_NUMBER_OF_ROWS", status: "NOT_EXECUTED" },
    "WRITE_DATA_TO_S3": { name: "WRITE_DATA_TO_S3", status: "NOT_EXECUTED" },
    "LIST_GENERATED_FILES": { name: "LIST_GENERATED_FILES", status: "NOT_EXECUTED" },
    "SEND_FILES_AND_BACKUP": { name: "SEND_FILES_AND_BACKUP", status: "NOT_EXECUTED" },
    "SAVE_ACCOUNTING": { name: "SAVE_ACCOUNTING", status: "NOT_EXECUTED" }
  }
}