/**
 * CRIF Data Flow Monitoring
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ElaborationReadDTO } from './elaborationReadDTO';


export interface ElaborationControllerFindAll200Response { 
    data: Array<ElaborationReadDTO>;
    page: number;
    limit: number;
    total: number;
    lastScanned: string;
}

