import { Injectable, Type } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class CrifModalRegistryOptions implements CrifModalRegistryOptions {
    public modals!: Type<any>[]
}

export interface CrifModalRegistryOptions {
    modals: Type<any>[]
}