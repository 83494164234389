<template-base>
	<div all>
		<div class="row">
			<div class="col-12">
        <crf-form-fields #filtersForm [fields]="formFields" [noCard]="true" [collapsable]="true" (crfSubmit)="search($event)"
                         (clear)="clearSearch()" [loading]="loading" />
        <crf-dt-table [creationStrategy]="enableAddButton ? 'modal' : 'none'" (createItem)="onCreateItem()" [items]="sortedItems"
					[showActions]="enableColumnActions ? 'head' : ''" [loading]="loading">
					<th crf-dt-header field="value" sortable="value" (sort)="onSort($event)">
						{{ 'globalSetting.'+globalSettingId+'.field.value.label' | translate }}
					</th>
					<th crf-dt-header field="description" type="string" sortable="description" (sort)="onSort($event)">
						{{ 'globalSetting.'+globalSettingId+'.field.description.label' | translate }}
					</th>
					<tr crf-dt-row *ngFor="let item of sortedItems; trackBy: trackByValueDescription">
						<td crf-dt-cell>{{ item.value }} </td>
						<td crf-dt-cell>{{ item.description }}</td>
						<crf-dt-actions *ngIf="enableColumnActions">
							<crf-dt-action (click)="onItemDelete(item)">
								{{'common.delete' | translate}}
							</crf-dt-action>
						</crf-dt-actions>
					</tr>
					<span crf-dt-no-item>
            {{ ('globalSetting.' + globalSettingId + '.noItems') | translate }}
          </span>
          <span crf-dt-loading>
            {{ 'common.loading' | translate }}
          </span>
				</crf-dt-table>
			</div>
		</div>
	</div>
</template-base>
