import {ContributorReadDTO} from '@/apis/configuration';
import {ElaborationReadDTO, ElaborationSearchDTO} from "@/apis/monitoring";
import {ContributorsService} from '@/services/contributors/contributors.service';
import {ElaborationsService} from "@/services/elaborations/elaborations.service";
import {GlobalSettingsService} from "@/services/global-settings/global-settings.service";
import {ServiceStatus} from '@/services/paginated.service';
import {Component, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {CrifFormFieldsComponent} from '@shared/components/crf-form-fields/crf-form-fields.component';
import {FormFieldOption, FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {SortableDirective, SortEvent} from '@shared/directives/sortable.directive';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {Subscription} from 'rxjs';
import {AbstractMonitoringComponent} from '../abstract-monitoring.component';

@Component({
  selector: 'app-elaborations',
  templateUrl: './elaborations.component.html',
  styleUrls: ['./elaborations.component.scss']
})
export class ElaborationsComponent extends AbstractMonitoringComponent<ElaborationSearchDTO> implements OnDestroy {

  etl: ServiceStatus<ElaborationReadDTO>;
  elaborationsLoading: boolean = true
  contributorsLoading: boolean = true
  contributors: FormFieldOption[] = []
  toShowStepForId: string | undefined;

  private subscriptions: Subscription[] = []
  private searchFilters: ElaborationSearchDTO = {}
  private DB_PAGE_SIZE = 1000;
  private DISPLAY_PAGE_SIZE = 50;
  private actualPage = 0;
  private items: ElaborationReadDTO[] = [];

  @ViewChild('filtersForm') filtersForm!: CrifFormFieldsComponent;
  @ViewChildren(SortableDirective) headers!: QueryList<SortableDirective>;

  constructor(
    private elaborationsService: ElaborationsService,
    private contributorsService: ContributorsService,
    languagesService: LanguagesService,
    private modalService: ModalService,
    private globalSettings: GlobalSettingsService
  ) {
    super(languagesService);
    this.etl = elaborationsService.status
    this.formFields = {
      elaborationId: {labeli18n: "monitoring.etl.search.elaborationId", type: 'text', cols: "col-4"},
      contributorId: {labeli18n: "monitoring.etl.search.contributorId", type: 'text', cols: "col-4"},
      contributionId: {labeli18n: "monitoring.etl.search.contributionId", type: 'text', cols: "col-4"},
      status: {labeli18n: "monitoring.etl.search.status", type: 'select', cols: "col-4"},
      createdAtFrom: {
        labeli18n: "monitoring.etl.search.createdAtFrom",
        type: 'date',
        format: 'yyyy-MM-dd',
        cols: "col-4",
        maxDate: this.maxDate
      },
      createdAtTo: {
        labeli18n: "monitoring.etl.search.createdAtTo",
        type: 'date',
        format: 'yyyy-MM-dd',
        cols: "col-4",
        maxDate: this.maxDate
      }
    }
    this.subscriptions.push(this.elaborationsService.loading$.subscribe(o => this.elaborationsLoading = o))
    this.subscriptions.push(this.contributorsService.loading$.subscribe(o => this.contributorsLoading = o))
    globalSettings.initialized.subscribe(o => {
      if (o) {
        let statuses = globalSettings.deliveryStatus ? [...globalSettings.deliveryStatus] : [];
        statuses.unshift({value: "", text: ""})
        this.formFields['status'].options = statuses
      }
    })
  }

  protected override getFiltersForm(): CrifFormFieldsComponent {
    return this.filtersForm;
  }

  async search(data: ElaborationSearchDTO) {
    if (!this.areSearchFiltersCorrect(data)) return;
    this.searchFilters = data
    this.elaborationsService.reset(true)
    this.etl = this.elaborationsService.status
    await this.elaborationsService.search(this.DB_PAGE_SIZE, this.searchFilters)
    this.etl = this.elaborationsService.status
    this.items = this.elaborationsService.status.items
    this.etl.items = this.items.slice(0, this.DISPLAY_PAGE_SIZE);
    this.actualPage = 1;
    this.setHasMore();
  }

  areSearchFiltersCorrect(data: ElaborationSearchDTO) {
    let errorLabel: string = "";
    if (data.createdAtFrom && data.createdAtTo) {
      // Check 1
      const fromDate = new Date(data.createdAtFrom);
      const toDate = new Date(data.createdAtTo);
      if (fromDate > toDate) errorLabel = "monitoring.etl.search.errors.fromAfterTo";
      // Check 2
      const diffTime = Math.abs(toDate.getTime() - fromDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 30) errorLabel = "monitoring.etl.search.errors.moreThan30Days";
    } else if (data.createdAtFrom && data.createdAtTo == undefined) {
      // Setting to date 30 days after from date
      let toDate: Date = new Date(data.createdAtFrom);
      toDate.setDate(toDate.getDate() + 29);
      if (toDate > (new Date())) toDate = (new Date());
      data.createdAtTo = toDate.toISOString().substring(0, 10);
      this.filtersForm.form.form.setValue(data);
      this.searchFilters.createdAtTo = toDate.toISOString().substring(0, 10);
    } else if (data.createdAtFrom == undefined && data.createdAtTo) {
      // Setting from date 30 days before to date
      let fromDate: Date = new Date(data.createdAtTo);
      fromDate.setDate(fromDate.getDate() - 29);
      data.createdAtFrom = fromDate.toISOString().substring(0, 10);
      this.filtersForm.form.form.setValue(data);
      this.searchFilters.createdAtFrom = fromDate.toISOString().substring(0, 10);
    } else {
      // Setting a range of 30 days from today
      const toDate: Date = new Date();
      let fromDate: Date = new Date();
      fromDate.setDate(fromDate.getDate() - 29);
      this.searchFilters.createdAtFrom = fromDate.toISOString().substring(0, 10);
      this.searchFilters.createdAtTo = toDate.toISOString().substring(0, 10);
      data.createdAtFrom = fromDate.toISOString().substring(0, 10);
      data.createdAtTo = toDate.toISOString().substring(0, 10);
      this.filtersForm.form.form.setValue(data);
    }
    if (errorLabel && errorLabel.length > 0) {
      this.modalService.openDialog<string, void>('row-details-modal-component', {
        type: 'warning',
        title: this.languagesService.translate('dialogs.warning'),
        content: this.languagesService.translate(errorLabel),
        action: 'details',
        entity: 'text',
        ok: this.languagesService.translate('common.ok'),
      })
      return false;
    }

    return true;
  }

  async loadMore() {
    const startPosition = this.actualPage * this.DISPLAY_PAGE_SIZE;
    this.etl.items = this.etl.items.concat(this.items.slice(startPosition, startPosition + this.DISPLAY_PAGE_SIZE));
    this.actualPage++;
    this.setHasMore();
  }

  async clearSearch() {
    this.searchFilters = {}
  }

  setHasMore() {
    this.etl.hasMore = this.etl.items.length !== this.items.length;
  }

  async refresh(isFirstLoad: boolean = false) {
    if (isFirstLoad) {
      let defaultFrom: Date = new Date();
      defaultFrom.setDate(defaultFrom.getDate() - 5);
      this.filtersForm.form.form.setValue({
        elaborationId: null,
        contributorId: null,
        contributionId: null,
        status: null,
        createdAtFrom: defaultFrom.toISOString().substring(0, 10),
        createdAtTo: (new Date()).toISOString().substring(0, 10)
      })
      this.searchFilters.createdAtFrom = defaultFrom.toISOString().substring(0, 10);
      this.searchFilters.createdAtTo = (new Date()).toISOString().substring(0, 10);
    }
    await this.search(this.searchFilters);
  }

  showDetails(rowItem: ElaborationReadDTO) {
    this.modalService.openDialog<ElaborationReadDTO, void>('row-details-modal-component', {
      type: 'info',
      title: this.languagesService.translate('monitoring.etl.detail'),
      content: rowItem,
      action: 'details',
      entity: 'elaboration',
      ok: this.languagesService.translate('common.ok'),
    })
  }

  getStatusTextBackground(elaboration: ElaborationReadDTO): string {
    let color: string;
    switch (elaboration.status) {
      case "COMPLETE":
        color = "text-bg-success";
        break;
      case "FAILED":
        color = "text-bg-danger";
        break;
      case "RUNNING":
        color = "text-bg-info";
        break;
      case "INVALID":
        color = "text-bg-danger";
        break;
      default:
        color = "text-bg-light";
        break;
    }
    return color;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.refresh(true).then(), 1);

    this.contributorsService.reset(true)
    this.contributorsService.load(999999).then((response: ContributorReadDTO[]) => {
      this.contributors = response
        .map((contributor: ContributorReadDTO) => {
          const c: FormFieldOption = {
            text: contributor.contributorName || contributor.contributorId,
            value: contributor.contributorId
          };
          return c;
        })
        .sort((a, b) => a.value > b.value ? 1 : -1)
      this.formFields['contributorId'].datalist = this.contributors
    });
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s?.unsubscribe()
    }
  }

  onSort(sortEvent: SortEvent) {
    if (sortEvent.column === 'elapsed') {
      this.elaborationsService.sortItems(sortEvent, x => {
        if (!x.createdAt || !x.completedAt) {
          return 0
        }
        const str = new Date(x.createdAt).getTime();
        const end = new Date(x.completedAt).getTime();
        return end - str
      })

      this.etl = this.elaborationsService.status;
      return;
    }

    for (const header of this.headers) {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    }
    this.elaborationsService.sort(sortEvent);
    this.etl = this.elaborationsService.status;
  }

  getElapsedTime(item: ElaborationReadDTO): string {
    if (!item.createdAt || !item.completedAt) {
      return "-"
    }
    const diff = new Date(item.completedAt).getTime() - new Date(item.createdAt).getTime();
    const hours = Math.trunc(diff / (1000 * 3600));
    const minutes = Math.trunc((diff - (hours * 3600 * 1000)) / (1000 * 60));
    const seconds = Math.trunc((diff - (hours * 3600 * 1000) - (minutes * 60 * 1000)) / 1000);
    const millis = diff - (hours * 3600 * 1000) - (minutes * 60 * 1000) - (seconds * 1000);
    return hours.toString() + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2) + '.' + ('000' + millis).slice(-3);
  }

  showProcessSteps(rowItem: ElaborationReadDTO) {
    this.toShowStepForId = rowItem.elaborationId
  }

  showInputParameters(elaboration: ElaborationReadDTO) {
    this.modalService.openDialog<FormFieldsDefinition, ElaborationReadDTO>('form-fields-modal-component', {
      type: 'info',
      title: this.languagesService.translate('monitoring.actions.inputParameters'),
      content: {
        query: {
          type: 'codeblock',
          cols: "col-12 whitespace-wrap",
          rows: 12,
          defaultValue: this.getIdentedFilters(elaboration.appliedFilters)
        }
      },
      action: 'details',
      entity: 'etl',
      ok: this.languagesService.translate('common.ok'),
    }).subscribe();
  }

  closeStepsCard() {
    this.toShowStepForId = undefined
  }

  trackByETL(index: number, item: ElaborationReadDTO) {
    return item.elaborationId;
  }
}
