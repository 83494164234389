import { Component, Input } from '@angular/core';

@Component({
  selector: 'crf-header',
  template: `
    <div class="navbar d-flex align-items-center justify-content-end">
      <img [src]="image" style="height: 50px;">
    </div>`
})
export class CrfHeaderComponent {
  @Input() image!: string
}
