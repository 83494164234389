import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {Component} from '@angular/core';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {
  GlobalSettingDynamicFormComponent
} from "@/pages/global-settings/global-setting-dynamic-form/global-setting-dynamic-form.component";
import {Validators} from "@angular/forms";

@Component({
  selector: 'app-global-setting-elaboration-configuration',
  templateUrl: '../global-setting-dynamic-form/global-setting-dynamic-form.component.html',
  styleUrls: ['../global-setting-dynamic-form/global-setting-dynamic-form.component.scss']
})
export class GlobalSettingElaborationConfigurationComponent extends GlobalSettingDynamicFormComponent {

  constructor(
    modalService: ModalService,
    language: LanguagesService,
    globalSettingsService: GlobalSettingsService,
  ) {
    const GLOBAL_SETTING_ID: string = "elaborationConfiguration";
    const formFields = {
      errorThreshold: {
        labeli18n: `globalSetting.${GLOBAL_SETTING_ID}.field.errorThreshold.label`,
        placeholder: `globalSetting.${GLOBAL_SETTING_ID}.field.errorThreshold.placeholder`,
        type: 'text',
        cols: "col-12",
        //validators: [Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]
        validators: [Validators.pattern(/^(0(\.\d+)?|1(\.0+)?)$/)]
      },
      roundPercent: {
        labeli18n: `globalSetting.${GLOBAL_SETTING_ID}.field.roundPercent.label`,
        type: 'text',
        cols: "col-12"
      },
    };

    super(modalService, language, globalSettingsService);
    this.globalSettingId = GLOBAL_SETTING_ID;
    this.formFields = formFields;
  }

  override ngOnInit(): void {
    this.loadGlobalSetting().then();
  }

  override async updateButtonClick(data: any): Promise<void> {
    data.errorThreshold = data.errorThreshold.replace(",", ".");
    await super.updateButtonClick(data);
  }

}
