import { Component, Input, Optional, Self, OnDestroy } from '@angular/core';
import { CrifAbstractComponent } from '../crf-abstract-field.component';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UniqueIdService } from '../../utils/unique-id.service';
import { ModalService } from '@shared/components/crf-modals/modal.service';
import { LanguagesService } from '@shared/services/languages/languages.service';
import { Subscription, filter, tap, map } from 'rxjs';
import { CronDefinition } from './cron-definition';
import { CronValidatorService } from '@shared/components/utils/cron-validator.service';

@Component({
  selector: 'crf-cron',
  template: `
    <label [for]="id" class="form-label">{{ label }}</label>
    <div class="input-group has-validation">
      <input #input
             type="text"
             class="form-control"
             readonly
             [id]="id"
             [attr.disabled]="disabled?true:undefined"
             [placeholder]="placeholder || ''"
             [value]="value ? parseValue(value) : defaultValue ? parseValue(defaultValue) : ''"
             (blur)="touch();"
             [ngClass]="{'is-invalid': control?.touched && control?.invalid}"
             >
      <button class="btn btn-outline-secondary" type="button" (click)="openModal()" [disabled]="disabled">
        <i class="fa-regular fa-clock"></i>
      </button>
    </div>
    <div class="cds-form-control-error" *ngIf="invalidScheduler||hasNotNextExecution">
      {{(invalidScheduler? "common.cron.invalidScheduler":"common.cron.noNextSchedulerExecution") | translate }}
    </div>`
})

export class CrfCronComponent extends CrifAbstractComponent<string> implements ControlValueAccessor, OnDestroy {
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() label: string = '';
  @Input() beforeText: boolean = false
  @Input() separator: string = ' '
  @Input() defaultValue: string = ''
  @Input() placeholder?: string = ''

  private subscriptions: Subscription[] = []

  constructor(
    idService: UniqueIdService,
    private modalService: ModalService,
    private language: LanguagesService,
    private cronValidatorService: CronValidatorService,
    @Self() @Optional() override control?: NgControl
  ) {
    super(idService, control)
  }

  get invalidScheduler(): boolean {
    return !this.cronValidatorService.isValid(this.value ? this.parseValue(this.value) : this.defaultValue ? this.parseValue(this.defaultValue) : '');
  }

  get hasNotNextExecution(): boolean {
    return !this.cronValidatorService.hasNextExecution(this.value ? this.parseValue(this.value) : this.defaultValue ? this.parseValue(this.defaultValue) : '');
  }

  parseValue(value: string): string {
    return value || ''
  }

  openModal() {
    const s = this.modalService.openDialog<CronDefinition, CronDefinition>('cron-modal-component', {
      title: this.language.translate('common.cron.title'),
      content: this.parseCronString(this.value),
      ok: this.language.translate('common.ok'),
      cancel: this.language.translate('common.cancel'),
      action: 'update-success',
      type: 'success',
      size: 'md'
    })
      .pipe(
        filter(o => o.reason === 'ok'),
        map(o => o.result as CronDefinition),
        tap(o => {
          this.value = this.createCronString(o)
          this.setValue(this.value)
        })
      )
      .subscribe()
    this.subscriptions.push(s)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe())
  }

  private createCronString(cron: CronDefinition): string {
    return (cron.minute || '*') +
      this.separator +
      (cron.hour || '*') + this.separator +
      (cron.dayOfMonth || '*') + this.separator +
      (cron.month || '*') + this.separator +
      (cron.dayOfWeek || '?') + this.separator +
      (cron.year || '*')
  }

  private parseCronString(cron: string): CronDefinition {
    if (!cron)
      return { minute: '*', hour: '*', dayOfMonth: '*', month: '*', dayOfWeek: '?', year: '*' }
    const parts = cron.split(this.separator)
    return {
      minute: parts[0],
      hour: parts[1],
      dayOfMonth: parts[2],
      month: parts[3],
      dayOfWeek: parts[4],
      year: parts[5]
    }
  }
}
