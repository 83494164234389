import { Component, ContentChildren, Injector, Input, QueryList, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { CrifDtActionComponent } from './crf-action.component';
import { WrappedColumn } from '../utils/wrapped.component';
import { HostWrapper } from '../utils/host-wrapper';

@Component({
  selector: 'crf-dt-actions',
  template: `
    <div class="dropdown">
      <button class="btn btn-outline py-0" style="padding: 10px; margin: 0px 16px;" [disabled]="_disabled" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis-vertical"></i>
      </button>
      <ul class="dropdown-menu">
        <ng-container #projectedActions></ng-container>
      </ul>
    </div>`
})
export class CrifDtActionsComponent {

  @ContentChildren(CrifDtActionComponent) actions!: QueryList<CrifDtActionComponent>;
  @ViewChild('projectedActions', { read: ViewContainerRef }) _projectedActions!: ViewContainerRef;
  @Input() disabled: boolean = false
 
  private wrappedInjector!: Injector;

  constructor(private vcr: ViewContainerRef) { }

  get _disabled() {
    if (this.disabled) return true
    if (!this.actions || this.actions.length === 0) return false;
    return !this.actions.toArray().some(o => !o.disabled)
  }

  ngAfterViewInit() {
    for (let i = this._projectedActions.length; i > 0; i--) {
      this._projectedActions.detach();
    }
    this.actions.forEach(action => {
      this._projectedActions.insert(action._view);
    });
  }

  get _view() {
    return this.wrappedInjector.get(WrappedColumn, this.vcr).columnView;
  }

  ngOnInit() {
    this.wrappedInjector = new HostWrapper(WrappedColumn, this.vcr);
  }
}
