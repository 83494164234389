import { Injectable } from '@angular/core';
import { PaginatedService } from '../paginated.service';
import { DeliveryReadDTO, DeliverySearchDTO, DeliveryService } from '@/apis/monitoring';
import { ErrorUiService } from '@shared/services/error/error-ui.service';
@Injectable({
  providedIn: 'root'
})
export class DeliveriesHPDService extends PaginatedService<DeliveryReadDTO> {

  constructor(errorUIService: ErrorUiService, private deliveryService: DeliveryService) {
    super(errorUIService)
  }

  public async load(limit: number): Promise<DeliveryReadDTO[]> {
    //return await super._load((a, b) => this.deliveryService.deliveryControllerFindAll(a, b), limit)
    return await this._search((a, b, c) => this.deliveryService.deliveryControllerSearch(a, b, c), limit, {
      type: DeliveryReadDTO.TypeEnum.Hpd
    })
  }

  public async search(limit: number, search: DeliverySearchDTO): Promise<DeliveryReadDTO[]> {
    return await this._search((a, b, c) => this.deliveryService.deliveryControllerSearch(a, b, c), limit, search)
  }
}
