/**
 * CRIF Data Flow Monitoring
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ElaborationUpdateDTO { 
    status: ElaborationUpdateDTO.StatusEnum;
    filename: string;
    contributionId: string;
    contributorId: string;
    completedAt?: string;
    appliedFilters?: string;
    failedId?: string;
    failedMessage?: string;
    numberOfSubjects?: number;
    numberOfAccounts?: number;
    numberOfTransactions?: number;
    numberOfRecords?: number;
    numberOfInvalidTransactions?: number;
    errorThreshold?: number;
}
export namespace ElaborationUpdateDTO {
    export type StatusEnum = 'COMPLETE' | 'RUNNING' | 'INVALID' | 'FAILED';
    export const StatusEnum = {
        Complete: 'COMPLETE' as StatusEnum,
        Running: 'RUNNING' as StatusEnum,
        Invalid: 'INVALID' as StatusEnum,
        Failed: 'FAILED' as StatusEnum
    };
}


