import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DialogCloseReason, DialogType } from '@shared/components/crf-modals/dialog-data';

@Component({
  selector: 'crf-modal-simple-template',
  templateUrl: './modal-simple-template.component.html',
  styleUrls: ['./modal-simple-template.component.scss'],
  host: {
    class: "modal-dialog"
  }
})
export class ModalSimpleTemplateComponent implements OnChanges {

  @Input() title!: string
  @Input() okLabel?: string
  @Input() cancelLabel?: string
  @Input() color?: DialogType = 'info'
  @Output() close: EventEmitter<DialogCloseReason> = new EventEmitter()
  hbackground: string = ''
  htext: string = ''

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['color']) {
      switch (this.color) {
        case 'info':
          this.hbackground = ''
          this.htext = 'text-primary'
          break;
        case 'error':
          this.hbackground = 'bg-danger'
          this.htext = 'text-white'
          break;
        case 'success':
          this.hbackground = 'bg-primary'
          this.htext = 'text-white'
          break;
        case 'warning':
          this.hbackground = 'bg-warning'
          this.htext = 'text-black'
          break;
      }
    }
  }
}
