<ng-container *ngIf="collapsable;else form">
  <div class="accordion mb-3" [id]="'accordion-'+id">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button" type="button" data-bs-toggle="collapse"
          data-bs-target="#collapseFilter" aria-expanded="false" [attr.aria-controls]="'accordion-'+id">
          {{ title || 'filter.title' | translate }}
        </button>
      </h2>
      <div id="collapseFilter" class="accordion-collapse collapse show" [attr.data-bs-parent]="'#accordion-'+id">
        <div class="accordion-body">
          <ng-container [ngTemplateOutlet]="cardSel"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #cardSel>
  <ng-container *ngIf="noCard;else card">
    <ng-container [ngTemplateOutlet]="form"></ng-container>
  </ng-container>
</ng-template>


<ng-template #card>
  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title" *ngIf="title && !collapsable">{{ title || 'filter.title' | translate }}</h5>
      <ng-container [ngTemplateOutlet]="form"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #form>
  <form *ngIf="filterForm" [formGroup]="filterForm" [class.was-validated]="ngForm.submitted" class="needs-validation"
    #ngForm="ngForm" (ngSubmit)="onFilterSubmit()">
    <div class="row">
      <ng-container *ngFor="let input of _fields;trackBy:identify">
        <ng-container *ngIf="input.hidden">
          <crf-input [formControlName]="input.property" [type]="input.type" [hidden]="true"/>
        </ng-container>
        <ng-container *ngIf="!input.hidden">
          <div class="col" [ngClass]="input.cols">
            <div class="mb-3">
              <ng-container *ngIf="isText(input.type)">
                <crf-input [formControlName]="input.property" [type]="input.type"
                  [label]="((input.labeli18n || '') | translate) || input.label || ''"
                  [placeholder]="(input.placeholder ||'') | translate" [defaultValue]="input.defaultValue"
                  [hintText]="input.hintText" [datalist]="input.datalist" />
              </ng-container>
              <ng-container *ngIf="isSwitch(input.type)">
                <crf-switch [formControlName]="input.property"
                  [label]="((input.labeli18n || '') | translate) || input.label || ''"
                  [defaultValue]="input.defaultValue"/>
              </ng-container>
              <ng-container *ngIf="isSelect(input.type)">
                <crf-select [formControlName]="input.property"
                  [label]="((input.labeli18n || '') | translate) || input.label || ''"
                  [defaultValue]="input.defaultValue"
                  [options]="input.options">
                </crf-select>
              </ng-container>
              <ng-container *ngIf="isMultiSelect(input.type)">
                <crf-multi-select [formControlName]="input.property"
                  [label]="((input.labeli18n || '') | translate) || input.label || ''"
                  [defaultValue]="input.defaultValue"
                  [options]="input.options">
                </crf-multi-select>
              </ng-container>
              <ng-container *ngIf="isTextarea(input.type)">
                <crf-textarea [formControlName]="input.property" [placeholder]="input.placeholder || ''"
                  [formControlName]="input.property" [label]="((input.labeli18n || '') | translate) || input.label || ''"
                  [rows]="input.rows" [hintText]="input.hintText" [defaultValue]="input.defaultValue" [readonly]="input.readonly || false"
                  [tooltip]="input.tooltip"
                />
              </ng-container>
              <ng-container *ngIf="isDatePicker(input.type)">
                <crf-datepicker [formControlName]="input.property" [type]="input.type"
                  [label]="((input.labeli18n || '') | translate) || input.label || ''"
                  [format]="input.format"
                  [placeholder]="input.placeholder || ''"
                  [hintText]="input.hintText"
                  [min]="input.minDate"
                  [max]="input.maxDate" />
              </ng-container>
              <ng-container *ngIf="isCron(input.type)">
                <crf-cron [formControlName]="input.property"
                  [label]="((input.labeli18n || '') | translate) || input.label || ''" [separator]="' '"
                  [defaultValue]="input.defaultValue" [placeholder]="input.placeholder"/>
              </ng-container>
              <ng-container *ngIf="isCodeBlock(input.type)">
                <crf-codeblock [formControlName]="input.property" [formControlName]="input.property"
                  [defaultValue]="input.defaultValue" />
                </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="!noActions">
      <div class="row">
        <div class="col">
          <button type="submit" class="btn btn-primary float-end" [disabled]="!filterForm.valid" [disabled]="disabled"
            click-stop-propagation [disabled]="disabled || loading">
            <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="loading"></i>
            {{ actionBtn || "filter.action" | translate }}
          </button>
          <button type="button" class="btn btn-outline-primary float-end me-3" [disabled]="!filterForm.valid"
            click-stop-propagation [disabled]="disabled" (click)="clearForm()" [disabled]="disabled || loading">
            <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="loading"></i>
            {{ clearBtn || "filter.clear" | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>
