import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EngineEpdComponent } from './pages/engine/engine-epd/engine-epd.component';
import { EngineHpdComponent } from './pages/engine/engine-hpd/engine-hpd.component';
import { NotFoundComponent, SupportComponent } from './shared/pages';
import { SubscribersComponent } from './pages/configuration/subscribers/subscribers.component';
import { AddSubscriberComponent } from './pages/configuration/add-subscriber/add-subscriber.component';
import { ContributorsComponent } from './pages/configuration/contributors/contributors.component';
import { AddContributorComponent } from './pages/configuration/add-contributor/add-contributor.component';
import { authGuard } from '@shared/auth/auth.guard';
import { GlobalSettingHomeComponent } from "@/pages/global-settings/global-setting-home/global-setting-home.component";
import { ElaborationsComponent } from "@/pages/monitoring/elaborations/elaborations.component";
import { DeliveryEPDComponent } from "@/pages/monitoring/delivery-epd/delivery-epd.component";
import { DeliveryHPDComponent } from "@/pages/monitoring/delivery-hpd/delivery-hpd.component";
import { PendingChangesGuard } from '@shared/guards/pending-changes/pending-changes.guard';

const routes: Routes = [
  { path: '', redirectTo: 'monitoring/etl', pathMatch: 'full' },
  // { path: 'home', component: HomeComponent, canActivate: [authGuard] },
  { path: 'engine/epd', component: EngineEpdComponent, canActivate: [authGuard] },
  { path: 'engine/hpd', component: EngineHpdComponent, canActivate: [authGuard] },
  { path: 'configuration/subscribers', component: SubscribersComponent, canActivate: [authGuard] },
  { path: 'configuration/subscribers/create', component: AddSubscriberComponent, canActivate: [authGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'configuration/subscribers/:id', component: AddSubscriberComponent, canActivate: [authGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'configuration/contributors', component: ContributorsComponent, canActivate: [authGuard] },
  { path: 'configuration/contributors/create', component: AddContributorComponent, canActivate: [authGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'configuration/contributors/:id', component: AddContributorComponent, canActivate: [authGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'configuration/global-settings', component: GlobalSettingHomeComponent, canActivate: [authGuard] },
  { path: 'monitoring/etl', component: ElaborationsComponent, canActivate: [authGuard] },
  { path: 'monitoring/epd', component: DeliveryEPDComponent, canActivate: [authGuard] },
  { path: 'monitoring/hpd', component: DeliveryHPDComponent, canActivate: [authGuard] },
  { path: 'not-found', component: NotFoundComponent, canActivate: [authGuard] },
  { path: 'support', component: SupportComponent },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  declarations: [],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
}
