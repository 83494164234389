import { LogLevel } from 'angular-auth-oidc-client';

export const environment = {
  production: false,
  version: '1.0.0',
  env: 'DEV',
  baseUrl: "https://api.datasupply-int.crif.com",
  i18n: {
    availableLangs: ['it', 'en'],
    defaultLanguage: 'en',
    useLanguageBrowser: true,
    storeSelectedLanguage: true,
    cookieStoreName: 'language'
  },
  cognito: {
    configId: "cognito-ad-dev",
    authority: "https://walab-ca3e7e50-f552-11ed-8026-0605b1c5e4f0.auth.eu-central-1.amazoncognito.com",
    authWellknownEndpointUrl: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3WBsN07gc/.well-known/openid-configuration',
    clientId: "77f9bfqjtne80fk9v2nd4olo9b",
    postLoginRoute: "/",
    silentRenew: true,
    logLevel: LogLevel.Error,
    useRefreshToken: true,
    maxIdTokenIatOffsetAllowedInSeconds: 600,
    issValidationOff: true,
    silentRenewUrl: window.location.origin + '/silent-renew.html'
  }
};
