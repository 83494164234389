import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {OauthService} from '@shared/auth/oauth.service';
import {initSidebarToggle} from 'crif-design-system-bootstrap5'
import {map, Subscription} from 'rxjs';
import {environment} from "~/environments/environment";

@Component({
  selector: 'div.cds-sidebar',
  templateUrl: './crf-sidebar.component.html',
  styleUrls: ['./crf-sidebar.component.scss']
})
export class CrfSidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private oauth: OauthService) {
  }

  user: { name: string, email: string } | null = null
  protected readonly environment = environment;

  private subscription: Subscription = new Subscription();

  ngOnInit(): void {

    this.subscription = this.oauth.user$.pipe(map(o => {
      if (o.userData) {
        return {
          name: `${o.userData?.given_name} ${o.userData?.middle_name}`,
          email: o.userData?.email?.toLowerCase()
        }
      }
      return null
    })).subscribe(o => this.user = o)
  }

  logout() {
    this.oauth.logout()
  }

  login() {
    this.oauth.login()
  }

  ngAfterViewInit(): void {
    initSidebarToggle()
  }

  expandMenuItem(ev: Event): void {
    let el = ev.target as (HTMLElement | null);
    while (el && !el.classList.contains('cds-sidebar-menu-item')) {
      el = el.parentElement;
    }
    (el?.children[0] as HTMLElement)?.click();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
