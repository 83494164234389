<ng-container>
  <div class="modal-content" style="border-radius: 8px;">
    <div class="modal-header {{hbackground}}">
      <ng-container *ngIf="title;else thead">
        <h5 class="modal-title {{htext}}"> {{ title }}</h5>
      </ng-container>
      <ng-template #thead>
        <ng-content select="div[title]"></ng-content>
      </ng-template>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
    <div class="modal-footer text-right">
      <button *ngIf="cancelLabel" type="button" class="btn btn-outline-primary" (click)="close.emit('cancel')">
        {{ cancelLabel }}
      </button>
      <button type="button" ngbAutofocus class="btn btn-primary" (click)="close.emit('ok')">{{ okLabel }}</button>
    </div>
  </div>
</ng-container>