import {Component, OnDestroy, OnInit} from '@angular/core';
import {map, Subscription} from "rxjs";
import {OauthService} from "@shared/auth/oauth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'crif-dataflow-app';

  user: { name: string, email: string } | null = null

  constructor(private oauth: OauthService) {
  }

  private subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscription = this.oauth.user$.pipe(map(o => {
      if (o.userData) {
        return {
          name: `${o.userData?.given_name} ${o.userData?.middle_name}`,
          email: o.userData?.email?.toLowerCase()
        }
      }
      return null
    })).subscribe(o => this.user = o)
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
