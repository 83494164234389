import {SubscriberCreateDTO, SubscriberEventItemDTO, SubscriberFilterItemDTO} from '@/apis/configuration';
import { GlobalSettingsService } from '@/services/global-settings/global-settings.service';
import { SubscribersService } from '@/services/subscribers/subscribers.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrifFormFieldsComponent } from '@shared/components/crf-form-fields/crf-form-fields.component';
import { FormFieldsDefinition } from '@shared/components/crf-form-fields/form-fields';
import { ModalService } from '@shared/components/crf-modals/modal.service';
import { CronValidatorService } from '@shared/components/utils/cron-validator.service';
import { UniqueIdService } from '@shared/components/utils/unique-id.service';
import { ComponentCanDeactivate } from '@shared/guards/pending-changes/pending-changes.guard';
import { LanguagesService } from '@shared/services/languages/languages.service';
import { cloneDeep, isEqual } from 'lodash-es';
import { filter, lastValueFrom, Observable, Subscription, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-add-subscriber',
  templateUrl: './add-subscriber.component.html',
  styleUrls: ['./add-subscriber.component.scss']
})
export class AddSubscriberComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  @ViewChild('subscriberForm') subscriberForm!: CrifFormFieldsComponent;
  formFields: FormFieldsDefinition = {};
  loading: boolean = false;
  events: Array<SubscriberEventItemDTO> = [];
  private subscriptions: Subscription[] = []

  private checkPendingChanges: boolean = true;
  private initialValue: any = {
    subscriberName: '',
    subscriberId: '',
    region: '',
    events: []
  };

  get editMode() {
    return !!this.activatedRoute.snapshot.params['id']
  }

  constructor(
    private modalService: ModalService,
    private language: LanguagesService,
    private subscribersService: SubscribersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uiqueIdService: UniqueIdService,
    private globalSettingsService: GlobalSettingsService,
    private cronValidatorService: CronValidatorService
  ) {

    this.formFields = {
      subscriberId: {labeli18n: "subscribers.form.id", type: 'text', cols: "col-4", hidden: this.editMode},
      subscriberName: {labeli18n: "subscribers.form.name", type: 'text', cols: "col-4"},
      region: {
        labeli18n: "subscribers.form.region", type: 'select', cols: "col-4",
        options: globalSettingsService.regions
      }
    }
    globalSettingsService.initialized.subscribe(o => {
      if (o) {
        this.formFields['region'].options = globalSettingsService.regions || [];
      }
    })
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    const formValue = this.subscriberForm.form.form.getRawValue();
    const cleanEvents = this.getCleanEvents(this.events);
    return !this.checkPendingChanges ||
      this.initialValue.subscriberName === formValue.subscriberName &&
      this.initialValue.subscriberId === formValue.subscriberId &&
      this.initialValue.region === formValue.region &&
      isEqual(this.initialValue.events, cleanEvents);
  }

  undoButtonClick() {
    this.checkPendingChanges = false;
    this.router.navigate(['/configuration/subscribers']);
  }

  submitForm() {
    this.events.forEach(ev=>
      ev.filters?.forEach(flt=>
          flt.type === SubscriberFilterItemDTO.TypeEnum.Starter &&
            (flt.groups = [])
      )
    );

    this.subscriberForm.submitForm()
  };

  async createButtonClick(data: SubscriberCreateDTO) {
    if (!this.checkCronExpressions()) {
      return;
    }

    this.loading = true
    let _data: any = Object.assign({}, data)
    _data.events = this.events
    if (this.editMode) {
      delete _data['subscriberId'];
      await lastValueFrom(this.subscribersService.update(data.subscriberId, _data)
        .pipe(
          filter(o => !!o),
          switchMap(() =>
            this.modalService.openDialog<string, void>('error-modal-component', {
              title: this.language.translate('subscribers.modal.updateTitle'),
              content: this.language.translate('subscribers.modal.updateText'),
              ok: this.language.translate('common.ok'),
              action: 'update-success',
              type: 'success'
            })),
          tap(() => {
            this.subscriberForm.form.form.setValue({
              subscriberName: null,
              subscriberId: null,
              region: null
            });
            this.checkPendingChanges = false;
          }),
          switchMap(() => this.router.navigate(['/configuration/subscribers']))
        )
      ).catch(reason => {});
    } else {
      await lastValueFrom(this.subscribersService.create(_data)
        .pipe(
          filter(o => !!o),
          switchMap(() =>
            this.modalService.openDialog<string, void>('error-modal-component', {
              title: this.language.translate('subscribers.modal.createTitle'),
              content: this.language.translate('subscribers.modal.createText'),
              ok: this.language.translate('common.ok'),
              action: 'create-success',
              type: 'success'
            })),
          tap(() => {
            this.subscriberForm.form.form.setValue({
              subscriberName: null,
              subscriberId: null,
              region: null
            });
            this.checkPendingChanges = false;
          }),
          switchMap(() => this.router.navigate(['/configuration/subscribers']))
        )
      ).catch(reason => {});
    }
    this.loading = false;
  }

  private checkCronExpressions(): boolean {
    if (this.events.some(event => event.enabled && !this.cronValidatorService.isValid(event.scheduler))) {
      this.modalService.openDialog<string, boolean>('confirm-modal-component', {
        type: 'error',
        title: this.language.translate('subscribers.modal.' + (this.editMode ? 'updateTitle' : 'createTitle')),
        content: this.language.translate('subscribers.modal.checkCronExpressions'),
        action: 'error',
        entity: 'text',
        ok: this.language.translate('common.ok')
      }).subscribe();
      return false;
    }

    if (this.events.some(event => event.enabled && !this.cronValidatorService.hasNextExecution(event.scheduler))) {
      this.modalService.openDialog<string, boolean>('confirm-modal-component', {
        type: 'error',
        title: this.language.translate('subscribers.modal.' + (this.editMode ? 'updateTitle' : 'createTitle')),
        content: this.language.translate('subscribers.modal.checkCronExpressionsNextExecution'),
        action: 'error',
        entity: 'text',
        ok: this.language.translate('common.ok')
      }).subscribe();
      return false;
    }

    return true;
  }

  loadSubscriber(id: string) {
    return this.subscribersService.get(id)
      .pipe(
        tap(o => {
          const value = {
            subscriberName: o.subscriberName,
            subscriberId: o.subscriberId,
            region: o.region
          };
          this.subscriberForm.form.form.setValue(value);
          this.events = this.getEventsWithFixedColumns(o.events);

          this.initialValue = cloneDeep(value);
          this.initialValue.events = this.getCleanEvents(this.events);
        }));
  }

  getEventsWithFixedColumns(events: SubscriberEventItemDTO[]) {
    return events?.map(item => {
      const clonedItem = cloneDeep(item);
      clonedItem.filters?.forEach(f => {
        f.groups?.forEach(g => {
          g.allOf?.forEach(item => {
            if (item.column) {
              item.column = item.column.toLowerCase();
            }
          });
          g.anyOf?.forEach(item => {
            if (item.column) {
              item.column = item.column.toLowerCase();
            }
          });
        });
      });
      return clonedItem;
    });
  }

  getCleanEvents(events: SubscriberEventItemDTO[]) {
    const cleanEvents = events.map(item => cloneDeep(item));
    cleanEvents.forEach(item => {
      item.name = '';
      item.eventId = '';
    });
    return cleanEvents;
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params['id']
    if (id) {
      this.subscriptions.push(this.loadSubscriber(id).subscribe());
    }

  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s?.unsubscribe()
    }
  }

}
