<template-base [title]="('engine.title' | translate) + ' > ' + ('engine.epd.title' | translate)"
	[subTitle]="'engine.epd.create' | translate">
	<div all>
		<crf-form-fields #iFormFields [fields]="formFields" [noActions]="true" [noCard]="true"
			(crfSubmit)="startEngine($event)" (valueChange)="updateCultures($event)" />
		<hr>
		<div class="row">
			<div class="col-2">
				<button [disabled]="disabled" type="button" class="btn btn-primary mb-2"
					(click)="iFormFields.submitForm()">
          <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="disabled"></i>
					{{ 'common.submit' | translate }}
				</button>
			</div>
      <div class="col-10"></div>
		</div>
	</div>
</template-base>
