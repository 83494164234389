import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, switchMap, take, tap } from 'rxjs';
import { environment } from '~/environments/environment';


export const authGuard: CanActivateFn = (route, state) => {
  const auth = inject(OidcSecurityService);
  const router = inject(Router);
  const configId: string = environment.cognito.configId

  return auth.checkAuth().pipe(
    map(({ isAuthenticated }) => {
      if (!isAuthenticated) {
        auth.authorize(configId)
        return false
      }
      return true
    }))
};

