/**
 * CRIF Data Flow configuration
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContributorFilterItemDTO } from './contributorFilterItemDTO';


export interface ContributorReadDTO { 
    contributorName?: string;
    errorThreshold?: number;
    filters: Array<ContributorFilterItemDTO>;
    contributorId: string;
    createdAt?: string;
    updatedAt?: string;
    deleteAt?: string;
}

