import { Component, ViewChild } from '@angular/core';
import { CrifFormFieldsComponent } from '@shared/components/crf-form-fields/crf-form-fields.component';
import { FormFieldsDefinition } from '@shared/components/crf-form-fields/form-fields';
import { CrifAbstractModalComponent } from '@shared/components/crf-modals/crf-abstract-modal.component';
import { DialogCloseReason } from '@shared/components/crf-modals/dialog-data';

@Component({
  selector: 'app-form-fields-modal',
  templateUrl: './form-fields-modal.component.html',
  styleUrls: ['./form-fields-modal.component.scss']
})
export class FormFieldsModalComponent extends CrifAbstractModalComponent<FormFieldsDefinition, any> {
  @ViewChild('form') form!: CrifFormFieldsComponent;

  onClose(reason: DialogCloseReason) {
    if (reason !== 'ok') this.close({ reason, result: null })
    if (reason === 'ok') {
      this.form?.submitForm()
    }
  }

  submit(result: any) {
    this.close({ reason: 'ok', result })
  }
}