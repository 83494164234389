import { Component, Input, OnInit, Optional, Self } from "@angular/core";
import { CrifAbstractComponent } from "./crf-abstract-field.component";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { UniqueIdService } from "../utils/unique-id.service";

@Component({
  selector: 'crf-codeblock',
  template: `
    <label [for]="id" class="form-label" *ngIf="hasLabel()">{{ label }}</label>
    <pre class="code whitespace-normal">{{value}}</pre>
  `
})
export class CrifCodeBlockComponent extends CrifAbstractComponent<string> implements ControlValueAccessor, OnInit {
  @Input() disabled = false;
  @Input() label: string = '';
  @Input() defaultValue?: string = undefined;

  constructor(idService: UniqueIdService, @Self() @Optional() override control?: NgControl) {
    super(idService, control)
  }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.setValue(this.defaultValue);
    }
  }

  hasLabel() {
    return this.label !== null && this.label !== undefined && this.label !== '';
  }
}