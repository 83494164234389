<div #processTable class="card cds-card-shadow container mt-5 px-4 pt-4 pb-3">
  <div class="row mb-2 d-flex justify-content-between align-items-center">
    <h3 class="col text-nowrap"> {{ jobType === "ETL" ? "Elaboration" : "Delivery" }} Id: {{ jobId }} </h3>
    <div class="col d-flex justify-content-end align-items-center">
      <i class="fa-solid fa-arrows-rotate me-1" (click)="onRefresh()"></i>
      <i class="fa-solid fa-xmark fa-lg ms-2" (click)="onClose()"></i>
    </div>
  </div>
  <crf-dt-table [items]="steps" [loading]="loading">
    <th crf-dt-header field="name" type="string" sortable="stepName" (sort)="onSort($event, 'name')" class="pointer">
      {{ 'monitoring.jobSteps.name' | translate }}
    </th>
    <th crf-dt-header field="status" type="string" sortable="stepStatus" (sort)="onSort($event, 'status')">
      {{ 'monitoring.jobSteps.status' | translate }}
    </th>
    <th crf-dt-header field="createdAt" type="string" sortable="createdAt" (sort)="onSort($event, 'createdAt')">
      {{ 'monitoring.jobSteps.startTime' | translate }}
    </th>
    <th crf-dt-header field="endedAt" type="string" sortable="endedAt" (sort)="onSort($event, 'endedAt')">
      {{ 'monitoring.jobSteps.endTime' | translate }}
    </th>
    <th crf-dt-header field="elapsed" type="string" sortable="elapsed"
        (sort)="onSort($event, 'elapsed')">
      {{ 'monitoring.jobSteps.elapsedTime' | translate }}
    </th>
    <tr crf-dt-row class="align-middle" *ngFor="let item of steps; trackBy: trackByStep">
      <td crf-dt-cell>
        <span [ngbTooltip]="'monitoring.jobSteps.descriptions.' + jobType + '.'+ item.name | translate ">
          {{ 'monitoring.jobSteps.names.' + item.name | translate }}
        </span>
      </td>
      <td crf-dt-cell>
        <div class="d-flex flex-nowrap align-items-center">
          <div class="badge cds-badge active {{ getStatusTextBackground(item) }}">
            {{ item.status | titlecase }}
          </div>
          <div class="d-flex">
            <span *ngFor="let warning of stepWarnings(item.name)"
                  [ngbTooltip]="warning.warningCounter + 'x '+ ('monitoring.warnings.'+ warning.warningName | translate)"
                  style="color:#ffcc00; margin-left: 10px">
                <i class="fa fa-exclamation-triangle"></i>
            </span>
          </div>
        </div>
      </td>
      <td crf-dt-cell>
        {{ item.createdAt ? (item.createdAt | date:"dd/MM/yyyy hh:mm a z") : "-" }}
      </td>
      <td crf-dt-cell>
        {{ item.endedAt ? (item.endedAt | date:"dd/MM/yyyy hh:mm a z") : "-" }}
      </td>
      <td crf-dt-cell> {{ getElapsedTime(item) }}</td>
    </tr>
    <span crf-dt-no-item>
      {{ 'monitoring.jobSteps.noItems' | translate }}
    </span>
    <span crf-dt-loading>
      {{ 'common.loading' | translate }}
    </span>
  </crf-dt-table>
</div>
