import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dasherize'
})
export class DasherizePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return value.replace(/\s+/g, '-').toLowerCase();;
  }

}
