import { Component, Injector, Input, ViewContainerRef } from '@angular/core';
import { HostWrapper } from '@shared/components/utils/host-wrapper';
import { WrappedColumn } from '../utils/wrapped.component';


@Component({
  selector: 'th[crf-dt-header]',
  template: `<ng-content></ng-content>`
})
export class CrifDtHeaderComponent {
  private wrappedInjector!: Injector;
  @Input() field!: string
  @Input() type!: 'number' | 'string'
  @Input() filtrable!: boolean

  constructor(private vcr: ViewContainerRef) { }

  get _view() {
    return this.wrappedInjector.get(WrappedColumn, this.vcr).columnView;
  }

  ngOnInit() {
    this.wrappedInjector = new HostWrapper(WrappedColumn, this.vcr);
  }
}
