import { SubscriberReadDTO, SubscribersService as ApiSubscribersService, SubscriberSearchDTO, SubscriberCreateDTO, SubscriberUpdateDTO } from '@/apis/configuration';
import { Injectable } from '@angular/core';
import { ErrorUiService } from '@shared/services/error/error-ui.service';
import { PaginatedService } from '../paginated.service';


@Injectable({
  providedIn: 'root'
})
export class SubscribersService extends PaginatedService<SubscriberReadDTO> {

  constructor(errorUIService: ErrorUiService, private subscribersService: ApiSubscribersService) {
    super(errorUIService)
  }

  public async load(limit: number): Promise<SubscriberReadDTO[]> {
    return await this._load((a, b) => this.subscribersService.subscriberControllerFindAll(a, b), limit)
  }

  public async search(limit: number, search: SubscriberSearchDTO): Promise<SubscriberReadDTO[]> {
    return await this._search((a, b, c) => this.subscribersService.subscriberControllerSearch(a, b, c), limit, search)
  }

  public create(model: SubscriberCreateDTO) {
    return this._create(() => this.subscribersService.subscriberControllerCreate(model))
  }

  public delete(id: string) {
    return this._delete(() => this.subscribersService.subscriberControllerDelete(id), (o => o.subscriberId === id))
  }

  public update(id: string, model: SubscriberUpdateDTO) {
    return this._update(() => this.subscribersService.subscriberControllerUpdate(id, model), (o => o.subscriberId === id))
  }

  public get(id: string) {
    return this._get(() => this.subscribersService.subscriberControllerFindOne(id))
  }
}
