import {formatDate} from "@angular/common";
import {Injectable} from "@angular/core";
import {CrifFormFieldsComponent} from "@shared/components/crf-form-fields/crf-form-fields.component";
import {FormFieldsDefinition} from "@shared/components/crf-form-fields/form-fields";
import {LanguagesService} from "@shared/services/languages/languages.service";
import {ContributorFilterItemDTO} from "@/apis/configuration";

@Injectable()
export abstract class AbstractMonitoringComponent<SearchDTO extends { createdAtFrom?: string; createdAtTo?: string; }> {

  private readonly format = 'yyyy-MM-dd';
  private readonly locale = 'en-US';

  protected columnNames: string[] = [];

  formFields: FormFieldsDefinition;

  constructor(
    protected languagesService: LanguagesService
  ) {
    this.formFields = {};
  }

  protected abstract getFiltersForm(): CrifFormFieldsComponent;

  protected checkDates(data: SearchDTO) {
    let createdAtFromHintText = '';
    let createdAtToHintText = '';

    if (data.createdAtFrom) {
      const createdAtToControl = this.getFiltersForm().form.form.controls['createdAtTo'];
      const fromDate = new Date(data.createdAtFrom);
      const toDate = createdAtToControl.value ? new Date(createdAtToControl.value) : null;
      const diffDays = toDate ? this.getDiffDays(fromDate, toDate) : 0;
      if (!toDate || diffDays < 0 || diffDays > 30) {
        let value = new Date(data.createdAtFrom);
        value.setDate(fromDate.getDate() + 29);
        if (value > new Date()) {
          value = new Date();
        }
        createdAtToControl.setValue(this.formatDate(value));
        createdAtToHintText = this.languagesService.translate('monitoring.etl.search.hints.moreThan30Days');
      }
    }

    if (data.createdAtTo) {
      const createdAtFromControl = this.getFiltersForm().form.form.controls['createdAtFrom'];
      const fromDate = createdAtFromControl.value ? new Date(createdAtFromControl.value) : null;
      const toDate = new Date(data.createdAtTo);
      const diffDays = fromDate ? this.getDiffDays(fromDate, toDate) : 0;
      if (!fromDate || diffDays < 0 || diffDays > 30) {
        let value = new Date(data.createdAtTo);
        value.setDate(toDate.getDate() - 29);
        createdAtFromControl.setValue(this.formatDate(value));
        createdAtFromHintText = this.languagesService.translate('monitoring.etl.search.hints.moreThan30Days');
      }
    }

    this.formFields['createdAtFrom'].hintText = createdAtFromHintText;
    this.formFields['createdAtTo'].hintText = createdAtToHintText;
  }

  protected get maxDate(): string {
    return this.formatDate(new Date());
  }

  private getDiffDays(fromDate: Date, toDate: Date): number {
    const diffTime = toDate.getTime() - fromDate.getTime();
    return Math.round(diffTime / (1000 * 60 * 60 * 24));
  }

  private formatDate(d: Date): string {
    return formatDate(d, this.format, this.locale);
  }

  protected getFormattedQuery(query: string): string {
    let formattedQuery = query
      .replaceAll(' AND ', '<br>AND ')
      .replaceAll(' OR ', '<br>OR ');

    this.columnNames
      .forEach(columnName => {
        const re = new RegExp(`${columnName}`, 'ig');
        const instances = formattedQuery.match(re) || [];
        const distinctInstances = instances.filter(
          (thing, i, arr) => arr.findIndex(t => t === thing) === i
        );
        distinctInstances.forEach(instance => {
          formattedQuery = formattedQuery.replaceAll(instance, `<b>${instance}</b>`);
        });
      });
    return formattedQuery;
  }

  protected getIdentedFilters(filters: string | undefined): string {
    try {
      if (!filters) {
        return "-"
      }
      const parsed = JSON.parse(filters) as ContributorFilterItemDTO[];
      if (typeof parsed === 'object' && parsed !== null) {
        var obj = parsed.map(x => ({
          type: x.type,
          enabled: x.enabled,
          match: x.match,
          col: x.col,
          candidates: x.candidates,
          copyColumns: x.copyColumns
        }))
        var serialized = JSON.stringify(obj, null, 1);
        return "\n" + serialized
          .substring(3, serialized.length - 4)
          .replaceAll('"', '')
          .replaceAll('{\n', '')
          .replaceAll('},\n ', '\n')
      }
    } catch {
    }
    return "-";
  }
}
