import {Component} from '@angular/core';
import {
  GlobalSettingValueDescriptionComponent
} from "@/pages/global-settings/global-setting-value-description/global-setting-value-description.component";

@Component({
  selector: 'app-global-setting-transaction-culture-domain',
  templateUrl: '../global-setting-value-description/global-setting-value-description.component.html',
  styleUrls: ['../global-setting-value-description/global-setting-value-description.component.scss']
})
export class GlobalSettingTransactionCultureDomainComponent extends GlobalSettingValueDescriptionComponent {

  override ngOnInit() {
    this.setGlobalSettingId("transactionCultureDomain");
    this.setActionsMode(false, false);
    this.setMapping("value", "LocationDescription");
    super.ngOnInit();
  }

}
