import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  private readonly maxCookies = 2048;
  
  public getCookie(name: string): string | undefined {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    if (caLen > this.maxCookies){
      caLen = this.maxCookies;
    }
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return undefined;
  }

  public deleteCookie(name: string) {
    this.setCookie(name, '', -1);
  }

  public setCookie(name: string, value: string, expireDays?: number, path: string = '') {
    let cookie = `${name}=${value};`;
    if (expireDays) {
      let d: Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      let expires: string = `expires=${d.toUTCString()};`;
      cookie+=`${expires}`
    }
    if(path){
      cookie+=`path=${path};`
    }
    cookie+='secure;httpOnly;';
    document.cookie = cookie;
  }
}
