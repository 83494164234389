import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {Component} from '@angular/core';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {
  GlobalSettingDynamicFormComponent
} from "@/pages/global-settings/global-setting-dynamic-form/global-setting-dynamic-form.component";

@Component({
  selector: 'app-global-setting-send-mail',
  templateUrl: '../global-setting-dynamic-form/global-setting-dynamic-form.component.html',
  styleUrls: ['../global-setting-dynamic-form/global-setting-dynamic-form.component.scss']
})
export class GlobalSettingSendMailComponent extends GlobalSettingDynamicFormComponent {

  constructor(
    modalService: ModalService,
    language: LanguagesService,
    globalSettingsService: GlobalSettingsService,
  ) {
    const GLOBAL_SETTING_ID: string = "SendMail";
    const formFields = {
      destinationEmailAddress: {
        labeli18n: `globalSetting.${GLOBAL_SETTING_ID}.field.destinationEmailAddress.label`,
        type: 'text',
        cols: "col-12"
      },
      senderEmailAddress: {
        labeli18n: `globalSetting.${GLOBAL_SETTING_ID}.field.senderEmailAddress.label`,
        type: 'text',
        cols: "col-12"
      },
    };

    super(modalService, language, globalSettingsService);
    this.globalSettingId = GLOBAL_SETTING_ID;
    this.formFields = formFields;
  }

  override ngOnInit(): void {
    this.loadGlobalSetting().then();
  }

}
