import { Component, Input, OnInit, Optional, Self } from "@angular/core";
import { CrifAbstractComponent } from "./crf-abstract-field.component";
import { UniqueIdService } from "../utils/unique-id.service";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { FormFieldOption } from "../crf-form-fields/form-fields";

@Component({
  selector: 'crf-multi-select',
  template: `
  <div class="w-100">
    <label *ngIf="label" [for]="id" class="form-label">{{ label }}</label>
    <ng-select
        [id]="id"
        [attr.aria-label]="label"
        [attr.disabled]="disabled?true:undefined"
        [ngClass]="{'is-invalid': control?.touched && control?.invalid}"
        [closeOnSelect]="false"
        [multiple]="true"
        [placeholder]="placeholder"
        [searchable]="false"
        bindLabel="text"
        bindValue="value"
        dropdownPosition="bottom"
        [(ngModel)]="value"
        (change)="update($event);">
      <ng-option *ngFor="let option of options; trackBy: trackByOption" [value]="option.value">{{option.text}}</ng-option>
    </ng-select>
    <div class="invalid-feedback">
      {{ errors | json }}
    </div>
  </div>
  `
})
export class MultiSelectComponent extends CrifAbstractComponent<string[]> implements ControlValueAccessor, OnInit {
  @Input() disabled = false;
  @Input() label: string = '';
  @Input() placeholder!: string
  @Input() defaultValue?: string[] = undefined
  @Input() options?: FormFieldOption[] | undefined

  constructor(idService: UniqueIdService, @Self() @Optional() override control?: NgControl) {
    super(idService, control)
  }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.setValue(this.defaultValue);
    }
  }

  update(value: string[]) {
    if (!this.disabled) {
      this.setValue(value);
    }
  }

  trackByOption(index: number, item: FormFieldOption) {
    return `${index}-multi-select-${this.id}`;
  }
}
