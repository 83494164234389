import { Directive, ElementRef } from '@angular/core';

const BUTTON_SIZE: string = '20px';

@Directive({
    selector: 'pre.code'
})
export class CodeDirective {
    private copyButton?: HTMLButtonElement;
    private pre: HTMLPreElement;
    constructor(private el: ElementRef) {
        this.pre = this.el.nativeElement as HTMLPreElement;
        setTimeout(() => {
            this.escapeHtmlCode();
            this.addCopyButton();
        }, 0);
    }

    addCopyButton() {
        if(this.copyButton)
            return;
        this.copyButton = document.createElement('button');
        this.copyButton.name = 'copysource';
        this.copyButton.type = 'button';
        this.copyButton.title = 'Copy to clipboard';
        this.copyButton.classList.add('btn');
        this.copyButton.classList.add('btn-text-secondary');
        this.copyButton.classList.add('p-0');
        this.copyButton.classList.add('d-flex');
        this.copyButton.classList.add('align-items-end');
        this.copyButton.classList.add('justify-content-start');
        this.copyButton.style.width = BUTTON_SIZE;
        this.copyButton.style.height = BUTTON_SIZE;
        this.copyButton.style.position = 'relative';
        this.copyButton.style.top = '0';
        if(document.body.classList.contains('rtl'))
            this.copyButton.style.left = `calc(-100% + 2 * ${BUTTON_SIZE})`;
        else
            this.copyButton.style.left = `calc(100% - 2 * ${BUTTON_SIZE})`;
        const icon = document.createElement('i');
        icon.classList.add('fas');
        icon.classList.add('fa-sm');
        icon.classList.add('fa-copy');
        this.copyButton.appendChild(icon);
        if(this.pre.previousElementSibling && this.pre.previousElementSibling.getAttribute('name') === 'copysource') {
            this.pre.previousElementSibling.remove();
        }
        this.pre.parentElement!.insertBefore(this.copyButton, this.pre);
        this.pre.style.marginTop = `-${BUTTON_SIZE}`;
        this.pre.style.minHeight = '50px';
        this.pre.style.maxHeight = '350px';
        this.pre.style.overflowY = 'scroll';
        this.copyButton.addEventListener('click', (ev:Event) => {
            this.copyButton!.blur();
            navigator.clipboard.writeText(this.pre.innerText).then(() => {
                //icon.classList.remove('text-muted');
                icon.classList.add('text-success');
                icon.classList.remove('fa-copy');
                icon.classList.add('fa-thumbs-up');
                this.copyButton!.title = 'Copied to clipboard!';
                setTimeout(() => {
                    icon.classList.add('text-muted');
                    icon.classList.remove('text-success');
                    icon.classList.add('fa-copy');
                    icon.classList.remove('fa-thumbs-up');
                    this.copyButton!.title = 'Copy to clipboard';
                }, 3000);
            });
        });
    }

    escapeHtmlCode() {
        const escaped = this.pre.innerHTML
            .replaceAll('<', '&lt;')
            .replaceAll('>', '&gt;')
            .replaceAll('&lt;!--', '<i>&lt;!--')
            .replaceAll('--&gt;', '--&gt;</i>')
            .replaceAll('&lt;b&gt;', '<b>')
            .replaceAll('&lt;/b&gt;', '</b>')
            .replaceAll('&lt;i&gt;', '<i>')
            .replaceAll('&lt;/i&gt;', '</i>')
            .replaceAll('&lt;span&gt;', '<span>')
            .replaceAll('&lt;/span&gt;', '</span>')
            .replaceAll('&lt;br&gt;', '<br>');
        this.pre.innerHTML = escaped;
    }
}