/**
 * CRIF Data Flow Monitoring
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GlobalSettingReadDTO {
    settings: Array<any>;
    globalSettingId: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

