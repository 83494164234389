import { Injectable } from '@angular/core';
import { ErrorUiService } from '@shared/services/error/error-ui.service';
import { ContributorReadDTO, ContributorsService as ApiContributorsService, ContributorControllerFindAll200Response, ContributorSearchDTO, ContributorCreateDTO, ContributorUpdateDTO } from '@/apis/configuration';
import { PaginatedService } from '../paginated.service';
@Injectable({
  providedIn: 'root'
})
export class ContributorsService extends PaginatedService<ContributorReadDTO> {

  constructor(errorUIService: ErrorUiService, private apiContributorsService: ApiContributorsService) {
    super(errorUIService)
  }

  public async load(limit: number): Promise<ContributorReadDTO[]> {
    return await super._load((a, b) => this.apiContributorsService.contributorControllerFindAll(a, b), limit)
  }

  public async search(limit: number, search: ContributorSearchDTO): Promise<ContributorReadDTO[]> {
    return await this._search((a, b, c) => this.apiContributorsService.contributorControllerSearch(a, b, c), limit, search)
  }

  public create(model: ContributorCreateDTO) {
    return this._create(() => this.apiContributorsService.contributorControllerCreate(model))
  }

  public delete(id: string) {
    return this._delete(() => this.apiContributorsService.contributorControllerDelete(id), (o => o.contributorId === id))
  }

  public update(id: string, model: ContributorUpdateDTO) {
    return this._update(() => this.apiContributorsService.contributorControllerUpdate(id, model), (o => o.contributorId === id))
  }

  public get(id: string) {
    return this._get(() => this.apiContributorsService.contributorControllerFindOne(id))
  }
}
