import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { Observable, map, of, shareReplay } from 'rxjs';

@Component({
  selector: 'template-base',
  templateUrl: './base-template.component.html',
  styleUrls: ['./base-template.component.scss'],
})
export class TemplateBaseComponent {
  constructor(private breakpointObserver: BreakpointObserver) { }

  @Input()
  title!: string;

  @Input()
  subTitle!: string;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
}
