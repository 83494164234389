import { ContributorCreateDTO, ContributorFilterItemDTO, SubscriberEventItemDTO, SubscriberFilterItemDTO, SubscriberGroupsDTO, SubscriberGroupsItemDTO, SubscriberReadDTO } from '@/apis/configuration';
import { StartEPDRequestDTO } from '@/apis/engine';
import { AfterViewInit, Component, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeNode } from '@shared/components/crf-dt-tree/tree-node.model';
import { CrifAbstractModalComponent } from '@shared/components/crf-modals/crf-abstract-modal.component';
import { DialogCloseReason } from '@shared/components/crf-modals/dialog-data';
import { UniqueIdService } from '@shared/components/utils/unique-id.service';
import { LanguagesService } from '@shared/services/languages/languages.service';

type DialogData = SubscriberReadDTO |
  ContributorCreateDTO |
  ContributorFilterItemDTO |
  SubscriberEventItemDTO |
  SubscriberFilterItemDTO |
  SubscriberGroupsItemDTO

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent extends CrifAbstractModalComponent<DialogData, boolean> implements AfterViewInit {

  dataSubscriber!: SubscriberReadDTO
  dataSubscriberEventTree: TreeNode<any>[] = []
  dataContributor: ContributorCreateDTO | undefined
  dataContributorFilter: ContributorFilterItemDTO | undefined
  dataSubscriberEvent: SubscriberEventItemDTO | undefined
  dataSubscriberFilter: SubscriberFilterItemDTO | undefined
  dataSubscriberGroup: boolean = false;
  dataSubscriberCondition: SubscriberGroupsItemDTO | undefined
  dataStartEpd: StartEPDRequestDTO | undefined
  text: string | undefined

  constructor(
    activeModal: NgbActiveModal,
    private uiqueIdService: UniqueIdService,
    private cdref: ChangeDetectorRef,
    private language: LanguagesService
  ) {
    super(activeModal);
  }

  ngAfterViewInit(): void {
    switch (this.data.entity) {
      case 'subscriber':
        this.dataSubscriber = this.data.content as SubscriberReadDTO
        this.dataSubscriberEventTree = this.dto2FilterTree(this.dataSubscriber.events)
        break;
      case 'contributor':
        this.dataContributor = this.data.content as ContributorCreateDTO
        break;
      case 'contributors-filter':
        this.dataContributorFilter = this.data.content as ContributorFilterItemDTO
        break;
      case 'text':
        this.text = this.data.content as string
        break;
      case 'subscribers-filter':
        this.dataSubscriberFilter = this.data.content as SubscriberFilterItemDTO
        break;
      case 'subscribers-event':
        this.dataSubscriberEvent = this.data.content as SubscriberEventItemDTO
        break;
      case 'subscribers-group':
        this.dataSubscriberGroup = true
        break;
      case 'subscribers-condition':
        this.dataSubscriberCondition = this.data.content as SubscriberGroupsItemDTO
        break;
      case 'start-epd':
        this.dataStartEpd = this.data.content as StartEPDRequestDTO;
        break;
    }
    this.cdref.detectChanges()
  }


  onClose(reason: DialogCloseReason) {
    this.close({ reason, result: reason === 'ok' })
  }

  treeItemClick({ node, depth }: { node: TreeNode<any>, depth: number }) {
    switch (depth) {
      // case 0: this._setEventForm(node); break;
      // case 1: this._setFilterForm(node); break;
      // case 3: this._setConditionForm(node); break;
    }
  }

  dto2FilterTree(data: SubscriberEventItemDTO[]): TreeNode<any>[] {
    const _data = [...data]
    return _data.map((event: SubscriberEventItemDTO) => {
      return {
        id: `event-${this.uiqueIdService.id}`,
        name: (d) => `${this.language.translate("subscribers.events.event.event")} ${d.$idx + 1}`,
        expanded: false,
        data: { scheduler: event.scheduler, culture: event.culture, enabled: event.enabled },
        children: event.filters?.map((filter: SubscriberFilterItemDTO) => {
          return {
            id: `filter-${this.uiqueIdService.id}`,
            name: (d) => `${this.language.translate('subscribers.events.filter.' + d.type.toLowerCase())} ${d.$idx + 1}`,
            expanded: false,
            data: { type: filter.type, value: filter.value, enabled: filter.enabled },
            children: filter.groups?.map((group: SubscriberGroupsDTO) => {
              return {
                id: `group-${this.uiqueIdService.id}`,
                name: (d) => `${this.language.translate("subscribers.events.group.group")} ${d.$idx + 1}`,
                selected: false,
                children: [
                  ...group.allOf?.map((condition: SubscriberGroupsItemDTO) => {
                    return {
                      id: `condition-${this.uiqueIdService.id}`,
                      name: (d) => d.column || `${this.language.translate("subscribers.events.condition.condition")} ${d.$idx + 1}`,
                      expanded: false,
                      data: { logic: 'and', column: condition.column, condition: condition.condition, value: condition.value }
                    } as TreeNode<SubscriberGroupsItemDTO & { logic: string }>
                  }) || [],
                  ...group.anyOf?.map((condition: SubscriberGroupsItemDTO) => {
                    return {
                      id: `condition-${this.uiqueIdService.id}`,
                      name: (d) => d.column || `${this.language.translate("subscribers.events.condition.condition")} ${d.$idx + 1}`,
                      expanded: false,
                      data: { logic: 'or', column: condition.column, condition: condition.condition, value: condition.value }
                    } as TreeNode<SubscriberGroupsItemDTO & { logic: string }>
                  }) || []
                ]
              }
            })
          }
        })
      }
    })
  }

  trackByFilter(index: number, item: ContributorFilterItemDTO) {
    return `${index}-contributor-filter-${item.type}`;
  }
}
