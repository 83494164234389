/**
 * CRIF Data Flow configuration
 * The clients API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriberGroupsDTO } from './subscriberGroupsDTO';


export interface SubscriberFilterItemDTO { 
    type: SubscriberFilterItemDTO.TypeEnum;
    enabled?: boolean;
    value?: string;
    groups?: Array<SubscriberGroupsDTO>;
}
export namespace SubscriberFilterItemDTO {
    export type TypeEnum = 'STARTER' | 'FILTER';
    export const TypeEnum = {
        Starter: 'STARTER' as TypeEnum,
        Filter: 'FILTER' as TypeEnum
    };
}


