import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Configuration as MonSettings, CrifStudioMonitoringApiModule } from './monitoring';
import { Configuration as EngSettings, CrifStudioEngineApiModule } from './engine';
import { Configuration as CfgSettings, CrifStudioConfigurationApiModule } from './configuration';
import { environment } from '~/environments/environment';
import { TokenInterceptor } from '@shared/auth/token.interceptor';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CrifStudioConfigurationApiModule.forRoot(() => new CfgSettings({ basePath: environment.baseUrl })),
    CrifStudioEngineApiModule.forRoot(() => new EngSettings({ basePath: environment.baseUrl })),
    CrifStudioMonitoringApiModule.forRoot(() => new MonSettings({ basePath: environment.baseUrl })),
  ],
  exports: [
    CrifStudioConfigurationApiModule,
    CrifStudioEngineApiModule,
    CrifStudioMonitoringApiModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }]
})
export class ApisModule { }