import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CrifFormFieldsComponent} from '@shared/components/crf-form-fields/crf-form-fields.component';
import {FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {filter, lastValueFrom, switchMap, tap} from 'rxjs';
import {GlobalSettingReadDTO, GlobalSettingUpdateDTO} from "@/apis/monitoring";

@Component({
  selector: 'app-global-setting-dynamic-form',
  templateUrl: './global-setting-dynamic-form.component.html',
  styleUrls: ['./global-setting-dynamic-form.component.scss']
})
export class GlobalSettingDynamicFormComponent implements OnInit, OnDestroy {

  @ViewChild('globalSettingForm')
  globalSettingForm!: CrifFormFieldsComponent;
  loading: boolean = false;
  globalSettingId: string = "";
  formFields: FormFieldsDefinition = {};

  private modalService: ModalService;
  private language: LanguagesService;
  private globalSettingsService: GlobalSettingsService;

  constructor(
    modalService: ModalService,
    language: LanguagesService,
    globalSettingsService: GlobalSettingsService,
  ) {
    this.modalService = modalService;
    this.language = language;
    this.globalSettingsService = globalSettingsService;
  }

  async updateButtonClick(data: any) {
    let updateData: GlobalSettingUpdateDTO = {
      settings: [data]
    };
    this.loading = true;
    await lastValueFrom(this.globalSettingsService.update(this.globalSettingId, updateData)
      .pipe(
        filter(o => !!o),
        switchMap(o =>
          this.modalService.openDialog<string, void>('error-modal-component', {
            title: this.language.translate(`globalSetting.${this.globalSettingId}.modal.updateTitle`),
            content: this.language.translate(`globalSetting.${this.globalSettingId}.modal.updateText`),
            ok: this.language.translate('common.ok'),
            action: 'update-success',
            type: 'success'
          })
        ),
      )
    );
    this.loading = false;
  }

  async loadGlobalSetting() {
    await lastValueFrom(this.globalSettingsService.findOne(this.globalSettingId)
      .pipe(
        tap((globalSettingReadDTO: GlobalSettingReadDTO) => {
          const settings: any = globalSettingReadDTO.settings && globalSettingReadDTO.settings.length > 0 ? globalSettingReadDTO.settings[0] : [];
          this.globalSettingForm.form.form.patchValue(settings)
        })
      )
    );
  }

  ngOnInit(): void {
    this.loadGlobalSetting().then();
  }

  ngOnDestroy(): void {
  }

}
