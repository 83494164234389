import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild} from '@angular/core';
import {debounceTime, fromEvent, Subscription} from 'rxjs';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})


export class SearchBarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('inputField') inputFieldRef!: ElementRef;
  @Output() searchEvent = new EventEmitter<string>();

  private subscription: Subscription = new Subscription();

  ngAfterViewInit() {
    const inputField = this.inputFieldRef.nativeElement;
    const inputObservable = fromEvent(inputField, 'input');

    this.subscription = inputObservable
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.onSearch(inputField.value);
      });
  }

  onSearch(value: string) {
    this.searchEvent.emit(value);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
