import {ContributorReadDTO, ContributorSearchDTO} from '@/apis/configuration';
import {ContributorsService} from '@/services/contributors/contributors.service';
import {ServiceStatus} from '@/services/paginated.service';
import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {SortableDirective, SortEvent} from '@shared/directives/sortable.directive';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {filter, lastValueFrom, Subscription, switchMap, tap} from 'rxjs';

@Component({
  selector: 'app-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.scss']
})
export class ContributorsComponent implements OnInit, OnDestroy {

  contributors: ServiceStatus<ContributorReadDTO>;
  formFields: FormFieldsDefinition
  private subscriptions: Subscription[] = []
  private _search: ContributorSearchDTO = {}
  public loading: boolean = false

  @ViewChildren(SortableDirective) headers!: QueryList<SortableDirective>;

  constructor(
    private contributorsService: ContributorsService,
    private languagesService: LanguagesService,
    private modalService: ModalService,
    private router: Router) {
    this.contributors = contributorsService.status
    this.formFields = {
      contributorName: { labeli18n: "contributors.search.contributorName", type: 'text', cols: "col-6" },
      contributorId: { labeli18n: "contributors.search.contributorId", type: 'text', cols: "col-6" },
      updatedAtFrom: { labeli18n: "contributors.search.updatedAtFrom", type: 'date', format: 'yyyy-MM-dd', cols: "col-6" },
      updatedAtTo: { labeli18n: "contributors.search.updatedAtTo", type: 'date', format: 'yyyy-MM-dd', cols: "col-6" },
    }
    this.subscriptions.push(this.contributorsService.loading$.subscribe(o => this.loading = o))
  }

  async search(data: ContributorSearchDTO) {
    this._search = data
    this.contributorsService.reset(true)
    this.contributors = this.contributorsService.status
    await this.contributorsService.search(100, this._search)
    this.contributors = this.contributorsService.status
  }

  async loadMore() {
    await this.contributorsService.search(100, this._search)
    this.contributors = this.contributorsService.status
  }

  async clearSearch() {
    this._search = {}
    this.contributorsService.reset(true)
    this.contributors = this.contributorsService.status
    await this.contributorsService.search(100, this._search)
    this.contributors = this.contributorsService.status
  }

  ngOnInit(): void {
    if (!this.contributors.loaded) {
      this.contributorsService.reset(true)
      this.contributors = this.contributorsService.status
      this.contributorsService.search(100, this._search)
        .then(() => {
          this.contributors = this.contributorsService.status
        }).catch()
    }
  }

  async refresh() {
    this.contributorsService.reset(true)
    this.contributors = this.contributorsService.status
    await this.contributorsService.search(100, this._search)
    this.contributors = this.contributorsService.status
  }

  showDetails(rowItem: ContributorReadDTO) {
    this.modalService.openDialog<ContributorReadDTO, void>('row-details-modal-component', {
      type: 'info',
      title: this.languagesService.translate('contributors.detail'),
      content: rowItem,
      action: 'details',
      entity: 'contributor',
      ok: this.languagesService.translate('common.ok'),
    })
  }

  async onDelete(item: ContributorReadDTO) {
    await lastValueFrom(this.modalService.openDialog<ContributorReadDTO, boolean>('confirm-modal-component', {
      type: 'warning',
      title: this.languagesService.translate('dialogs.contributors.titleDelete'),
      content: item,
      action: 'delete-confirm',
      entity: 'contributor',
      ok: this.languagesService.translate('common.ok'),
      cancel: this.languagesService.translate('common.cancel')
    }).pipe(
      filter(o => o.reason === 'ok'),
      switchMap(o => this.contributorsService.delete(item.contributorId)),
      tap(o => this.modalService.openDialog<string, void>('error-modal-component', {
        title: this.languagesService.translate('contributors.modal.deleteTitle'),
        content: this.languagesService.translate('contributors.modal.deleteText'),
        ok: this.languagesService.translate('common.ok'),
        action: 'delete-success',
        type: 'success'
      }))
    )).catch(reason => {});
  }

  async onEdit(item: ContributorReadDTO) {
    return await this.router.navigate([`/configuration/contributors/${item.contributorId}`])
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s?.unsubscribe()
    }
  }

  onSort(sortEvent: SortEvent) {
    for (const header of this.headers) {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    }
    this.contributorsService.sort(sortEvent);
    this.contributors = this.contributorsService.status;
  }

  trackByContributor(index: number, item: ContributorReadDTO) {
    return item.contributorId;
  }
}
