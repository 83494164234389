import {ContributorCreateDTO, ContributorFilterItemDTO} from '@/apis/configuration';
import {ContributorsService} from '@/services/contributors/contributors.service';
import {GlobalSettingsService} from '@/services/global-settings/global-settings.service';
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CrifFormFieldsComponent} from '@shared/components/crf-form-fields/crf-form-fields.component';
import {FormFieldOption, FormFieldsDefinition} from '@shared/components/crf-form-fields/form-fields';
import {ModalService} from '@shared/components/crf-modals/modal.service';
import {ComponentCanDeactivate} from '@shared/guards/pending-changes/pending-changes.guard';
import {LanguagesService} from '@shared/services/languages/languages.service';
import {filter, lastValueFrom, map, Observable, Subscription, switchMap, tap} from 'rxjs';
import {cloneDeep, isEqual} from 'lodash-es';

@Component({
  selector: 'app-add-contributor',
  templateUrl: './add-contributor.component.html',
  styleUrls: ['./add-contributor.component.scss']
})
export class AddContributorComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  private subscriptions: Subscription[] = []
  filterItems: ContributorFilterItemDTO[] = []
  formFields: FormFieldsDefinition
  @ViewChild('contributorForm')
  contributorForm!: CrifFormFieldsComponent;
  loading: boolean = false;
  typeOptions: FormFieldOption[] = [{text: "Replace", value: "REPLACE"}]

  private checkPendingChanges: boolean = true;
  private initialValue: any = {
    contributorName: '',
    contributorId: '',
    errorThreshold: '',
    filters: []
  };

  get editMode() {
    return !!this.activatedRoute.snapshot.params['id']
  }

  constructor(
    private modalService: ModalService,
    private language: LanguagesService,
    private contributorsService: ContributorsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalSettings: GlobalSettingsService) {
    this.formFields = {
      contributorId: {labeli18n: "contributors.form.id", type: 'text', cols: "col-4", hidden: this.editMode},
      contributorName: {labeli18n: "contributors.form.name", type: 'text', cols: "col-4"},
      errorThreshold: {
        labeli18n: "contributors.form.errorThreshold",
        type: 'text',
        cols: "col-4",
        placeholder: "contributors.form.errorThresholdPlaceholder",
        validators: [Validators.pattern(/^0(\.\d+)?|1(\.0+)?|\s?$/)]
      },
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    const formValue = this.contributorForm.form.form.getRawValue();
    return !this.checkPendingChanges ||
      this.initialValue.contributorName === formValue.contributorName &&
      this.initialValue.contributorId === formValue.contributorId &&
      this.initialValue.errorThreshold === formValue.errorThreshold &&
      isEqual(this.initialValue.filters, this.filterItems);
  }

  onCreateFilterItem() {
    const s = this.modalService.openDialog<FormFieldsDefinition, ContributorFilterItemDTO & {
      candidateArray: string
    }>('form-fields-modal-component', {
      type: 'info',
      title: this.language.translate('contributors.filters.add.title'),
      content: {
        type: {
          labeli18n: "contributors.filters.ent.type", type: 'select', cols: "col-6", validators: [Validators.required],
          options: this.typeOptions
        },
        match: {labeli18n: "contributors.filters.ent.match", type: 'text', cols: "col-6"},
        col: {
          labeli18n: "contributors.filters.ent.col", type: 'select', cols: "col-6",
          options: this.globalSettings.columns
        },
        candidateArray: {labeli18n: "contributors.filters.ent.candidates", type: 'text', cols: "col-6"},
        copyColumns: {
          labeli18n: "contributors.filters.ent.copyColumns", type: 'multiselect', cols: "col-6",
          defaultValue: [],
          options: this.globalSettings.columns
        },
        enabled: {labeli18n: "contributors.filters.ent.enabled", type: 'switch', cols: "col-12", defaultValue: true},
      },
      action: 'details',
      entity: 'contributors-filter',
      ok: this.language.translate('common.ok'),
      cancel: this.language.translate('common.cancel')
    }).pipe(
      filter(o => o.reason === 'ok'),
      filter(o => !!o.result),
      map(o => o.result as ContributorFilterItemDTO & { candidateArray: string }),
      tap(o => {
        o.candidates = o["candidateArray"].split(",").map(item => item.trim());
        this.filterItems.push(o);
      })
    ).subscribe();
    this.subscriptions.push(s);
  }

  onFilterItemEdit(item: ContributorFilterItemDTO) {
    const s = this.modalService.openDialog<FormFieldsDefinition, ContributorFilterItemDTO & {
      candidateArray: string
    }>('form-fields-modal-component', {
      type: 'info',
      title: this.language.translate('contributors.filters.add.title'),
      content: {
        type: {
          labeli18n: "contributors.filters.ent.type", type: 'select', cols: "col-6", validators: [Validators.required],
          options: this.typeOptions,
          defaultValue: item.type
        },
        match: {
          labeli18n: "contributors.filters.ent.match", type: 'text', cols: "col-6",
          defaultValue: item.match
        },
        col: {
          labeli18n: "contributors.filters.ent.col", type: 'select', cols: "col-6",
          options: this.globalSettings.columns,
          defaultValue: item.col
        },
        candidateArray: {
          labeli18n: "contributors.filters.ent.candidates", type: 'text', cols: "col-6",
          defaultValue: item.candidates?.join(',')
        },
        copyColumns: {
          labeli18n: "contributors.filters.ent.copyColumns", type: 'multiselect', cols: "col-6",
          defaultValue: item.copyColumns && item.copyColumns.length > 0 ? item.copyColumns : [],
          options: this.globalSettings.columns
        },
        enabled: {
          labeli18n: "contributors.filters.ent.enabled", type: 'switch', cols: "col-12",
          defaultValue: item.enabled
        },
      },
      action: 'details',
      entity: 'contributors-filter',
      ok: this.language.translate('common.ok'),
      cancel: this.language.translate('common.cancel')
    }).pipe(
      filter(o => o.reason === 'ok'),
      filter(o => !!o.result),
      map(o => o.result as ContributorFilterItemDTO & { candidateArray: string }),
      tap(o => {
        item.type = o.type;
        item.match = o.match;
        item.col = o.col;
        item.copyColumns = o.copyColumns;
        item.candidates = o["candidateArray"].split(",").map(item => item.trim());
        item.enabled = o.enabled;
      })
    ).subscribe();
    this.subscriptions.push(s);
  }

  onFilterItemDelete(item: ContributorFilterItemDTO) {
    const s = this.modalService.openDialog<ContributorFilterItemDTO, boolean>('confirm-modal-component', {
      type: 'warning',
      title: this.language.translate('dialogs.contributors.filters.titleDelete'),
      content: item,
      action: 'delete-confirm',
      entity: 'contributors-filter',
      ok: this.language.translate('common.ok'),
      cancel: this.language.translate('common.cancel')
    }).pipe(
      filter(o => o.reason === 'ok'),
      tap(o => this.filterItems.splice(this.filterItems.indexOf(item), 1))
    ).subscribe();
    this.subscriptions.push(s)
  }

  undoButtonClick() {
    this.checkPendingChanges = false;
    this.router.navigate(['/configuration/contributors']);
  }

  async createButtonClick(data: ContributorCreateDTO) {
    this.loading = true;
    data.filters = this.filterItems
    for (let filterKey in data.filters) {
      if (("" + data.filters[filterKey].enabled).length == 0) {
        data.filters[filterKey].enabled = true;
      }
    }
    data.errorThreshold = parseFloat(`${('' + data?.errorThreshold).replace(",", ".") || ''}`)

    let _data: any = Object.assign({}, data);
    if (isNaN(_data.errorThreshold)) {
      delete _data.errorThreshold;
    }

    if (this.editMode) {
      delete _data['contributorId'];
      await lastValueFrom(this.contributorsService.update(data.contributorId, _data)
        .pipe(
          filter(o => !!o),
          switchMap(o =>
            this.modalService.openDialog<string, void>('error-modal-component', {
              title: this.language.translate('contributors.modal.updateTitle'),
              content: this.language.translate('contributors.modal.updateText'),
              ok: this.language.translate('common.ok'),
              action: 'update-success',
              type: 'success'
            })),
          tap(o => {
            this.contributorForm.form.form.setValue({
              contributorName: null,
              contributorId: null,
              errorThreshold: null
            });
            this.checkPendingChanges = false;
          }),
          switchMap(o => this.router.navigate(['/configuration/contributors'])))
      ).catch(reason => {
      });
    } else {
      await lastValueFrom(this.contributorsService.create(_data)
        .pipe(
          filter(o => !!o),
          switchMap(o =>
            this.modalService.openDialog<string, void>('error-modal-component', {
              title: this.language.translate('contributors.modal.createTitle'),
              content: this.language.translate('contributors.modal.createText'),
              ok: this.language.translate('common.ok'),
              action: 'create-success',
              type: 'success'
            })),
          tap(o => {
            this.contributorForm.form.form.setValue({
              contributorName: null,
              contributorId: null,
              errorThreshold: null
            });
            this.checkPendingChanges = false;
          }),
          switchMap(o => this.router.navigate(['/configuration/contributors'])))
      ).catch(reason => {
      });
    }
    this.loading = false;
  }

  loadContributor(id: string) {
    return this.contributorsService.get(id)
      .pipe(
        tap(o => {
          const value = {
            contributorName: o.contributorName,
            contributorId: o.contributorId,
            errorThreshold: o.errorThreshold === undefined ? '' : '' + parseFloat(`${o.errorThreshold}`)
          };
          this.contributorForm.form.form.setValue(value);
          this.filterItems = o.filters || []

          this.initialValue = cloneDeep(value);
          this.initialValue.filters = cloneDeep(this.filterItems);
        }));
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params['id']
    if (id) {
      this.subscriptions.push(this.loadContributor(id).subscribe());
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s?.unsubscribe()
    }
  }

  trackByFilter(index: number, item: ContributorFilterItemDTO) {
    return `${index}-contributor-filter-${item.type}`;
  }
}
