<template-base [title]="('engine.title' | translate) + ' > ' + ('engine.hpd.title' | translate)"
	[subTitle]="'engine.hpd.create' | translate">
	<div all>
		<crf-form-fields #iFormFields [fields]="formFields" [noActions]="true" [noCard]="true"
			(crfSubmit)="startEngine($event)" (valueChange)="checkDate($event)" />
		<div class="alert cds-alert alert-info" role="alert">
			<div class="cds-alert-icon">
				<i class="fa-info-circle fa-solid"></i>
			</div>
			<div class="cds-alert-content">
				{{ 'engine.form.alerts.noMoreThan400MTransactions' | translate }}
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col-2">
				<button [disabled]="disabled" type="button" class="btn btn-primary mb-2"
					(click)="iFormFields.submitForm()">
          <i class="fa-circle-notch fa-solid fa-spin me-1" *ngIf="disabled"></i>
					{{ 'common.submit' | translate }}
				</button>
			</div>
      <div class="col-10"></div>
		</div>
	</div>
</template-base>
