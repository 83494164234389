import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {
  GlobalSettingMerchantCategoryCodeDomainComponent
} from "@/pages/global-settings/global-setting-merchant-category-code-domain/global-setting-merchant-category-code-domain.component";
import {
  GlobalSettingCurrencyDomainComponent
} from "@/pages/global-settings/global-setting-currency-domain/global-setting-currency-domain.component";
import {
  GlobalSettingPurposeCodeDomainComponent
} from "@/pages/global-settings/global-setting-purpose-code-domain/global-setting-purpose-code-domain.component";
import {
  GlobalSettingCountryCodeDomainComponent
} from "@/pages/global-settings/global-setting-country-code-domain/global-setting-country-code-domain.component";
import {
  GlobalSettingSendMailComponent
} from "@/pages/global-settings/global-setting-send-mail/global-setting-send-mail.component";
import {
  GlobalSettingElaborationConfigurationComponent
} from "@/pages/global-settings/global-setting-elaboration-configuration/global-setting-elaboration-configuration.component";
import {
  GlobalSettingAccountPurposeDomainComponent
} from '@/pages/global-settings/global-setting-account-purpose-domain/global-setting-account-purpose-domain.component';
import {
  GlobalSettingTransactionCultureDomainComponent
} from '@/pages/global-settings/global-setting-transaction-culture-domain/global-setting-transaction-culture-domain.component';

@Component({
  selector: 'app-global-setting-home',
  templateUrl: './global-setting-home.component.html',
  styleUrls: ['./global-setting-home.component.scss']
})
export class GlobalSettingHomeComponent implements AfterViewInit {

  @ViewChild('sendMail', {read: GlobalSettingSendMailComponent}) sendMailComp!: GlobalSettingSendMailComponent;
  @ViewChild('elaborationConfiguration', {read: GlobalSettingElaborationConfigurationComponent}) elaborationConfigurationComp!: GlobalSettingElaborationConfigurationComponent;
  @ViewChild('countryCodeDomain', {read: GlobalSettingCountryCodeDomainComponent}) countryCodeDomainComp!: GlobalSettingCountryCodeDomainComponent;
  @ViewChild('currencyDomain', {read: GlobalSettingCurrencyDomainComponent}) currencyDomainComp!: GlobalSettingCurrencyDomainComponent;
  @ViewChild('merchantCategoryCodeDomain', {read: GlobalSettingMerchantCategoryCodeDomainComponent}) merchantCategoryCodeDomainComp!: GlobalSettingMerchantCategoryCodeDomainComponent;
  @ViewChild('purposeCodeDomain', {read: GlobalSettingPurposeCodeDomainComponent}) purposeCodeDomainComp!: GlobalSettingPurposeCodeDomainComponent;
  @ViewChild('accountPurposeDomain', {read: GlobalSettingAccountPurposeDomainComponent}) accountPurposeDomainComp!: GlobalSettingAccountPurposeDomainComponent;
  @ViewChild('transactionCultureDomain', {read: GlobalSettingTransactionCultureDomainComponent}) transactionCultureDomainComp!: GlobalSettingTransactionCultureDomainComponent;

  ngAfterViewInit(): void {
  }

  async onTabChanged(tab: 'sendMail' | 'elaborationConfiguration' | 'countryCodeDomain' | 'currencyDomain' | 'merchantCategoryCodeDomain' | 'purposeCodeDomain' | 'accountPurposeDomain' | 'transactionCultureDomain') {
    if (tab === 'sendMail') await this.sendMailComp.loadGlobalSetting()
    if (tab === 'elaborationConfiguration') await this.elaborationConfigurationComp.loadGlobalSetting()
    if (tab === 'countryCodeDomain') await this.countryCodeDomainComp.loadGlobalSetting()
    if (tab === 'currencyDomain') await this.currencyDomainComp.loadGlobalSetting()
    if (tab === 'merchantCategoryCodeDomain') await this.merchantCategoryCodeDomainComp.loadGlobalSetting()
    if (tab === 'purposeCodeDomain') await this.purposeCodeDomainComp.loadGlobalSetting()
    if (tab === 'accountPurposeDomain') await this.accountPurposeDomainComp.loadGlobalSetting()
    if (tab === 'transactionCultureDomain') await this.transactionCultureDomainComp.loadGlobalSetting()
  }
}
